import textCenteredSVG from '@plone/volto/icons/align-center.svg';
import textLeftSVG from '@plone/volto/icons/align-left.svg';
import textRightSVG from '@plone/volto/icons/align-right.svg';

import { Icon as VoltoIcon } from '@plone/volto/components';
import { Icon } from '@package/icons';

import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import checkSVG from '@plone/volto/icons/check.svg';

import { defineMessages } from 'react-intl';

import * as featherIcons from '@package/icons/assets';

const messages = defineMessages({
  title: { id: 'Title', defaultMessage: 'Title' },
  description: { id: 'Description', defaultMessage: 'Description' },
  cards: { id: 'Cards', defaultMessage: 'Cards' },
  blockGlobalSettings: {
    id: 'Block global settings',
    defaultMessage: 'Block global settings',
  },
  card: { id: 'Card', defaultMessage: 'Card' },
  link: { id: 'Link', defaultMessage: 'Link' },
  addCard: { id: 'Add card', defaultMessage: 'Add card' },
  Content: { id: 'Content', defaultMessage: 'Content' },
  cardsPerRow: { id: 'Cards per row', defaultMessage: 'Cards per row' },
  cardsPerRowOnMobile: {
    id: 'Cards per row on mobile',
    defaultMessage: 'Cards per row on mobile',
  },
  icon: { id: 'Icon', defaultMessage: 'Icon' },
  typeToSearch: {
    id: 'Type to search…',
    defaultMessage: 'Type to search…',
  },
  align: { id: 'Align', defaultMessage: 'Align' },
  iconStyle: { id: 'Icon style', defaultMessage: 'Icon style' },
  left: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  right: {
    id: 'Right',
    defaultMessage: 'Right',
  },
  center: {
    id: 'Center',
    defaultMessage: 'Center',
  },
  lightBlueDarkBlue: {
    id: 'Light blue / Dark blue',
    defaultMessage: 'Light blue / Dark blue',
  },
  yellowDarkBlue: {
    id: 'Yellow / Dark blue',
    defaultMessage: 'Yellow / Dark blue',
  },
  greenDarkBlue: {
    id: 'Green / Dark blue',
    defaultMessage: 'Green / Dark blue',
  },
  darkBlueWhite: {
    id: 'Dark blue / White',
    defaultMessage: 'Dark blue / White',
  },
  redWhite: { id: 'Red / White', defaultMessage: 'Red / White' },
  transparentDarkBlue: {
    id: 'Transparent / Dark blue',
    defaultMessage: 'Transparent / Dark blue',
  },
  transparentLightBlue: {
    id: 'Transparent / Light blue',
    defaultMessage: 'Transparent / Light blue',
  },
  linkLabel: {
    id: 'Link label',
    defaultMessage: 'Link label',
  },
  linkLabelHelp: {
    id: 'Leave empty to hide. The card will still be clickable.',
    defaultMessage: 'Leave empty to hide. The card will still be clickable.',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  moreInfo: { id: 'More info', defaultMessage: 'More info' },
});

const colors = (intl) => [
  {
    name: 'icon-light-blue-dark-blue',
    label: intl.formatMessage(messages.lightBlueDarkBlue),
  },
  {
    name: 'icon-yellow-dark-blue',
    label: intl.formatMessage(messages.yellowDarkBlue),
  },
  {
    name: 'icon-green-dark-blue',
    label: intl.formatMessage(messages.greenDarkBlue),
  },
  {
    name: 'icon-dark-blue-white',
    label: intl.formatMessage(messages.darkBlueWhite),
  },
  { name: 'icon-red-white', label: intl.formatMessage(messages.redWhite) },
  {
    name: 'icon-transparent-dark-blue',
    label: intl.formatMessage(messages.transparentDarkBlue),
  },
  {
    name: 'icon-transparent-light-blue',
    label: intl.formatMessage(messages.transparentLightBlue),
  },
];

const IconItem = injectLazyLibs('reactSelect')((props) => {
  const { Option } = props.reactSelect.components;

  return (
    <Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon icon={props.value} />
        <span style={{ marginLeft: 8 }}>{props.label}</span>
      </div>
      {props.isFocused && !props.isSelected && (
        <VoltoIcon name={checkSVG} size="24px" color="#b8c6c8" />
      )}
      {props.isSelected && (
        <VoltoIcon name={checkSVG} size="24px" color="#007bc1" />
      )}
    </Option>
  );
});

const cardSchema = ({ intl }) => {
  return {
    title: intl.formatMessage(messages.card),
    addMessage: intl.formatMessage(messages.addCard),
    fieldsets: [
      {
        id: 'content',
        title: intl.formatMessage(messages.Content),
        fields: [
          'icon',
          'style',
          'title',
          'description',
          'href',
          'openLinkInNewTab',
          'label',
        ],
      },
    ],
    properties: {
      href: {
        title: intl.formatMessage(messages.link),
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      openLinkInNewTab: {
        title: intl.formatMessage(messages.openLinkInNewTab),
        type: 'boolean',
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      description: {
        title: intl.formatMessage(messages.description),
        widget: 'textarea',
      },
      icon: {
        id: 'icon',
        title: intl.formatMessage(messages.icon),
        placeholder: intl.formatMessage(messages.typeToSearch),
        noValueOption: false,
        isMulti: false,
        choices: Object.keys(featherIcons).map((icon) => [icon, icon]),
        customOptionStyling: IconItem,
      },
      style: {
        title: intl.formatMessage(messages.iconStyle),
        widget: 'color_picker',
        colors: colors(intl),
      },
      label: {
        title: intl.formatMessage(messages.linkLabel),
        description: intl.formatMessage(messages.linkLabelHelp),
      },
    },
    required: [],
  };
};

export const cardsSchema = ({ intl }) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.cards),
        fields: ['cards'],
      },
      {
        id: 'settings',
        title: intl.formatMessage(messages.blockGlobalSettings),
        fields: [
          'cardsPerRow',
          'cardsPerRowOnMobile',
          'align',
          'style',
          'label',
        ],
      },
    ],
    properties: {
      cards: {
        widget: 'object_list',
        title: intl.formatMessage(messages.cards),
        schema: cardSchema,
      },
      cardsPerRow: {
        title: intl.formatMessage(messages.cardsPerRow),
        noValueOption: false,
        default: '4',
        factory: 'Choice',
        required: true,
        choices: [
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
        ],
      },
      cardsPerRowOnMobile: {
        title: intl.formatMessage(messages.cardsPerRowOnMobile),
        noValueOption: false,
        default: '1',
        factory: 'Choice',
        required: true,
        choices: [
          ['1', '1'],
          ['2', '2'],
        ],
      },
      align: {
        title: intl.formatMessage(messages.align),
        required: false,
        default: 'center',
        type: 'string',
        widget: 'buttons',
        actions: ['left', 'center', 'right'],
        actionsInfoMap: {
          left: [textLeftSVG, intl.formatMessage(messages.left)],
          center: [textCenteredSVG, intl.formatMessage(messages.center)],
          right: [textRightSVG, intl.formatMessage(messages.right)],
        },
      },
      style: {
        title: intl.formatMessage(messages.iconStyle),
        widget: 'color_picker',
        colors: colors(intl),
      },
      label: {
        title: intl.formatMessage(messages.linkLabel),
        description: intl.formatMessage(messages.linkLabelHelp),
        default: intl.formatMessage(messages.moreInfo),
      },
    },
    required: [],
  };
};
