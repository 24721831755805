import { memo } from 'react';
const SvgWatch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="watch_svg__feather watch_svg__feather-watch"
    viewBox="0 0 24 24"
    {...props}
  >
    <circle cx={12} cy={12} r={7} />
    <path d="M12 9v3l1.5 1.5M16.51 17.35l-.35 3.83a2 2 0 0 1-2 1.82H9.83a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7.35-3.83A2 2 0 0 1 9.83 1h4.35a2 2 0 0 1 2 1.82l.35 3.83" />
  </svg>
);
const Memo = memo(SvgWatch);
export default Memo;
