import { memo } from 'react';
const SvgPorcentagemDeEmblema = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12 24a5.195 5.195 0 0 1-3.982-1.854.427.427 0 0 0-.376-.155 5.22 5.22 0 0 1-4.127-1.505 5.194 5.194 0 0 1-1.505-4.127.43.43 0 0 0-.155-.375C.676 14.991.001 13.539.001 12.001s.676-2.99 1.854-3.982a.43.43 0 0 0 .156-.376 5.194 5.194 0 0 1 1.505-4.127 5.183 5.183 0 0 1 4.127-1.505.435.435 0 0 0 .376-.155C9.011.677 10.462.002 12.001.002s2.99.676 3.982 1.854a.43.43 0 0 0 .376.155 5.205 5.205 0 0 1 5.632 5.632.43.43 0 0 0 .155.375C23.325 9.011 24 10.463 24 12.001s-.676 2.99-1.854 3.982a.43.43 0 0 0-.156.376 5.194 5.194 0 0 1-1.505 4.127 5.174 5.174 0 0 1-4.127 1.505.45.45 0 0 0-.376.155A5.192 5.192 0 0 1 12 24Zm-4.314-3.012c.425 0 .819.183 1.098.514.801.952 1.974 1.498 3.217 1.498s2.416-.546 3.217-1.498a1.415 1.415 0 0 1 1.228-.508c1.241.105 2.455-.337 3.334-1.216s1.322-2.094 1.216-3.335c-.04-.474.145-.92.509-1.226.951-.802 1.497-1.975 1.497-3.217s-.546-2.416-1.498-3.218a1.425 1.425 0 0 1-.508-1.226 4.207 4.207 0 0 0-4.55-4.551c-.48.04-.921-.144-1.228-.508-.801-.952-1.974-1.498-3.217-1.498s-2.416.546-3.217 1.498a1.44 1.44 0 0 1-1.228.508 4.191 4.191 0 0 0-3.334 1.216 4.2 4.2 0 0 0-1.216 3.335c.04.474-.145.92-.509 1.226C1.546 9.584 1 10.757 1 11.999s.546 2.416 1.498 3.218c.363.305.548.752.508 1.226-.106 1.241.337 2.456 1.216 3.335s2.099 1.318 3.334 1.216a1.43 1.43 0 0 1 .13-.006ZM9 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm6 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-5.084 2.277 5-7.5a.5.5 0 1 0-.832-.554l-5 7.5a.5.5 0 1 0 .832.554Z" />
  </svg>
);
const Memo = memo(SvgPorcentagemDeEmblema);
export default Memo;
