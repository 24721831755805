import { toBackendLang } from '@plone/volto/helpers';
import { getG20Events } from '@package/actions';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { Filterbar } from './Filterbar';
import * as dates from './utils/dates';

// Componentes que alteram a visualização do calendario
import CustomListWrapper from './CustomViews/CustomListWrapper/CustomListWrapper';
import CustomDateHeader from './CustomViews/CustomDataHeader/CustomDataHeader';
import CustomEventWrapper from './CustomViews/CustomEventWrapper/CustomEventWrapper';
import CustomToolbar from './CustomViews/CustomToolbar/CustomToolbar';
// import CustomViewYear from './CustomViews/CustomViewYear/CustomViewYear';

import './theme/g20-calendar.less';
import styles from './CalendarBody.module.less';

const messages = defineMessages({
  loadingEvents: { id: 'Loading events…', defaultMessage: 'Loading events…' },
  List: { id: 'List', defaultMessage: 'List' },
  Month: { id: 'Month', defaultMessage: 'Month' },
  Today: { id: 'Today', defaultMessage: 'Today' },
  noEventsInRange: {
    id: 'There are no events in this range',
    defaultMessage: 'There are no events in this range',
  },
});

moment.updateLocale('es', {
  months: {
    format:
      'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split(
        '_',
      ),
    standalone:
      'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
        '_',
      ),
  },
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'dom_lun_mar_mié_jue_vie_sáb'.split('_'),
  monthsShort: 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_'),
});

moment.updateLocale('pt-br', {
  months: {
    format:
      'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split(
        '_',
      ),
    standalone:
      'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split(
        '_',
      ),
  },

  weekdays:
    'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split(
      '_',
    ),
});

const localizer = momentLocalizer(moment);
localizer.formats.yearHeaderFormat = 'YYYY'; // Para a visao de Ano (Year)

const CalendarBody = ({ isEditMode, data }) => {
  const dispatch = useDispatch();
  const [eventsFiltered, setEventsFiltered] = useState([]);
  const [filters, setFilters] = useState({
    tracks: [],
    cities: [],
  });

  const intl = useIntl();

  const lang = useSelector((state) => state.intl.locale);
  moment.locale(toBackendLang(lang));

  const calendarConfig = useMemo(
    () => ({
      defaultDate: new Date(),
      components: {
        month: {
          dateHeader: CustomDateHeader,
        },
        eventWrapper: CustomEventWrapper,
        toolbar: CustomToolbar,
      },
      views: {
        day: false,
        week: false,
        work_week: false,
        month: true,
        // year: CustomViewYear,
        list: CustomListWrapper,
      },
      messages: {
        date: 'Date',
        time: 'Time',
        event: 'Event',
        allDay: 'All Day',
        week: 'Week',
        work_week: 'Work Week',
        day: 'Day',
        month: intl.formatMessage(messages.Month),
        previous: 'Back',
        next: 'Next',
        yesterday: 'Yesterday',
        tomorrow: 'Tomorrow',
        today: intl.formatMessage(messages.Today),
        list: intl.formatMessage(messages.List),
        year: 'Year',
        noEventsInRange: intl.formatMessage(messages.noEventsInRange),
        /**
         * Propriedade que permite personalizar o botão 'showMore' do componente.
         *
         * A função de callback 'showMore' recebe o parâmetro 'total' e retorna a
         * string que será exibida no botão. Essa string geralmente contém uma
         * mensagem indicando a quantidade adicional de itens a serem exibidos.
         *
         * Exemplo de uso:
         *
         * showMore: (total) => `+${total} more`,
         *
         * Onde 'total' é o número total de itens a serem exibidos.
         */
        showMore: (total) => `+${total} more`,
      },
      max: dates.add(dates.endOf(new Date(), 'day'), -1, 'hours'),
    }),
    [intl],
  );

  const filterEvents = (newFilters) => {
    const allWorkgroupsFilter = newFilters.tracks.reduce(
      (prev, curr) => [...prev, ...curr.workgroups],
      [],
    );

    const filtered = events.all.filter((event) => {
      if (newFilters.cities.length && !newFilters.cities.includes(event.city)) {
        return false;
      }

      const selectedTracks = newFilters.tracks.filter((t) => t.checked);

      if (selectedTracks.length === 0) {
        return true;
      }
      if (!event.track) {
        return false;
      }

      const currentTrack = selectedTracks.find(
        (t) => event.track === t.name || event.bothTracks,
      );
      if (!currentTrack) {
        return false;
      }

      const selectedWorkgroups = event.bothTracks
        ? allWorkgroupsFilter
        : currentTrack.workgroups;

      if (selectedWorkgroups.length === 0) {
        return true;
      }

      if (!event.workgroupLabel) {
        return false;
      }
      return selectedWorkgroups.includes(event.workgroupLabel);
    });

    setFilters(newFilters);
    setEventsFiltered(filtered);
  };

  useEffect(() => {
    dispatch(getG20Events());
  }, [dispatch]);
  const res = useSelector((state) => state.g20Events);
  const { error, loaded, loading, data: events } = res;

  useEffect(() => {
    if (!events?.all) {
      return null;
    }

    setEventsFiltered(events.all);
  }, [events]);

  if (loading) return <div>{intl.formatMessage(messages.loadingEvents)}</div>;

  if (error || !loaded)
    return isEditMode ? <div>Falha ao acessar a api</div> : null;

  const { title, subtitle } = data ?? {};

  return (
    <>
      {title || subtitle ? (
        <div className={styles.title}>
          {subtitle ? <div className="sectionSubtitle">{subtitle}</div> : null}
          {title ? <h2 className="sectionTitle">{title}</h2> : null}
        </div>
      ) : null}
      <div className={styles.wrap}>
        <Filterbar
          isEditMode={isEditMode}
          className={styles.filter}
          current={filters}
          onFilter={(filters) => filterEvents(filters)}
        />

        <Calendar
          components={calendarConfig.components}
          defaultDate={calendarConfig.defaultDate}
          events={eventsFiltered}
          localizer={localizer}
          max={calendarConfig.max}
          showMultiDayTimes
          step={60}
          views={calendarConfig.views}
          endAccessor="end"
          startAccessor="start"
          messages={calendarConfig.messages}
          className={styles.cal}
          popup={false}
          showAllEvents={true}
        />
      </div>
    </>
  );
};

CalendarBody.propTypes = {
  data: PropTypes.object,
  isEditMode: PropTypes.bool,
};

export default CalendarBody;
