import cx from 'classnames';
import { UniversalLink } from '@plone/volto/components';
import { Icon } from '@package/icons';
import styles from './GradientButton.module.less';

const GradientButton = ({
  label,
  href,
  item = null,
  type = 'button',
  hideArrow,
  invert,
  openLinkInNewTab,
  className,
  ...rest
}) => {
  const content = (
    <>
      {label}
      {!hideArrow && <Icon icon="ArrowRight" />}
    </>
  );

  return href || item ? (
    <UniversalLink
      href={href}
      item={item}
      openLinkInNewTab={openLinkInNewTab}
      className={cx({ [styles.btn]: !invert, [styles.inv]: invert }, className)}
      {...rest}
    >
      {content}
    </UniversalLink>
  ) : (
    <button
      className={cx({ [styles.btn]: !invert, [styles.inv]: invert }, className)}
      type={type}
      {...rest}
    >
      {content}
    </button>
  );
};

export default GradientButton;
