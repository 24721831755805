/**
 * Image view component.
 * @module components/theme/View/ImageView
 */

import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import prettybytes from 'pretty-bytes';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@package/icons';
import config from '@plone/volto/registry';

import styles from './ImageView.module.less';
import { imageView } from '@package/messages';

/**
 * Image view component class.
 * @function ImageView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ImageView = ({ content, ...rest }) => {
  const intl = useIntl();

  const Image = config.getComponent({ name: 'Image' }).component;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  return (
    <Container className="view-wrapper image-view">
      <div className="sectionSubtitle documentHeadTitle">
        <FormattedMessage id="Image" defaultMessage="Image" />
      </div>
      <h1 className="documentFirstHeading">
        {content.title}
        {content.subtitle && ` - ${content.subtitle}`}
      </h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content?.image?.download && (
        <>
          <div className={styles.img}>
            <Image
              item={content}
              imageField="image"
              alt={content.title}
              responsive={true}
            />
          </div>
          <div className={styles.footer}>
            <div>
              <Icon icon="Maximize" />
              {content.image.width} x {content.image.height}
            </div>

            <a
              href={flattenToAppURL(content.image.download)}
              className={styles.img}
            >
              <Icon icon="Download" />
              {intl.formatMessage(imageView.downloadFullSizedImage)}
            </a>

            <div>
              <Icon icon="HardDrive" />
              {prettybytes(content.image.size)}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ImageView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      scales: PropTypes.shape({
        preview: PropTypes.shape({
          download: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

export default ImageView;
