import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { SidebarPortal } from '@plone/volto/components';
import DividerData from './DividerData';
import DividerBody from './DividerBody';

import styles from './DividerBody.module.less';

const messages = defineMessages({
  dividerBlock: {
    id: 'Divider block',
    defaultMessage: 'Divider block',
  },
});

const Edit = (props) => {
  const { data, onChangeBlock, block, selected } = props;

  const intl = useIntl();

  return (
    <>
      <legend className={styles.legend}>
        {intl.formatMessage(messages.dividerBlock)}
      </legend>
      <div style={{ padding: '1px 0' }}>
        <DividerBody data={data} id={block} />
      </div>
      <SidebarPortal selected={selected}>
        <DividerData
          key={block}
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};

Edit.propTypes = {
  data: PropTypes.shape({
    size: PropTypes.string,
    spacing: PropTypes.string,
  }),
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.bool,
};

export default Edit;
