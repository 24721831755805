import moment from 'moment';

export const formatDateRange = (start, end) => {
  if (moment(start).isSame(end, 'day')) {
    return moment(start).format('dddd, LL');
  }

  if (moment(start).isSame(end, 'month')) {
    return moment(start).format('MMMM DD - ') + moment(end).format('DD, YYYY');
  }

  if (moment(start).isSame(end, 'year')) {
    // 'October 18 - December 24, 2023'
    return (
      moment(start).format('MMMM DD - ') + moment(end).format('MMMM DD, YYYY')
    );
  }

  // '10/18/2023 - 12/24/2024'
  return (
    moment(start).format('MM/DD/YYYY - ') + moment(end).format('MM/DD/YYYY')
  );
};
