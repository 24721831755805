import { manageCookies } from './reducers';
import { CookieBanner, ManageCookies } from './components';

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,

    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: CookieBanner,
      },
      {
        match: '',
        component: ManageCookies,
      },
    ],
  };

  config.addonReducers = {
    ...config.addonReducers,
    manageCookies: manageCookies,
  };

  return config;
};

export default applyConfig;
