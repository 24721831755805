import { BlockDataForm } from '@plone/volto/components';
import PropTypes from 'prop-types';
import { carouselSchema } from './schema';

import { difference, usePrevious } from '@plone/volto/helpers';
import { replaceItemOfArray } from '@plone/volto/helpers/Utils/Utils';
import React from 'react';
import { useIntl } from 'react-intl';

const CarouselData = (props) => {
  const { block, data, onChangeBlock, schemaEnhancer } = props;
  const { columns } = props.data;
  const previous = usePrevious(columns);

  React.useEffect(() => {
    if (previous) {
      const diff = difference(columns, previous);

      const index = diff.findIndex((val) => val);
      if (diff[index]?.href?.[0]) {
        onChangeBlock(block, {
          ...data,
          columns: replaceItemOfArray(data.columns, index, {
            ...data.columns[index],
            title: diff[index].href[0].title,
            description: diff[index].href[0].Description,
            review_state: diff[index].href[0].review_state,
          }),
        });
      }

      if (diff[index]?.href && !diff[index]?.href.length) {
        onChangeBlock(block, {
          ...data,
          columns: replaceItemOfArray(data.columns, index, {
            ...data.columns[index],
            title: null,
            description: null,
            review_state: null,
            preview_image: null,
          }),
        });
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [columns]);

  const intl = useIntl();
  const schema = schemaEnhancer
    ? schemaEnhancer(carouselSchema({ ...props, intl }), props)
    : carouselSchema({ ...props, intl });

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeBlock={onChangeBlock}
      onChangeField={(id, value) => {
        props.onChangeBlock(props.block, {
          ...props.data,
          [id]: value,
        });
      }}
      formData={props.data || { '@type': 'carousel', columns: [{}] }}
      block={props.block}
    />
  );
};

CarouselData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default CarouselData;
