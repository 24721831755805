import { memo } from 'react';
const SvgTrovoada = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M14.594 16h-1.849l1.641-4H9.479l-2.417 6.166c-.132.436-.052.895.22 1.26S7.97 20 8.424 20h1.855l-1.5 4h1.5l5.486-5.818a1.403 1.403 0 0 0-1.171-2.181Zm.391 1.562-4.517 4.781L11.722 19H8.423a.418.418 0 0 1-.339-.171.415.415 0 0 1-.078-.336L10.16 13h2.735l-1.641 4h3.339c.219 0 .322.149.356.214.035.063.102.232.034.348Zm9.016-5.062c0 3.995-3.271 7.5-7 7.5H16.8l.421-.446.212-.267c.07-.105.129-.216.186-.326 2.931-.359 5.383-3.223 5.383-6.461 0-3.509-2.856-6.422-6.367-6.493L16.28 6l-.11-.338A6.732 6.732 0 0 0 9.753 1a6.758 6.758 0 0 0-6.75 6.75c0 .776.139 1.551.412 2.304l.142.388-.354.212c-1.377.826-2.199 2.264-2.199 3.847 0 2.323 1.775 4.217 4.036 4.453.039.358.125.707.277 1.037-2.945-.1-5.313-2.521-5.313-5.49 0-1.803.869-3.451 2.345-4.484a7.677 7.677 0 0 1-.345-2.266C2 3.477 5.477 0 9.75 0a7.729 7.729 0 0 1 7.253 5.023C20.893 5.284 24 8.567 24 12.5Z" />
  </svg>
);
const Memo = memo(SvgTrovoada);
export default Memo;
