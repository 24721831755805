import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { LogoG20 } from '@package/components';
import { accessibleOnClick } from '@package/helpers';
import { siteMessages } from '@package/messages';

import styles from './GroupHeader.module.less';

// TODO: i18n
const GroupHeader = ({
  title,
  description,
  logo,
  active,
  collapsible,
  onClick,
}) => {
  const intl = useIntl();

  if (!title && !logo) return null;

  // I18N
  return (
    <div
      className={
        collapsible ? (active ? styles.open : styles.close) : styles.header
      }
      {...accessibleOnClick((event) => {
        event.preventDefault();
        onClick();
      })}
    >
      {title && !logo && (
        <div className={styles.wrapper}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
      )}

      {logo && (
        <div className={styles.logo}>
          <LogoG20
            title={intl.formatMessage(siteMessages.siteName)}
            titleId="g20-menu-logo"
          />
        </div>
      )}

      {collapsible && (
        <span className="br-button circle small">
          <i
            className={`fa-solid fa-${active ? 'angle-up' : 'angle-down'}`}
          ></i>
        </span>
      )}
    </div>
  );
};

GroupHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.bool,
  active: PropTypes.bool,
  collapsible: PropTypes.bool,
  onClick: PropTypes.func,
};

export default GroupHeader;
