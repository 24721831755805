import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Anontools = () => {
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const content = useSelector((state) => state.content.data, shallowEqual);

  const { settings } = config;
  return (
    !token && (
      <>
        <Link
          style={{ color: '#fff', marginRight: 24 }}
          aria-label="login"
          to={`/login${
            content?.['@id']
              ? `?return_url=${flattenToAppURL(content['@id'])}`
              : ''
          }`}
        >
          <FormattedMessage id="Log in" defaultMessage="Log in" />
        </Link>
        {settings.showSelfRegistration && (
          <Link
            className="br-button dark-mode "
            aria-label="register"
            to="/register"
          >
            <FormattedMessage id="Register" defaultMessage="Register" />
          </Link>
        )}
      </>
    )
  );
};

export default Anontools;

Anontools.propTypes = {
  token: PropTypes.string,
  content: PropTypes.shape({
    '@id': PropTypes.string,
  }),
};

Anontools.defaultProps = {
  token: null,
  content: {
    '@id': null,
  },
};
