import { IconButton } from '@package/components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';

import styles from './SocialShare.module.less';

const timeoutLength = 2500;

const messages = defineMessages({
  share_on: {
    id: 'Share on',
    defaultMessage: 'Share on',
  },
  copy_page_link: {
    id: 'Copy page link',
    defaultMessage: 'Copy page link',
  },
});

export const DEFAULT_SOCIAL = [
  {
    name: 'Facebook',
    color: '#3b5998',
    sharing_url: 'http://www.facebook.com/sharer.php?u=',
    id: 'fb',
  },
  {
    name: 'Twitter',
    color: '#00acee',
    sharing_url: 'https://twitter.com/share?url=',
    id: 'tw',
  },
  {
    name: 'Linkedin',
    color: '#4FA1C6',
    sharing_url: 'https://linkedin.com/shareArticle/?url=',
    only_mobile: true,
    id: 'lk',
  },
  {
    name: 'Whatsapp',
    color: '#00bb2d',
    sharing_url: 'https://api.whatsapp.com/send?text=',
    only_mobile: true,
    id: 'wa',
  },
];

const SocialShare = ({ intl, url, text }) => {
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const share_on = intl.formatMessage(messages['share_on']);

  const handleOpen = () => {
    setIsOpenTooltip(true);

    setTimeout(() => {
      setIsOpenTooltip(false);
    }, timeoutLength);
  };

  const handleClose = () => {
    setIsOpenTooltip(false);
  };

  const getSharingUrl = (socialNetwork, text) => {
    const selectedSocial = DEFAULT_SOCIAL.find(
      (social) => social.name === socialNetwork,
    );
    if (selectedSocial && !text) {
      return `${selectedSocial.sharing_url}${url}`;
    }

    return `${selectedSocial.sharing_url}${url}&text=${text}`;
  };

  const copyClipBoard = () => {
    navigator.clipboard.writeText(url);
    handleOpen();
  };

  return (
    <div className={styles.root}>
      {/* <a href={getSharingUrl('Whatsapp')} target="_blank" rel="noreferrer">
        <p>Whatsapp</p>
      </a> */}
      <IconButton
        icon="Whatsapp"
        dark
        forceExternal
        rel="noopener noreferrer"
        target="_blank"
        title={`${share_on} Whatsapp`}
        href={getSharingUrl('Whatsapp')}
      />
      <IconButton
        icon="Facebook"
        dark
        forceExternal
        rel="noopener noreferrer"
        target="_blank"
        title={`${share_on} Facebook`}
        href={getSharingUrl('Facebook')}
      />
      <IconButton
        icon="Twitter"
        dark
        forceExternal
        rel="noopener noreferrer"
        target="_blank"
        title={`${share_on} Twitter`}
        href={getSharingUrl('Twitter', text)}
      />
      <IconButton
        icon="Linkedin"
        dark
        forceExternal
        rel="noopener noreferrer"
        target="_blank"
        title={`${share_on} Linkedin`}
        href={getSharingUrl('Linkedin')}
      />

      <Popup
        trigger={
          <IconButton
            icon="Link"
            dark
            title={intl.formatMessage(messages['copy_page_link'])}
            onClick={copyClipBoard}
          />
        }
        open={isOpenTooltip}
        onClose={handleClose}
      >
        <Popup.Content>Link copiado com sucesso!</Popup.Content>
      </Popup>
    </div>
  );
};

SocialShare.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(SocialShare);
