import { defineMessages } from 'react-intl';
import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';
import Edit from './HeadTitleEdit';
import View from './HeadTitleView';
import iconSVG from './icons/head-title.svg';

defineMessages({
  headTitle: {
    id: 'Content head title',
    defaultMessage: 'Content head title',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.headTitleBlock = {
    id: 'headTitleBlock',
    title: 'Content head title',
    group: 'text',
    icon: iconSVG,
    view: View,
    edit: Edit,
    schema: BlockSettingsSchema,
    restricted: ({ properties, block }) =>
      !('head_title' in properties) ||
      properties.blocks_layout?.items?.find(
        (uid) => properties.blocks?.[uid]?.['@type'] === block.id,
      ),
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
