import { defineMessages } from 'react-intl';

export default defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
  PrivateContentOrPublishedAfterAdd: {
    id: 'This content is private or has been published after it was added here',
    defaultMessage:
      'This content is private or has been published after it was added here',
  },
  OnlyPublishedContentsAllowed: {
    id: 'Only published contents are allowed',
    defaultMessage: 'Only published contents are allowed',
  },
});
