import { Icon } from '@package/icons';
import { toBackendLang } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';

const CityLink = ({ name, iconSize = 18, ...rest }) => {
  const lang = useSelector((state) => state.intl.locale);

  if (!name) {
    return null;
  }

  const mapLink = `https://www.google.com/maps/search/?api=1&query=${name
    .split(' ')
    .join('+')},Brasil&hl=${toBackendLang(lang)}`;
  return (
    <a href={mapLink} target="_blank" rel="noopener noreferrer" {...rest}>
      <Icon icon="MapPin" size={iconSize} />
      {name}
    </a>
  );
};

export default CityLink;
