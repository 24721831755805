import styles from './BlockMessage.module.less';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  blockMessage: {
    id: 'Content blocked by cookie refusal or domain not allowed.',
    defaultMessage: 'Content blocked by cookie refusal or domain not allowed.',
  },
});

const BlockMessage = ({ intl }) => {
  const classBlockMsg = cx('text-center', styles.blockMessage);

  return (
    <div className={classBlockMsg}>
      {intl.formatMessage(messages.blockMessage)}
    </div>
  );
};

export default injectIntl(BlockMessage);
