/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import PropTypes from 'prop-types';
import { Container as SemanticContainer, Grid } from 'semantic-ui-react';
import { hasBlocksData, flattenHTMLToAppURL } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import config from '@plone/volto/registry';
import { RelatedItems } from '@package/components';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content }) => {
  const Image = config.getComponent({ name: 'Image' }).component;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  if (!hasBlocksData(content)) {
    return (
      <Container className="view-wrapper">
        {content.title && (
          <h1 className="documentFirstHeading">
            {content.title}
            {content.subtitle && ` - ${content.subtitle}`}
          </h1>
        )}
        {content.description && (
          <p className="documentDescription">{content.description}</p>
        )}
        {content.image && (
          <Image
            className="documentImage ui right floated image"
            alt={content.title}
            title={content.title}
            item={content}
            imageField="image"
            responsive={true}
          />
        )}
        {content.text && (
          <div
            dangerouslySetInnerHTML={{
              __html: flattenHTMLToAppURL(content.text.data),
            }}
          />
        )}
      </Container>
    );
  }

  // console.log(content);

  let body = <RenderBlocks content={content} />;

  const { blocks, blocks_layout, relatedItems } = content;

  if (relatedItems?.length) {
    const [headBlocks, bodyBlocks] = blocks_layout.items.reduce(
      (prev, curr) => {
        const [head, body] = prev;

        const headerTypes = [
          'headTitleBlock',
          'title',
          'description',
          'dateShareBlock',
        ];

        const currBlock = blocks[curr];
        // console.log({ blocks, curr, currBlock });

        return headerTypes.includes(currBlock['@type'])
          ? [[...head, curr], [...body]]
          : [[...head], [...body, curr]];
      },
      [[], []],
    );

    body = (
      <Grid
        style={{
          marginTop:
            'calc(var(--title-margin-top) - var(--breadcrumbs-margin))',
        }}
      >
        <Grid.Row>
          <Grid.Column mobile={12} computer={8}>
            <RenderBlocks
              content={{ ...content, blocks_layout: { items: headBlocks } }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: 0, paddingBottom: 60 }}>
          <Grid.Column style={{ paddingBottom: 40 }} mobile={12} computer={8}>
            <RenderBlocks
              content={{ ...content, blocks_layout: { items: bodyBlocks } }}
            />
          </Grid.Column>
          <Grid.Column mobile={12} computer={4}>
            <RelatedItems relatedItems={relatedItems} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  return (
    <Container id="page-document" className="view-wrapper newsitem-view">
      {body}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
