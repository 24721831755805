import { defineMessages } from 'react-intl';
import textCenteredSVG from '@plone/volto/icons/align-center.svg';
import textLeftSVG from '@plone/volto/icons/align-left.svg';
import textRightSVG from '@plone/volto/icons/align-right.svg';

const messages = defineMessages({
  sectionTitle: { id: 'Section title', defaultMessage: 'Section title' },
  mainHeader: { id: 'Page main header', defaultMessage: 'Page main header' },
  align: {
    id: 'Align',
    defaultMessage: 'Align',
  },
  left: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  right: {
    id: 'Right',
    defaultMessage: 'Right',
  },
  center: {
    id: 'Center',
    defaultMessage: 'Center',
  },
});

export const Schema = ({ intl }) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.sectionTitle),
        fields: ['title', 'mainHeader', 'align'],
      },
    ],
    properties: {
      title: { title: intl.formatMessage(messages.sectionTitle) },
      mainHeader: {
        title: intl.formatMessage(messages.mainHeader),
        type: 'boolean',
      },
      align: {
        title: intl.formatMessage(messages.align),
        required: false,
        default: 'center',
        type: 'string',
        widget: 'buttons',
        actions: ['left', 'center', 'right'],
        actionsInfoMap: {
          left: [textLeftSVG, intl.formatMessage(messages.left)],
          center: [textCenteredSVG, intl.formatMessage(messages.center)],
          right: [textRightSVG, intl.formatMessage(messages.right)],
        },
      },
    },
    required: [],
  };
};
