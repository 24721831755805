import { memo } from 'react';
const SvgEdit3 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="edit-3_svg__feather edit-3_svg__feather-edit-3"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12 20h9M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
  </svg>
);
const Memo = memo(SvgEdit3);
export default Memo;
