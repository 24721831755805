import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './DividerBody.module.less';

const DividerBody = ({ data }) => {
  const { spacing, showLine } = data;

  const classes = cx(styles[spacing], {
    [styles.line]: showLine,
  });

  return <div className={classes}></div>;
};

DividerBody.propTypes = {
  data: PropTypes.shape({
    size: PropTypes.string,
    spacing: PropTypes.string,
  }),

  isEditMode: PropTypes.bool,
};

export default DividerBody;
