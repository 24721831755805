import { defineMessages, useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getG20SocialNetworks } from '@package/actions';
import cx from 'classnames';
import IconButton from '@package/components/IconButton';

import styles from './SocialLinks.module.less';

const messages = defineMessages({
  visitUsOn: {
    id: 'Visit us on {siteName}',
    defaultMessage: 'Visit us on {siteName}',
  },
});

const SocialLinks = ({ className }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getG20SocialNetworks());
  }, [dispatch]);

  const result = useSelector((state) => {
    return state.g20SocialNetworks;
  });

  if (!result.loaded || result.error || !result?.data?.items) {
    return null;
  }

  return (
    <div className={cx(styles.social, { [className]: className })}>
      {result.data.items.map((network, id) => {
        const { social_network, social_network_url } = network;

        if (!social_network || !social_network_url) return null;

        return (
          <IconButton
            key={`${social_network_url}-${id}`}
            href={social_network_url}
            large
            icon={social_network}
            rel="noopener noreferrer"
            target="_blank"
            title={intl.formatMessage(messages.visitUsOn, {
              siteName: social_network,
            })}
          />
        );
      })}
    </div>
  );
};

export default SocialLinks;
