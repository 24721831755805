import PropTypes from 'prop-types';
import { SidebarPortal } from '@plone/volto/components';
import NumbersData from './NumbersData';
import NumbersBody from './NumbersBody';

const Edit = (props) => {
  const { data, block, selected, intl } = props;

  return (
    <>
      <NumbersBody data={data} id={block} intl={intl} isEditMode />
      <SidebarPortal selected={selected}>
        <NumbersData key={block} {...props} data={data} block={block} />
      </SidebarPortal>
    </>
  );
};

Edit.propTypes = {
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.bool,
};

export default Edit;
