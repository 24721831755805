/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import g20Events from './g20Events';
import g20Cities from './g20Cities';
import g20Tracks from './g20Tracks';
import g20Workgroups from './g20Workgroups';
import g20SocialNetworks from './g20SocialNetworks';
import g20FooterLinks from './g20FooterLinks';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  // Add your reducers here
  g20Events,
  g20Cities,
  g20Tracks,
  g20Workgroups,
  g20SocialNetworks,
  g20FooterLinks,
};

export default reducers;
