import { Icon } from '@package/icons';
import config from '@plone/volto/registry';
import cx from 'classnames';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { defineMessages, injectIntl } from 'react-intl';
import { Accordion, Input } from 'semantic-ui-react';

const messages = defineMessages({
  EnterTitle: {
    id: 'Enter Title…',
    defaultMessage: 'Enter Title…',
  },
});

const AccordionEdit = (props) => {
  const {
    children,
    handleTitleChange,
    handleTitleClick,
    uid,
    panel,
    data,
    index,
    intl,
  } = props;
  const [activeIndex, setActiveIndex] = React.useState([0]);
  const accordionConfig = config.blocks.blocksConfig.accordion;
  const isActive = activeIndex.includes(index);
  const iconOnLeft = data.left_arrows;

  const handleClick = (e, itemProps) => {
    const { index } = itemProps;
    if (data.exclusive) {
      const newIndex =
        activeIndex.indexOf(index) === -1
          ? [index]
          : activeIndex.filter((item) => item !== index);

      setActiveIndex(newIndex);
    } else {
      const newIndex =
        activeIndex.indexOf(index) === -1
          ? [...activeIndex, index]
          : activeIndex.filter((item) => item !== index);

      setActiveIndex(newIndex);
    }
  };

  React.useEffect(() => {
    return data.firstOpen ? setActiveIndex([0]) : setActiveIndex([]);
  }, [data.firstOpen]);

  return (
    <Accordion
      className={
        data.styles ? data.styles.theme : accordionConfig?.defaults?.theme
      }
      {...accordionConfig.options}
    >
      <React.Fragment>
        <Accordion.Title
          as="h3"
          active={isActive}
          index={index}
          onClick={handleClick}
          className={cx('accordion-title', {
            'align-arrow-left': iconOnLeft,
            'align-arrow-right': !iconOnLeft,
          })}
        >
          <Icon icon={isActive ? 'ArrowDown' : 'ArrowRight'} className="" />
          {!data.readOnlyTitles ? (
            <Input
              fluid
              className="input-accordion-title"
              transparent
              placeholder={intl.formatMessage(messages.EnterTitle)}
              value={panel?.title || ''}
              onClick={(e) => {
                handleTitleClick();
                e.stopPropagation();
              }}
              onChange={(e) => handleTitleChange(e, [uid, panel])}
            />
          ) : (
            <span>{panel?.title}</span>
          )}
        </Accordion.Title>
        <AnimateHeight
          animateOpacity
          duration={500}
          height={isActive ? 'auto' : 0}
        >
          <Accordion.Content active={isActive}>{children}</Accordion.Content>
        </AnimateHeight>
      </React.Fragment>
    </Accordion>
  );
};

export default injectIntl(AccordionEdit);
