import { GET_G20_CITIES } from '../constants/ActionTypes';

import config from '@plone/volto/registry';

export function getG20Cities() {
  const { settings } = config;

  return {
    type: GET_G20_CITIES,
    request: {
      op: 'get',
      path: `${settings.apiPath}/g20-api/cities`,
    },
  };
}
