import { defineMessages } from 'react-intl';
import textCenteredSVG from '@plone/volto/icons/align-center.svg';
import textLeftSVG from '@plone/volto/icons/align-left.svg';
import textRightSVG from '@plone/volto/icons/align-right.svg';
import { blocks as blocksMessages } from '../../../../../../messages';

const messages = defineMessages({
  Video: {
    id: 'Video',
    defaultMessage: 'Video',
  },
  VideoURL: {
    id: 'Video URL',
    defaultMessage: 'Video URL',
  },
  Preview_image: {
    id: 'Preview Image URL',
    defaultMessage: 'Preview Image URL',
  },
  Alignment: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  playAlign: { id: 'Play button align', defaultMessage: 'Play button align' },
  left: { id: 'Left', defaultMessage: 'Left' },
  center: { id: 'Center', defaultMessage: 'Center' },
  right: { id: 'Right', defaultMessage: 'Right' },
});
export const VideoBlockSchema = (props) => ({
  title: props.intl.formatMessage(messages.Video),
  block: 'Video',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'url',
        'align',
        'showPreviewImage',
        'preview_image',
        'playAlign',
      ],
    },
  ],

  properties: {
    url: {
      title: props.intl.formatMessage(messages.VideoURL),
      widget: 'url',
    },
    showPreviewImage: {
      title: props.intl.formatMessage(blocksMessages.showPreviewImage),
      default: true,
      type: 'boolean',
    },
    preview_image: {
      title: props.intl.formatMessage(messages.Preview_image),
      widget: props.data.showPreviewImage ? 'url' : 'text',
      isDisabled: !props.data.showPreviewImage,
    },
    align: {
      title: props.intl.formatMessage(messages.Alignment),
      widget: 'align',
    },
    playAlign: {
      title: props.intl.formatMessage(messages.playAlign),
      required: false,
      default: 'center',
      type: 'string',
      isDisabled: !props.data.showPreviewImage,
      widget: 'buttons',
      actions: ['left', 'center', 'right'],
      actionsInfoMap: {
        left: [textLeftSVG, props.intl.formatMessage(messages.left)],
        center: [textCenteredSVG, props.intl.formatMessage(messages.center)],
        right: [textRightSVG, props.intl.formatMessage(messages.right)],
      },
    },
  },
  required: [],
});

export default VideoBlockSchema;
