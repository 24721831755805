import { GET_G20_FOOTER_LINKS } from '../constants/ActionTypes';

export function getG20FooterLinks() {
  return {
    type: GET_G20_FOOTER_LINKS,
    request: {
      op: 'get',
      path: '/@linksrodape',
    },
  };
}
