import cx from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import { headerMessages } from '@package/messages';
import { getNavigation } from '@plone/volto/actions';
import NavItems from '@plone/volto/components/theme/Navigation/NavItems';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { IconButton, SiteNavigation } from '@package/components';

import styles from './Navigation.module.less';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});

const Navigation = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname, type } = props;
  const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const items = useSelector((state) => state.navigation.items, shallowEqual);
  const lang = useSelector((state) => state.intl.locale);

  useEffect(() => {
    const { settings } = config;
    if (!hasApiExpander('navigation', getBaseUrl(pathname))) {
      dispatch(getNavigation(getBaseUrl(pathname), settings.navDepth));
    }
  }, [pathname, token, dispatch]);

  const toggleMobileMenu = () => {
    setisMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    if (!isMobileMenuOpen) {
      return;
    }
    setisMobileMenuOpen(false);
  };

  return (
    <nav className="navigation" id="navigation" aria-label="navigation">
      <div className="mobile tablet only">
        <IconButton
          border
          icon="Menu"
          aria-label={
            isMobileMenuOpen
              ? intl.formatMessage(messages.closeMobileMenu, {
                  type: type,
                })
              : intl.formatMessage(messages.openMobileMenu, {
                  type: type,
                })
          }
          title={
            isMobileMenuOpen
              ? intl.formatMessage(messages.closeMobileMenu, {
                  type: type,
                })
              : intl.formatMessage(messages.openMobileMenu, {
                  type: type,
                })
          }
          type="button"
          className="hamburguer-menu"
          onClick={toggleMobileMenu}
        />
      </div>
      <Menu
        secondary
        className={cx('computer large screen widescreen only', styles.desk)}
        onClick={closeMobileMenu}
      >
        <NavItems items={items} lang={lang} />
      </Menu>
      <SiteNavigation
        onToggleMenu={closeMobileMenu}
        title="G20 Brasil 2024"
        active={isMobileMenuOpen}
        menu-label={intl.formatMessage(headerMessages.menu_label)}
        close-label={intl.formatMessage(headerMessages.close_label)}
      >
        {/* Lateral */}
        <SiteNavigation.Group items={items} lang={lang} />

        {/* Vertical */}
        {/* {items.map(item=>{
          return <SiteNavigation.Group items={item.items} lang={lang} collapsible title={item.title} description={item.description} />
        })} */}
      </SiteNavigation>
    </nav>
  );
};

Navigation.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Navigation;
