import { injectIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import styles from './ModalHeader.module.less';

const ModalHeader = ({ intl }) => {
  if (intl.locale === 'en') {
    return (
      <div className={styles.root}>
        <div className={styles.bold}>Advanced cookie settings</div>
        <p>
          <UniversalLink href="/en/lgpd" openLinkInNewTab>
            See Cookies Statement
          </UniversalLink>
        </p>
        <p>
          To improve your experience on the platform and provide personalized
          services, we use cookies.
        </p>
      </div>
    );
  }

  if (intl.locale === 'es') {
    return (
      <div className={styles.root}>
        <div className={styles.bold}>Configuración avanzada de cookies</div>
        <p>
          <UniversalLink href="/es/lgpd" openLinkInNewTab>
            Ver Declaración de cookies
          </UniversalLink>
        </p>
        <p>
          Para mejorar su experiencia en la plataforma y brindarle servicios
          personalizados, utilizamos cookies.
        </p>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.bold}>Configurações avançadas de cookies</div>
      <p>
        <UniversalLink href="/pt-br/lgpd" openLinkInNewTab>
          Ver Declaração de Cookies
        </UniversalLink>
      </p>
      <p>
        Para melhorar a sua experiência na plataforma e prover serviços
        personalizados, utilizamos cookies.
      </p>
    </div>
  );
};

export default injectIntl(ModalHeader);
