import { createElement } from 'react';
import cx from 'classnames';

// import './Icon.css';
import * as icons from './assets';

/**
 *
 * @param icon string key icon name
 * @param className string classes for styling
 * @param rotate optional number rotation of the icon
 * @returns Icon react component
 */
const Icon = ({ icon, size = 24, defaultColor, className }) => {
  if (!icon || !Object(icons).hasOwnProperty(icon)) {
    return null;
  }

  return createElement(icons[icon], {
    className: cx({
      'g20-icon': !defaultColor,
      className,
    }),
    style: { width: size, height: size },
  });
};

export default Icon;
