import { memo } from 'react';
const SvgMinimize2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="minimize-2_svg__feather minimize-2_svg__feather-minimize-2"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M4 14h6v6M20 10h-6V4M14 10l7-7M3 21l7-7" />
  </svg>
);
const Memo = memo(SvgMinimize2);
export default Memo;
