const LogoFull = ({ titleId, title, size, className }) => (
  <svg
    aria-labelledby={titleId}
    className={className}
    style={size}
    width="373"
    height="186"
    viewBox="0 0 373 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={titleId}>{title}</title>

    <g clipPath="url(#clip0_192_3408)">
      <path
        d="M37.8645 154.411C35.2998 155.752 32.6191 156.719 29.8354 157.312C27.0516 157.905 24.2034 158.202 21.3036 158.202C18.2234 158.202 15.3752 157.712 12.7719 156.758C10.1685 155.791 7.91314 154.411 6.01862 152.606C4.12411 150.801 2.64201 148.622 1.5852 146.056C0.528402 143.491 0 140.628 0 137.469C0 134.31 0.528402 131.448 1.5852 128.882C2.64201 126.317 4.12411 124.138 6.01862 122.332C7.91314 120.527 10.1685 119.148 12.7719 118.181C15.3752 117.214 18.2234 116.737 21.3036 116.737C24.3838 116.737 27.3738 117.124 30.0287 117.884C32.6836 118.645 35.0163 119.96 37.0268 121.817L30.8922 128.508C29.7065 127.284 28.3661 126.355 26.884 125.723C25.4019 125.092 23.5332 124.769 21.3036 124.769C19.4478 124.769 17.7594 125.092 16.2516 125.723C14.7437 126.355 13.4549 127.245 12.3723 128.379C11.2898 129.514 10.4649 130.868 9.88498 132.428C9.30502 133.988 9.02149 135.69 9.02149 137.508C9.02149 139.326 9.30502 141.067 9.88498 142.614C10.4649 144.161 11.2898 145.502 12.3723 146.637C13.4549 147.771 14.7437 148.661 16.2516 149.293C17.7594 149.924 19.4349 150.247 21.3036 150.247C23.1723 150.247 24.8349 150.027 26.2139 149.602C27.5929 149.177 28.6883 148.738 29.5003 148.287V141.866H18.9322L26.0463 133.833H37.8645V154.476V154.411Z"
        fill="#006DA7"
      />
      <path
        d="M40.8809 148.338L56.2174 134.555C57.0036 133.846 57.7768 133.047 58.563 132.157C59.3491 131.268 59.7358 130.223 59.7358 129.037C59.7358 127.696 59.246 126.652 58.2923 125.878C57.3257 125.117 56.1916 124.731 54.8899 124.731C53.3305 124.731 52.1062 125.221 51.2427 126.175C50.3663 127.142 49.8766 128.328 49.7606 129.746L41.3964 129.127C41.5124 127.077 41.9377 125.311 42.6852 123.802C43.4327 122.294 44.4121 121.043 45.6365 120.037C46.8608 119.032 48.3043 118.284 49.9539 117.781C51.6035 117.278 53.4078 117.02 55.341 117.02C57.1324 117.02 58.795 117.278 60.3544 117.781C61.9138 118.284 63.2799 119.032 64.427 120.012C65.574 120.992 66.4761 122.229 67.1334 123.725C67.7778 125.208 68.1129 126.948 68.1129 128.908C68.1129 130.172 67.984 131.319 67.7262 132.338C67.4685 133.356 67.1076 134.285 66.6437 135.123C66.1797 135.961 65.6255 136.734 64.994 137.431C64.3625 138.14 63.6795 138.823 62.932 139.494L50.9463 149.821H68.4608V157.183H40.9066V148.313L40.8809 148.338Z"
        fill="#006DA7"
      />
      <path
        d="M78.7453 137.456C78.7453 139.313 79.0288 141.015 79.6088 142.562C80.1888 144.109 81.0136 145.45 82.0833 146.585C83.1658 147.72 84.4546 148.609 85.9625 149.228C87.4704 149.86 89.1458 150.182 91.0145 150.182C92.8833 150.182 94.5587 149.86 96.0666 149.228C97.5745 148.596 98.8632 147.707 99.9458 146.585C101.028 145.45 101.853 144.109 102.433 142.562C103.013 141.015 103.297 139.313 103.297 137.456C103.297 135.6 103.013 133.936 102.433 132.376C101.853 130.816 101.028 129.462 99.9458 128.328C98.8632 127.193 97.5745 126.317 96.0666 125.685C94.5587 125.053 92.8833 124.731 91.0145 124.731C89.1458 124.731 87.4704 125.053 85.9625 125.685C84.4546 126.317 83.1658 127.206 82.0833 128.328C81.0007 129.462 80.1759 130.816 79.6088 132.376C79.0288 133.936 78.7453 135.626 78.7453 137.456ZM69.7109 137.456C69.7109 134.298 70.2393 131.435 71.2961 128.869C72.3529 126.304 73.835 124.125 75.7296 122.32C77.6241 120.514 79.8794 119.135 82.4828 118.168C85.0861 117.201 87.9344 116.724 91.0145 116.724C94.0947 116.724 96.943 117.214 99.5463 118.168C102.15 119.122 104.392 120.514 106.3 122.32C108.194 124.125 109.676 126.304 110.733 128.869C111.79 131.435 112.318 134.298 112.318 137.456C112.318 140.615 111.79 143.478 110.733 146.043C109.676 148.609 108.194 150.801 106.3 152.593C104.405 154.398 102.15 155.778 99.5463 156.745C96.943 157.712 94.0947 158.189 91.0145 158.189C87.9344 158.189 85.0861 157.699 82.4828 156.745C79.8794 155.778 77.6241 154.398 75.7296 152.593C73.835 150.788 72.3529 148.609 71.2961 146.043C70.2393 143.478 69.7109 140.615 69.7109 137.456Z"
        fill="#006DA7"
      />
      <path
        d="M189.787 117.626H205.214C206.696 117.626 208.178 117.807 209.622 118.155C211.078 118.503 212.367 119.083 213.501 119.883C214.635 120.682 215.563 121.714 216.272 122.99C216.98 124.254 217.328 125.788 217.328 127.567C217.328 129.798 216.697 131.654 215.434 133.098C214.171 134.555 212.508 135.6 210.459 136.232V136.348C211.684 136.502 212.831 136.838 213.9 137.379C214.957 137.921 215.872 138.617 216.607 139.455C217.354 140.293 217.934 141.26 218.372 142.356C218.798 143.452 219.017 144.638 219.017 145.902C219.017 148.068 218.591 149.86 217.728 151.291C216.864 152.722 215.743 153.883 214.351 154.759C212.959 155.636 211.348 156.255 209.544 156.629C207.74 157.003 205.897 157.183 204.041 157.183H189.787V117.613V117.626ZM196.824 133.73H203.242C205.588 133.73 207.353 133.279 208.526 132.364C209.699 131.448 210.292 130.249 210.292 128.753C210.292 127.039 209.686 125.762 208.474 124.924C207.263 124.086 205.317 123.66 202.636 123.66H196.824V133.717V133.73ZM196.824 151.175H203.306C204.196 151.175 205.162 151.123 206.18 151.007C207.199 150.891 208.139 150.633 209.003 150.221C209.853 149.808 210.562 149.228 211.129 148.454C211.684 147.694 211.967 146.662 211.967 145.347C211.967 143.258 211.284 141.801 209.931 140.989C208.565 140.177 206.451 139.764 203.59 139.764H196.837V151.162L196.824 151.175Z"
        fill="#006DA7"
      />
      <path
        d="M226.002 117.626H239.753C241.648 117.626 243.491 117.807 245.256 118.155C247.022 118.503 248.594 119.109 249.973 119.973C251.352 120.837 252.448 121.984 253.273 123.441C254.097 124.898 254.497 126.742 254.497 128.973C254.497 131.848 253.711 134.246 252.126 136.18C250.54 138.114 248.285 139.287 245.334 139.7L255.837 157.196H247.344L238.181 140.435H233.039V157.196H226.002V117.626ZM238.516 134.401C239.521 134.401 240.527 134.349 241.532 134.259C242.537 134.169 243.452 133.937 244.303 133.588C245.14 133.24 245.823 132.699 246.339 131.964C246.867 131.242 247.125 130.223 247.125 128.921C247.125 127.761 246.88 126.832 246.403 126.123C245.914 125.414 245.282 124.885 244.509 124.524C243.723 124.163 242.859 123.931 241.906 123.828C240.952 123.712 240.037 123.661 239.135 123.661H233.039V134.388H238.516V134.401Z"
        fill="#006DA7"
      />
      <path
        d="M273.996 117.626H280.092L297.13 157.196H289.088L285.402 148.145H268.248L264.665 157.196H256.791L273.996 117.626ZM282.94 142.111L276.844 126.007L270.645 142.111H282.94Z"
        fill="#006DA7"
      />
      <path
        d="M320.932 126.123C320.185 125.04 319.192 124.254 317.942 123.751C316.692 123.248 315.378 122.99 313.999 122.99C313.174 122.99 312.388 123.08 311.627 123.274C310.867 123.467 310.171 123.764 309.565 124.163C308.947 124.576 308.457 125.105 308.083 125.762C307.709 126.42 307.529 127.193 307.529 128.083C307.529 129.424 307.993 130.455 308.921 131.152C309.849 131.861 311.009 132.48 312.388 132.995C313.767 133.524 315.275 134.027 316.911 134.504C318.548 134.994 320.056 135.664 321.435 136.515C322.814 137.379 323.974 138.514 324.902 139.932C325.83 141.35 326.294 143.233 326.294 145.579C326.294 147.707 325.907 149.563 325.121 151.136C324.335 152.722 323.291 154.037 321.963 155.082C320.636 156.126 319.102 156.913 317.35 157.428C315.597 157.957 313.754 158.215 311.821 158.215C309.359 158.215 307.001 157.802 304.719 156.99C302.451 156.178 300.492 154.798 298.855 152.851L304.165 147.707C305.016 149.009 306.15 150.027 307.542 150.749C308.934 151.472 310.416 151.845 311.988 151.845C312.813 151.845 313.625 151.729 314.45 151.51C315.275 151.291 316.009 150.956 316.679 150.505C317.35 150.053 317.891 149.486 318.303 148.803C318.716 148.119 318.922 147.32 318.922 146.43C318.922 144.973 318.458 143.864 317.53 143.078C316.602 142.291 315.442 141.634 314.063 141.092C312.684 140.551 311.176 140.035 309.539 139.558C307.903 139.068 306.395 138.411 305.016 137.572C303.637 136.734 302.477 135.613 301.549 134.22C300.621 132.828 300.157 130.945 300.157 128.599C300.157 126.549 300.582 124.782 301.42 123.287C302.258 121.791 303.353 120.553 304.719 119.573C306.073 118.593 307.632 117.846 309.385 117.369C311.138 116.879 312.929 116.646 314.746 116.646C316.834 116.646 318.857 116.969 320.803 117.601C322.762 118.232 324.515 119.277 326.087 120.734L320.945 126.149L320.932 126.123Z"
        fill="#006DA7"
      />
      <path
        d="M340.484 117.626H333.447V157.196H340.484V117.626Z"
        fill="#006DA7"
      />
      <path
        d="M349.145 117.626H356.181V150.827H373V157.209H349.145V117.626Z"
        fill="#006DA7"
      />
      <path
        d="M190.882 182.622L199.298 174.254C199.594 173.983 199.878 173.674 200.161 173.352C200.432 173.029 200.69 172.681 200.909 172.333C201.128 171.985 201.295 171.598 201.424 171.211C201.553 170.812 201.618 170.399 201.618 169.961C201.618 169.445 201.527 168.981 201.334 168.568C201.141 168.156 200.896 167.807 200.561 167.511C200.239 167.227 199.852 167.008 199.401 166.84C198.95 166.686 198.486 166.608 197.996 166.608C196.965 166.608 196.114 166.892 195.444 167.472C194.774 168.04 194.349 168.839 194.168 169.845L191.088 169.574C191.23 168.646 191.501 167.833 191.9 167.15C192.3 166.467 192.815 165.899 193.408 165.448C194.014 164.997 194.697 164.661 195.483 164.442C196.269 164.223 197.107 164.12 198.022 164.12C198.937 164.12 199.788 164.249 200.587 164.494C201.386 164.739 202.094 165.1 202.687 165.59C203.293 166.08 203.77 166.673 204.105 167.395C204.453 168.117 204.62 168.968 204.62 169.935C204.62 170.631 204.517 171.276 204.311 171.882C204.105 172.488 203.834 173.055 203.499 173.584C203.164 174.112 202.778 174.628 202.339 175.092C201.901 175.569 201.45 176.033 200.973 176.485L194.065 183.163H204.62V185.652H190.869V182.622H190.882Z"
        fill="#006DA7"
      />
      <path
        d="M207.005 175.144C207.005 174.37 207.044 173.571 207.121 172.746C207.198 171.92 207.34 171.095 207.546 170.296C207.753 169.496 208.036 168.723 208.371 168.001C208.719 167.266 209.157 166.634 209.712 166.093C210.253 165.551 210.897 165.113 211.645 164.79C212.379 164.468 213.243 164.3 214.235 164.3C215.228 164.3 216.091 164.468 216.826 164.79C217.573 165.113 218.205 165.551 218.746 166.093C219.287 166.634 219.738 167.279 220.086 168.001C220.434 168.736 220.705 169.496 220.911 170.296C221.117 171.095 221.259 171.908 221.336 172.746C221.414 173.571 221.452 174.37 221.452 175.144C221.452 176.253 221.349 177.439 221.156 178.702C220.963 179.966 220.589 181.139 220.06 182.235C219.532 183.318 218.785 184.221 217.857 184.93C216.916 185.639 215.704 186 214.222 186C212.74 186 211.529 185.639 210.588 184.93C209.647 184.221 208.912 183.318 208.384 182.235C207.856 181.152 207.482 179.966 207.289 178.702C207.095 177.439 206.992 176.253 206.992 175.144M209.84 175.144C209.84 175.84 209.879 176.652 209.969 177.606C210.059 178.561 210.253 179.476 210.549 180.353C210.846 181.229 211.284 181.977 211.864 182.596C212.444 183.215 213.23 183.512 214.222 183.512C215.215 183.512 216.001 183.202 216.581 182.596C217.161 181.99 217.599 181.242 217.895 180.353C218.192 179.476 218.385 178.561 218.475 177.606C218.565 176.652 218.604 175.84 218.604 175.144C218.604 174.667 218.591 174.125 218.553 173.532C218.527 172.926 218.449 172.32 218.346 171.688C218.23 171.069 218.089 170.463 217.882 169.883C217.689 169.303 217.418 168.774 217.096 168.31C216.774 167.846 216.375 167.472 215.898 167.201C215.421 166.918 214.867 166.789 214.209 166.789C213.552 166.789 212.998 166.931 212.521 167.201C212.044 167.472 211.645 167.846 211.323 168.31C211 168.774 210.73 169.303 210.536 169.883C210.343 170.463 210.188 171.069 210.072 171.688C209.969 172.307 209.892 172.926 209.866 173.532C209.84 174.138 209.828 174.68 209.828 175.144"
        fill="#006DA7"
      />
      <path
        d="M223.835 182.622L232.251 174.254C232.547 173.983 232.831 173.674 233.114 173.352C233.385 173.029 233.643 172.681 233.862 172.333C234.081 171.985 234.249 171.598 234.377 171.211C234.506 170.812 234.571 170.399 234.571 169.961C234.571 169.445 234.481 168.981 234.287 168.568C234.094 168.156 233.849 167.807 233.514 167.511C233.192 167.227 232.805 167.008 232.354 166.84C231.903 166.686 231.439 166.608 230.949 166.608C229.918 166.608 229.068 166.892 228.397 167.472C227.727 168.04 227.302 168.839 227.122 169.845L224.041 169.574C224.183 168.646 224.454 167.833 224.853 167.15C225.253 166.467 225.768 165.899 226.361 165.448C226.967 164.997 227.65 164.661 228.436 164.442C229.222 164.223 230.06 164.12 230.975 164.12C231.89 164.12 232.741 164.249 233.54 164.494C234.339 164.739 235.048 165.1 235.64 165.59C236.246 166.08 236.723 166.673 237.058 167.395C237.406 168.117 237.574 168.968 237.574 169.935C237.574 170.631 237.471 171.276 237.264 171.882C237.058 172.488 236.787 173.055 236.452 173.584C236.117 174.112 235.731 174.628 235.292 175.092C234.854 175.569 234.403 176.033 233.926 176.485L227.018 183.163H237.574V185.652H223.822V182.622H223.835Z"
        fill="#006DA7"
      />
      <path
        d="M248.916 181.023H239.959V178.122L248.31 164.661H251.751V178.535H254.716V181.023H251.751V185.652H248.903V181.023H248.916ZM248.916 168.04H248.852L242.446 178.535H248.903V168.04H248.916Z"
        fill="#006DA7"
      />
      <path
        d="M265.271 165.59C265.168 165.461 265.039 165.358 264.884 165.293C264.73 165.229 264.549 165.19 264.343 165.19C264.137 165.19 263.957 165.229 263.789 165.306C263.621 165.383 263.467 165.487 263.338 165.628C263.209 165.77 263.106 165.938 263.042 166.131C262.964 166.325 262.938 166.531 262.938 166.763C262.938 166.995 262.977 167.201 263.042 167.395C263.106 167.588 263.209 167.756 263.338 167.898C263.467 168.039 263.609 168.143 263.776 168.22C263.944 168.297 264.124 168.336 264.317 168.336C264.537 168.336 264.73 168.284 264.897 168.194C265.065 168.104 265.207 167.975 265.323 167.807L266.225 168.478C266.019 168.762 265.761 168.981 265.439 169.123C265.116 169.264 264.794 169.329 264.459 169.329C264.073 169.329 263.725 169.264 263.402 169.148C263.08 169.032 262.81 168.852 262.565 168.633C262.333 168.413 262.152 168.143 262.023 167.82C261.895 167.498 261.83 167.15 261.83 166.763C261.83 166.376 261.895 166.015 262.023 165.706C262.152 165.383 262.333 165.113 262.565 164.894C262.797 164.674 263.08 164.494 263.402 164.378C263.725 164.262 264.073 164.197 264.459 164.197C264.601 164.197 264.743 164.197 264.884 164.236C265.026 164.262 265.181 164.3 265.323 164.352C265.464 164.404 265.606 164.481 265.735 164.571C265.864 164.661 265.993 164.777 266.096 164.906L265.271 165.577V165.59Z"
        fill="#006DA7"
      />
      <path
        d="M266.883 166.763C266.883 166.376 266.947 166.015 267.076 165.706C267.205 165.383 267.385 165.113 267.63 164.894C267.862 164.674 268.146 164.494 268.468 164.378C268.79 164.262 269.138 164.197 269.525 164.197C269.911 164.197 270.259 164.262 270.582 164.378C270.904 164.494 271.187 164.661 271.419 164.894C271.651 165.113 271.832 165.383 271.961 165.706C272.09 166.028 272.154 166.376 272.154 166.763C272.154 167.15 272.09 167.511 271.961 167.82C271.832 168.143 271.651 168.413 271.419 168.633C271.187 168.852 270.904 169.032 270.582 169.148C270.259 169.264 269.911 169.329 269.525 169.329C269.138 169.329 268.79 169.264 268.468 169.148C268.146 169.032 267.875 168.852 267.63 168.633C267.398 168.413 267.218 168.143 267.076 167.82C266.947 167.498 266.883 167.15 266.883 166.763ZM268.004 166.763C268.004 166.995 268.043 167.201 268.107 167.395C268.172 167.588 268.288 167.756 268.416 167.885C268.545 168.027 268.713 168.13 268.893 168.207C269.087 168.284 269.293 168.323 269.512 168.323C269.731 168.323 269.95 168.284 270.131 168.207C270.324 168.13 270.479 168.014 270.607 167.885C270.736 167.743 270.839 167.575 270.917 167.395C270.981 167.201 271.02 166.995 271.02 166.763C271.02 166.531 270.981 166.325 270.917 166.131C270.852 165.938 270.736 165.77 270.607 165.628C270.479 165.487 270.311 165.383 270.131 165.306C269.95 165.229 269.731 165.19 269.512 165.19C269.293 165.19 269.074 165.229 268.893 165.306C268.713 165.383 268.545 165.5 268.416 165.628C268.288 165.77 268.184 165.938 268.107 166.131C268.03 166.325 268.004 166.531 268.004 166.763Z"
        fill="#006DA7"
      />
      <path
        d="M273.223 164.313H274.679L276.754 167.704H276.767V164.313H277.849V169.2H276.445L274.305 165.732H274.292V169.2H273.223V164.313Z"
        fill="#006DA7"
      />
      <path
        d="M281.588 165.512C281.497 165.396 281.381 165.319 281.227 165.267C281.072 165.216 280.943 165.19 280.802 165.19C280.724 165.19 280.647 165.19 280.557 165.216C280.479 165.229 280.402 165.267 280.325 165.306C280.247 165.345 280.196 165.396 280.144 165.461C280.093 165.525 280.08 165.603 280.08 165.693C280.08 165.835 280.131 165.951 280.247 166.028C280.363 166.105 280.492 166.17 280.66 166.234C280.827 166.286 281.008 166.35 281.201 166.402C281.394 166.454 281.575 166.531 281.742 166.634C281.91 166.737 282.052 166.866 282.155 167.047C282.271 167.214 282.322 167.446 282.322 167.73C282.322 168.014 282.271 168.233 282.168 168.439C282.065 168.645 281.936 168.813 281.755 168.942C281.588 169.071 281.381 169.174 281.149 169.239C280.917 169.303 280.686 169.329 280.428 169.329C280.106 169.329 279.822 169.277 279.551 169.187C279.281 169.097 279.023 168.929 278.791 168.723L279.551 167.885C279.667 168.027 279.796 168.143 279.964 168.22C280.131 168.297 280.299 168.336 280.479 168.336C280.57 168.336 280.66 168.336 280.75 168.31C280.84 168.284 280.917 168.259 280.995 168.22C281.059 168.181 281.124 168.13 281.162 168.065C281.201 168.001 281.227 167.923 281.227 167.833C281.227 167.691 281.175 167.575 281.059 167.485C280.943 167.395 280.802 167.33 280.634 167.266C280.466 167.201 280.286 167.15 280.08 167.085C279.886 167.021 279.706 166.944 279.526 166.84C279.358 166.737 279.216 166.608 279.1 166.441C278.984 166.273 278.933 166.054 278.933 165.783C278.933 165.512 278.984 165.293 279.087 165.1C279.191 164.906 279.332 164.739 279.5 164.597C279.667 164.468 279.874 164.365 280.093 164.3C280.325 164.236 280.557 164.197 280.789 164.197C281.059 164.197 281.33 164.236 281.588 164.313C281.845 164.391 282.077 164.52 282.284 164.713L281.549 165.525L281.588 165.512Z"
        fill="#006DA7"
      />
      <path
        d="M284.423 165.267H283.031V164.313H286.898V165.267H285.506V169.2H284.423V165.267Z"
        fill="#006DA7"
      />
      <path
        d="M287.787 164.313H289.682C289.926 164.313 290.171 164.339 290.39 164.391C290.61 164.442 290.816 164.52 290.983 164.636C291.151 164.752 291.28 164.906 291.383 165.087C291.486 165.28 291.537 165.512 291.537 165.796C291.537 166.144 291.447 166.428 291.267 166.673C291.086 166.918 290.829 167.072 290.481 167.124L291.718 169.187H290.429L289.411 167.24H288.883V169.187H287.813V164.313H287.787ZM288.857 166.338H289.488C289.591 166.338 289.682 166.338 289.798 166.338C289.901 166.338 290.004 166.312 290.094 166.273C290.184 166.234 290.249 166.183 290.313 166.118C290.365 166.041 290.403 165.938 290.403 165.809C290.403 165.68 290.378 165.59 290.326 165.512C290.274 165.435 290.21 165.383 290.133 165.345C290.055 165.306 289.965 165.28 289.862 165.267C289.759 165.254 289.669 165.242 289.566 165.242H288.857V166.35V166.338Z"
        fill="#006DA7"
      />
      <path
        d="M296.704 167.318C296.704 167.614 296.665 167.872 296.575 168.117C296.485 168.362 296.356 168.568 296.188 168.749C296.021 168.929 295.802 169.071 295.544 169.174C295.286 169.277 294.99 169.329 294.655 169.329C294.32 169.329 294.023 169.277 293.765 169.174C293.508 169.071 293.289 168.929 293.121 168.749C292.953 168.568 292.825 168.362 292.734 168.117C292.644 167.872 292.605 167.614 292.605 167.318V164.326H293.675V167.279C293.675 167.434 293.701 167.562 293.752 167.704C293.804 167.833 293.868 167.949 293.959 168.04C294.049 168.13 294.152 168.207 294.268 168.259C294.384 168.31 294.526 168.336 294.668 168.336C294.809 168.336 294.938 168.31 295.067 168.259C295.183 168.207 295.286 168.13 295.376 168.04C295.467 167.949 295.531 167.833 295.583 167.704C295.634 167.575 295.66 167.434 295.66 167.279V164.326H296.73V167.318H296.704Z"
        fill="#006DA7"
      />
      <path
        d="M299.101 164.313H298.031V169.2H299.101V164.313Z"
        fill="#006DA7"
      />
      <path
        d="M300.43 164.313H301.886L303.961 167.704H303.974V164.313H305.044V169.2H303.639L301.512 165.732H301.499V169.2H300.43V164.313Z"
        fill="#006DA7"
      />
      <path
        d="M306.384 164.313H307.995C308.382 164.313 308.755 164.365 309.103 164.442C309.451 164.532 309.761 164.674 310.018 164.868C310.276 165.061 310.482 165.319 310.637 165.628C310.792 165.938 310.869 166.325 310.869 166.763C310.869 167.163 310.792 167.511 310.65 167.807C310.508 168.104 310.302 168.362 310.044 168.568C309.786 168.774 309.503 168.929 309.168 169.032C308.833 169.135 308.485 169.187 308.124 169.187H306.371V164.3L306.384 164.313ZM307.454 168.207H308.008C308.253 168.207 308.485 168.181 308.704 168.13C308.923 168.078 309.103 168.001 309.258 167.885C309.413 167.769 309.542 167.614 309.632 167.421C309.722 167.227 309.761 166.995 309.761 166.724C309.761 166.479 309.722 166.273 309.632 166.105C309.542 165.925 309.426 165.783 309.271 165.667C309.116 165.551 308.936 165.461 308.742 165.409C308.536 165.345 308.33 165.319 308.098 165.319H307.467V168.22L307.454 168.207Z"
        fill="#006DA7"
      />
      <path
        d="M311.693 166.763C311.693 166.376 311.758 166.015 311.887 165.706C312.016 165.383 312.196 165.113 312.441 164.894C312.673 164.674 312.956 164.494 313.279 164.378C313.601 164.262 313.949 164.197 314.335 164.197C314.722 164.197 315.07 164.262 315.392 164.378C315.714 164.494 315.998 164.661 316.23 164.894C316.462 165.113 316.642 165.383 316.771 165.706C316.9 166.028 316.964 166.376 316.964 166.763C316.964 167.15 316.9 167.511 316.771 167.82C316.642 168.143 316.462 168.413 316.23 168.633C315.998 168.852 315.714 169.032 315.392 169.148C315.07 169.264 314.722 169.329 314.335 169.329C313.949 169.329 313.601 169.264 313.279 169.148C312.956 169.032 312.686 168.852 312.441 168.633C312.209 168.413 312.028 168.143 311.887 167.82C311.758 167.498 311.693 167.15 311.693 166.763ZM312.815 166.763C312.815 166.995 312.853 167.201 312.918 167.395C312.982 167.588 313.098 167.756 313.227 167.885C313.356 168.027 313.523 168.13 313.704 168.207C313.897 168.284 314.103 168.323 314.322 168.323C314.542 168.323 314.761 168.284 314.941 168.207C315.134 168.13 315.289 168.014 315.418 167.885C315.547 167.743 315.65 167.575 315.727 167.395C315.792 167.201 315.83 166.995 315.83 166.763C315.83 166.531 315.792 166.325 315.727 166.131C315.663 165.938 315.547 165.77 315.418 165.628C315.289 165.487 315.122 165.383 314.941 165.306C314.761 165.229 314.542 165.19 314.322 165.19C314.103 165.19 313.884 165.229 313.704 165.306C313.523 165.383 313.356 165.5 313.227 165.628C313.098 165.77 312.995 165.938 312.918 166.131C312.84 166.325 312.815 166.531 312.815 166.763Z"
        fill="#006DA7"
      />
      <path
        d="M324.53 167.318C324.53 167.614 324.491 167.872 324.401 168.117C324.311 168.362 324.182 168.568 324.014 168.749C323.847 168.929 323.628 169.071 323.37 169.174C323.112 169.277 322.816 169.329 322.481 169.329C322.146 169.329 321.849 169.277 321.592 169.174C321.334 169.071 321.115 168.929 320.947 168.749C320.78 168.568 320.651 168.362 320.561 168.117C320.47 167.872 320.432 167.614 320.432 167.318V164.326H321.501V167.279C321.501 167.434 321.527 167.562 321.579 167.704C321.63 167.833 321.695 167.949 321.785 168.04C321.875 168.13 321.978 168.207 322.094 168.259C322.21 168.31 322.352 168.336 322.494 168.336C322.635 168.336 322.764 168.31 322.893 168.259C323.009 168.207 323.112 168.13 323.203 168.04C323.293 167.949 323.357 167.833 323.409 167.704C323.46 167.575 323.486 167.434 323.486 167.279V164.326H324.556V167.318H324.53Z"
        fill="#006DA7"
      />
      <path
        d="M325.869 164.313H327.493L328.627 167.498H328.64L329.761 164.313H331.385V169.2H330.303V165.461H330.29L329.014 169.2H328.189L326.965 165.461H326.952V169.2H325.869V164.313Z"
        fill="#006DA7"
      />
      <path
        d="M262.074 172.591H263.711L264.832 175.776H264.845L265.966 172.591H267.59V177.477H266.521V173.726H266.508L265.219 177.477H264.407L263.17 173.726H263.157V177.477H262.074V172.591Z"
        fill="#006DA7"
      />
      <path
        d="M273.042 175.595C273.042 175.892 273.003 176.149 272.913 176.394C272.823 176.639 272.694 176.846 272.526 177.026C272.359 177.207 272.14 177.349 271.882 177.452C271.624 177.555 271.328 177.606 270.993 177.606C270.657 177.606 270.361 177.555 270.103 177.452C269.846 177.349 269.626 177.207 269.459 177.026C269.291 176.846 269.162 176.639 269.072 176.394C268.982 176.149 268.943 175.892 268.943 175.595V172.604H270.013V175.556C270.013 175.711 270.039 175.84 270.09 175.982C270.142 176.111 270.206 176.227 270.297 176.317C270.387 176.407 270.49 176.485 270.606 176.536C270.722 176.588 270.864 176.614 271.005 176.614C271.147 176.614 271.276 176.588 271.405 176.536C271.521 176.485 271.624 176.407 271.714 176.317C271.804 176.227 271.869 176.111 271.92 175.982C271.972 175.853 271.998 175.711 271.998 175.556V172.604H273.067V175.595H273.042Z"
        fill="#006DA7"
      />
      <path
        d="M274.383 172.591H275.839L277.914 175.982H277.927V172.591H278.997V177.477H277.592L275.465 173.996H275.453V177.477H274.383V172.591Z"
        fill="#006DA7"
      />
      <path
        d="M280.337 172.591H281.948C282.335 172.591 282.708 172.642 283.056 172.72C283.404 172.81 283.714 172.952 283.971 173.145C284.229 173.339 284.435 173.597 284.59 173.906C284.745 174.215 284.822 174.602 284.822 175.041C284.822 175.44 284.745 175.788 284.603 176.085C284.461 176.382 284.255 176.639 283.997 176.846C283.74 177.052 283.456 177.207 283.121 177.31C282.786 177.413 282.438 177.465 282.077 177.465H280.324V172.578L280.337 172.591ZM281.407 176.485H281.961C282.206 176.485 282.438 176.459 282.657 176.407C282.876 176.356 283.056 176.278 283.211 176.162C283.366 176.046 283.495 175.892 283.585 175.698C283.675 175.505 283.714 175.273 283.714 175.002C283.714 174.757 283.675 174.551 283.585 174.383C283.495 174.203 283.379 174.061 283.224 173.945C283.069 173.829 282.889 173.738 282.696 173.687C282.489 173.622 282.283 173.597 282.051 173.597H281.42V176.498L281.407 176.485Z"
        fill="#006DA7"
      />
      <path
        d="M285.646 175.028C285.646 174.641 285.711 174.28 285.84 173.97C285.969 173.648 286.149 173.377 286.394 173.158C286.626 172.939 286.909 172.758 287.232 172.642C287.554 172.526 287.902 172.462 288.288 172.462C288.675 172.462 289.023 172.526 289.345 172.642C289.667 172.758 289.951 172.926 290.183 173.158C290.415 173.377 290.595 173.648 290.724 173.97C290.853 174.293 290.918 174.641 290.918 175.028C290.918 175.415 290.853 175.776 290.724 176.085C290.595 176.407 290.415 176.678 290.183 176.897C289.951 177.116 289.667 177.297 289.345 177.413C289.023 177.529 288.675 177.593 288.288 177.593C287.902 177.593 287.554 177.529 287.232 177.413C286.909 177.297 286.639 177.116 286.394 176.897C286.162 176.678 285.982 176.407 285.84 176.085C285.711 175.763 285.646 175.415 285.646 175.028ZM286.768 175.028C286.768 175.26 286.806 175.466 286.871 175.659C286.935 175.853 287.051 176.02 287.18 176.149C287.309 176.291 287.477 176.394 287.657 176.472C287.85 176.549 288.057 176.588 288.276 176.588C288.495 176.588 288.714 176.549 288.894 176.472C289.088 176.394 289.242 176.278 289.371 176.149C289.5 176.008 289.603 175.84 289.68 175.659C289.745 175.466 289.783 175.26 289.783 175.028C289.783 174.796 289.745 174.589 289.68 174.396C289.616 174.203 289.5 174.035 289.371 173.893C289.242 173.751 289.075 173.648 288.894 173.571C288.714 173.493 288.495 173.455 288.276 173.455C288.057 173.455 287.837 173.493 287.657 173.571C287.477 173.648 287.309 173.764 287.18 173.893C287.051 174.035 286.948 174.203 286.871 174.396C286.793 174.589 286.768 174.796 286.768 175.028Z"
        fill="#006DA7"
      />
      <path
        d="M296.898 175.84C296.898 176.072 296.872 176.291 296.834 176.498C296.795 176.704 296.718 176.897 296.615 177.052C296.499 177.22 296.344 177.349 296.138 177.452C295.932 177.555 295.661 177.606 295.339 177.606C294.952 177.606 294.63 177.516 294.359 177.336C294.089 177.155 293.921 176.859 293.844 176.472L294.823 176.227C294.823 176.343 294.888 176.446 294.978 176.523C295.068 176.601 295.184 176.652 295.3 176.652C295.416 176.652 295.519 176.627 295.584 176.562C295.648 176.498 295.7 176.42 295.738 176.33C295.777 176.24 295.79 176.137 295.803 176.033C295.803 175.93 295.803 175.827 295.803 175.737V172.604H296.872V175.853L296.898 175.84Z"
        fill="#006DA7"
      />
      <path
        d="M302.286 175.595C302.286 175.892 302.247 176.149 302.157 176.394C302.067 176.639 301.938 176.846 301.77 177.026C301.603 177.207 301.384 177.349 301.126 177.452C300.868 177.555 300.572 177.606 300.237 177.606C299.902 177.606 299.605 177.555 299.347 177.452C299.09 177.349 298.871 177.207 298.703 177.026C298.535 176.846 298.407 176.639 298.316 176.394C298.226 176.149 298.188 175.892 298.188 175.595V172.604H299.257V175.556C299.257 175.711 299.283 175.84 299.335 175.982C299.386 176.111 299.451 176.227 299.541 176.317C299.631 176.407 299.734 176.485 299.85 176.536C299.966 176.588 300.108 176.614 300.25 176.614C300.391 176.614 300.52 176.588 300.649 176.536C300.765 176.485 300.868 176.407 300.958 176.317C301.049 176.227 301.113 176.111 301.165 175.982C301.216 175.853 301.242 175.711 301.242 175.556V172.604H302.312V175.595H302.286Z"
        fill="#006DA7"
      />
      <path
        d="M306.023 173.777C305.933 173.661 305.817 173.584 305.662 173.532C305.508 173.48 305.379 173.455 305.237 173.455C305.16 173.455 305.082 173.455 304.992 173.481C304.915 173.493 304.838 173.532 304.76 173.571C304.683 173.609 304.631 173.661 304.58 173.725C304.528 173.79 304.515 173.867 304.515 173.958C304.515 174.099 304.567 174.215 304.683 174.293C304.799 174.37 304.928 174.435 305.095 174.499C305.263 174.551 305.443 174.615 305.637 174.667C305.83 174.718 306.01 174.796 306.178 174.899C306.345 175.002 306.487 175.131 306.59 175.311C306.706 175.479 306.758 175.711 306.758 175.995C306.758 176.278 306.706 176.498 306.603 176.704C306.5 176.91 306.371 177.078 306.191 177.207C306.023 177.336 305.817 177.439 305.585 177.503C305.353 177.568 305.121 177.593 304.863 177.593C304.541 177.593 304.258 177.542 303.987 177.452C303.716 177.361 303.459 177.194 303.227 176.988L303.987 176.149C304.103 176.291 304.232 176.407 304.399 176.485C304.567 176.562 304.734 176.601 304.915 176.601C305.005 176.601 305.095 176.601 305.186 176.575C305.276 176.549 305.353 176.523 305.43 176.485C305.495 176.446 305.559 176.394 305.598 176.33C305.637 176.265 305.662 176.188 305.662 176.098C305.662 175.956 305.611 175.84 305.495 175.75C305.379 175.659 305.237 175.595 305.07 175.531C304.902 175.466 304.722 175.415 304.515 175.35C304.322 175.286 304.142 175.208 303.961 175.105C303.794 175.002 303.652 174.873 303.536 174.705C303.42 174.538 303.368 174.319 303.368 174.048C303.368 173.777 303.42 173.558 303.523 173.364C303.626 173.171 303.768 173.003 303.935 172.862C304.103 172.733 304.309 172.63 304.528 172.565C304.76 172.501 304.992 172.462 305.224 172.462C305.495 172.462 305.765 172.501 306.023 172.578C306.281 172.655 306.513 172.784 306.719 172.978L305.985 173.79L306.023 173.777Z"
        fill="#006DA7"
      />
      <path
        d="M308.859 173.545H307.467V172.591H311.32V173.545H309.928V177.477H308.859V173.545Z"
        fill="#006DA7"
      />
      <path
        d="M311.951 175.028C311.951 174.641 312.016 174.28 312.144 173.97C312.273 173.648 312.454 173.377 312.699 173.158C312.931 172.939 313.214 172.758 313.536 172.642C313.859 172.526 314.207 172.462 314.593 172.462C314.98 172.462 315.328 172.526 315.65 172.642C315.972 172.758 316.256 172.926 316.488 173.158C316.72 173.377 316.9 173.648 317.029 173.97C317.158 174.293 317.222 174.641 317.222 175.028C317.222 175.415 317.158 175.776 317.029 176.085C316.9 176.407 316.72 176.678 316.488 176.897C316.256 177.116 315.972 177.297 315.65 177.413C315.328 177.529 314.98 177.593 314.593 177.593C314.207 177.593 313.859 177.529 313.536 177.413C313.214 177.297 312.944 177.116 312.699 176.897C312.467 176.678 312.286 176.407 312.144 176.085C312.016 175.763 311.951 175.415 311.951 175.028ZM313.072 175.028C313.072 175.26 313.111 175.466 313.176 175.659C313.24 175.853 313.356 176.02 313.485 176.149C313.614 176.291 313.781 176.394 313.962 176.472C314.155 176.549 314.361 176.588 314.58 176.588C314.799 176.588 315.018 176.549 315.199 176.472C315.392 176.394 315.547 176.278 315.676 176.149C315.805 176.008 315.908 175.84 315.985 175.659C316.049 175.466 316.088 175.26 316.088 175.028C316.088 174.796 316.049 174.589 315.985 174.396C315.921 174.203 315.805 174.035 315.676 173.893C315.547 173.751 315.379 173.648 315.199 173.571C315.018 173.493 314.799 173.455 314.58 173.455C314.361 173.455 314.142 173.493 313.962 173.571C313.781 173.648 313.614 173.764 313.485 173.893C313.356 174.035 313.253 174.203 313.176 174.396C313.098 174.589 313.072 174.796 313.072 175.028Z"
        fill="#006DA7"
      />
      <path
        d="M320.715 172.591H324.027V173.584H321.797V174.499H323.911V175.492H321.797V176.485H324.156V177.477H320.715V172.591Z"
        fill="#006DA7"
      />
      <path
        d="M331.862 175.595C331.862 175.892 331.823 176.149 331.733 176.394C331.643 176.639 331.514 176.846 331.346 177.026C331.179 177.207 330.96 177.349 330.702 177.452C330.444 177.555 330.148 177.606 329.813 177.606C329.478 177.606 329.181 177.555 328.924 177.452C328.666 177.349 328.447 177.207 328.279 177.026C328.112 176.846 327.983 176.639 327.893 176.394C327.802 176.149 327.764 175.892 327.764 175.595V172.604H328.833V175.556C328.833 175.711 328.859 175.84 328.911 175.982C328.962 176.111 329.027 176.227 329.117 176.317C329.207 176.407 329.31 176.485 329.426 176.536C329.542 176.588 329.684 176.614 329.826 176.614C329.967 176.614 330.096 176.588 330.225 176.536C330.341 176.485 330.444 176.407 330.535 176.317C330.625 176.227 330.689 176.111 330.741 175.982C330.792 175.853 330.818 175.711 330.818 175.556V172.604H331.888V175.595H331.862Z"
        fill="#006DA7"
      />
      <path
        d="M333.189 172.591H334.826L335.947 175.776H335.96L337.094 172.591H338.705V177.477H337.636V173.726H337.623L336.334 177.477H335.522L334.285 173.726H334.272V177.477H333.189V172.591Z"
        fill="#006DA7"
      />
      <path
        d="M262.074 180.868H263.891C264.149 180.868 264.381 180.894 264.613 180.933C264.832 180.972 265.038 181.062 265.206 181.165C265.373 181.281 265.502 181.436 265.605 181.616C265.709 181.81 265.747 182.042 265.747 182.338C265.747 182.635 265.696 182.867 265.605 183.06C265.515 183.254 265.386 183.408 265.232 183.524C265.077 183.64 264.884 183.718 264.665 183.769C264.446 183.821 264.201 183.847 263.943 183.847H263.157V185.755H262.087V180.868H262.074ZM263.144 182.931H263.866C263.956 182.931 264.059 182.931 264.149 182.906C264.239 182.893 264.317 182.854 264.394 182.815C264.471 182.777 264.523 182.712 264.562 182.635C264.6 182.557 264.626 182.467 264.626 182.351C264.626 182.222 264.6 182.132 264.536 182.055C264.484 181.977 264.407 181.926 264.317 181.874C264.226 181.835 264.123 181.81 264.02 181.797C263.904 181.797 263.801 181.784 263.698 181.784H263.144V182.944V182.931Z"
        fill="#006DA7"
      />
      <path
        d="M266.805 180.868H267.887V184.749H269.872V185.742H266.805V180.868Z"
        fill="#006DA7"
      />
      <path
        d="M272.41 180.868H273.3L275.426 185.755H274.215L273.789 184.724H271.895L271.483 185.755H270.297L272.41 180.868ZM272.823 182.287L272.23 183.808H273.429L272.823 182.287Z"
        fill="#006DA7"
      />
      <path
        d="M276.264 180.868H277.72L279.795 184.259H279.808V180.868H280.878V185.742H279.473L277.346 182.274H277.333V185.742H276.264V180.868Z"
        fill="#006DA7"
      />
      <path
        d="M282.258 180.868H285.583V181.861H283.34V182.764H285.454V183.757H283.34V184.749H285.699V185.742H282.258V180.868Z"
        fill="#006DA7"
      />
      <path
        d="M287.851 181.81H286.459V180.868H290.312V181.81H288.921V185.742H287.851V181.81Z"
        fill="#006DA7"
      />
      <path
        d="M292.18 180.868H293.069L295.196 185.755H293.984L293.559 184.724H291.664L291.252 185.755H290.066L292.18 180.868ZM292.592 182.287L292 183.808H293.198L292.592 182.287Z"
        fill="#006DA7"
      />
      <path
        d="M300.816 182.055C300.726 181.939 300.61 181.861 300.455 181.81C300.301 181.758 300.172 181.732 300.03 181.732C299.953 181.732 299.875 181.732 299.785 181.758C299.708 181.771 299.63 181.81 299.553 181.848C299.476 181.887 299.424 181.939 299.373 182.003C299.321 182.068 299.308 182.145 299.308 182.235C299.308 182.377 299.36 182.493 299.476 182.57C299.592 182.648 299.721 182.712 299.888 182.777C300.056 182.828 300.236 182.893 300.43 182.944C300.623 182.996 300.803 183.073 300.971 183.176C301.138 183.28 301.28 183.408 301.383 183.589C301.499 183.757 301.551 183.989 301.551 184.272C301.551 184.556 301.499 184.775 301.396 184.981C301.293 185.188 301.164 185.355 300.984 185.484C300.816 185.613 300.61 185.716 300.378 185.781C300.146 185.845 299.914 185.871 299.656 185.871C299.334 185.871 299.051 185.82 298.78 185.729C298.509 185.639 298.252 185.471 298.02 185.265L298.78 184.427C298.896 184.569 299.025 184.685 299.192 184.762C299.36 184.84 299.527 184.878 299.708 184.878C299.798 184.878 299.888 184.878 299.978 184.852C300.069 184.827 300.146 184.801 300.223 184.762C300.288 184.724 300.352 184.672 300.391 184.608C300.43 184.543 300.455 184.466 300.455 184.375C300.455 184.234 300.404 184.118 300.288 184.027C300.172 183.937 300.03 183.873 299.862 183.808C299.695 183.744 299.515 183.692 299.308 183.628C299.115 183.563 298.935 183.486 298.754 183.383C298.587 183.28 298.445 183.151 298.329 182.983C298.213 182.815 298.161 182.596 298.161 182.325C298.161 182.055 298.213 181.835 298.316 181.642C298.419 181.449 298.561 181.281 298.728 181.139C298.896 181.01 299.102 180.907 299.321 180.843C299.553 180.778 299.785 180.74 300.017 180.74C300.288 180.74 300.558 180.778 300.816 180.856C301.074 180.933 301.306 181.062 301.512 181.255L300.778 182.068L300.816 182.055Z"
        fill="#006DA7"
      />
      <path
        d="M306.821 183.86C306.821 184.156 306.782 184.414 306.692 184.659C306.602 184.904 306.473 185.11 306.305 185.291C306.138 185.471 305.919 185.613 305.661 185.716C305.403 185.819 305.107 185.871 304.772 185.871C304.437 185.871 304.14 185.819 303.883 185.716C303.625 185.613 303.406 185.471 303.238 185.291C303.071 185.11 302.942 184.904 302.852 184.659C302.761 184.414 302.723 184.156 302.723 183.86V180.868H303.792V183.821C303.792 183.976 303.818 184.105 303.87 184.246C303.921 184.375 303.986 184.491 304.076 184.582C304.166 184.672 304.269 184.749 304.385 184.801C304.501 184.852 304.643 184.878 304.785 184.878C304.926 184.878 305.055 184.852 305.184 184.801C305.3 184.749 305.403 184.672 305.494 184.582C305.584 184.491 305.648 184.375 305.7 184.246C305.751 184.118 305.777 183.976 305.777 183.821V180.868H306.847V183.86H306.821Z"
        fill="#006DA7"
      />
      <path
        d="M310.572 182.055C310.482 181.939 310.366 181.861 310.211 181.81C310.057 181.758 309.928 181.732 309.786 181.732C309.709 181.732 309.631 181.732 309.541 181.758C309.464 181.771 309.386 181.81 309.309 181.848C309.232 181.887 309.18 181.939 309.129 182.003C309.077 182.068 309.064 182.145 309.064 182.235C309.064 182.377 309.116 182.493 309.232 182.57C309.348 182.648 309.477 182.712 309.644 182.777C309.812 182.828 309.992 182.893 310.185 182.944C310.379 182.996 310.559 183.073 310.727 183.176C310.894 183.28 311.036 183.408 311.139 183.589C311.255 183.757 311.307 183.989 311.307 184.272C311.307 184.556 311.255 184.775 311.152 184.981C311.049 185.188 310.92 185.355 310.74 185.484C310.572 185.613 310.366 185.716 310.134 185.781C309.902 185.845 309.67 185.871 309.412 185.871C309.09 185.871 308.806 185.82 308.536 185.729C308.265 185.639 308.007 185.471 307.775 185.265L308.536 184.427C308.652 184.569 308.781 184.685 308.948 184.762C309.116 184.84 309.283 184.878 309.464 184.878C309.554 184.878 309.644 184.878 309.734 184.852C309.825 184.827 309.902 184.801 309.979 184.762C310.044 184.724 310.108 184.672 310.147 184.608C310.185 184.543 310.211 184.466 310.211 184.375C310.211 184.234 310.16 184.118 310.044 184.027C309.928 183.937 309.786 183.873 309.618 183.808C309.451 183.744 309.27 183.692 309.064 183.628C308.871 183.563 308.69 183.486 308.51 183.383C308.342 183.28 308.201 183.151 308.085 182.983C307.969 182.815 307.917 182.596 307.917 182.325C307.917 182.055 307.969 181.835 308.072 181.642C308.175 181.449 308.317 181.281 308.484 181.139C308.652 181.01 308.858 180.907 309.077 180.843C309.309 180.778 309.541 180.74 309.773 180.74C310.044 180.74 310.314 180.778 310.572 180.856C310.83 180.933 311.062 181.062 311.268 181.255L310.533 182.068L310.572 182.055Z"
        fill="#006DA7"
      />
      <path
        d="M313.394 181.81H312.002V180.868H315.868V181.81H314.476V185.742H313.394V181.81Z"
        fill="#006DA7"
      />
      <path
        d="M316.797 180.868H320.109V181.861H317.867V182.764H319.993V183.757H317.867V184.749H320.238V185.742H316.797V180.868Z"
        fill="#006DA7"
      />
      <path
        d="M321.436 180.868H322.905L324.98 184.259H324.993V180.868H326.062V185.742H324.658L322.531 182.274H322.518V185.742H321.436V180.868Z"
        fill="#006DA7"
      />
      <path
        d="M328.345 181.81H326.953V180.868H330.819V181.81H329.428V185.742H328.345V181.81Z"
        fill="#006DA7"
      />
      <path
        d="M332.674 180.868H333.563L335.69 185.755H334.478L334.053 184.724H332.159L331.746 185.755H330.561L332.674 180.868ZM333.138 179.515H334.543L333.344 180.507H332.442L333.138 179.515ZM333.087 182.287L332.494 183.808H333.692L333.087 182.287Z"
        fill="#006DA7"
      />
      <path
        d="M335.613 180.868H336.838L338.036 184.066H338.049L339.286 180.868H340.433L338.423 185.742H337.559L335.613 180.868Z"
        fill="#006DA7"
      />
      <path
        d="M341.322 180.868H344.634V181.861H342.405V182.764H344.518V183.757H342.405V184.749H344.763V185.742H341.322V180.868Z"
        fill="#006DA7"
      />
      <path
        d="M346.014 180.868H347.083V184.749H349.081V185.742H346.014V180.868Z"
        fill="#006DA7"
      />
      <path
        d="M124.637 0H138.286C138.286 0 117.575 23.3886 112.806 41.6199C112.806 41.7101 108.411 21.8543 124.637 0Z"
        fill="url(#paint0_linear_192_3408)"
      />
      <path
        d="M108.024 0H123.103C123.103 0 110.705 11.1657 110.228 28.8296C109.842 46.9706 117.007 60.5216 140.386 72.6413C140.386 72.6413 123.683 65.6789 111.569 50.2068C99.3509 34.8379 97.8301 14.1183 108.037 0H108.024Z"
        fill="url(#paint1_linear_192_3408)"
      />
      <path
        d="M88.2812 0H102.6C102.6 0 93.823 11.9264 98.5013 29.9771C103.27 48.015 115.578 61.2823 131.043 69.5856C154.319 82.0922 181.422 81.8085 195.444 103.186C195.444 103.186 190.765 108.15 190.765 108.433C190.765 108.433 184.283 95.2563 163.765 87.1464C143.158 79.0365 130.94 76.6512 112.433 60.8955C94.6865 45.6168 76.5533 26.0575 88.2941 0L88.2812 0Z"
        fill="url(#paint2_linear_192_3408)"
      />
      <path
        d="M114.611 67.5871C121.004 72.7445 137.796 80.9447 156.974 90.8726C178.161 101.664 183.883 115.692 183.883 115.692L187.608 111.773C187.608 111.773 182.736 101.935 171.292 94.5859C158.417 86.1794 155.839 85.8055 137.229 77.7858C116.428 68.9022 101.453 54.1135 88.0881 39.0283C73.963 23.1823 78.6413 5.4281 78.6413 5.4281C78.6413 5.4281 70.3415 23.3757 81.4122 42.084C92.4828 60.702 110.229 71.4809 119.573 77.399C128.93 83.3171 141.418 89.5188 151.535 97.9253C161.652 106.332 175.867 123.506 176.628 140.589L182.543 129.798C182.543 129.798 178.728 112.714 161.832 97.9124C149.911 87.2238 132.448 82.3501 114.598 67.5613L114.611 67.5871Z"
        fill="url(#paint3_linear_192_3408)"
      />
      <path
        d="M76.0636 23.3886C76.0636 33.3165 77.7776 47.1511 98.6817 64.7248C118.336 81.1381 150.684 92.6906 156.497 124.576C160.028 144.238 146.856 165.809 133.311 179.553H125.682C125.682 179.553 170.145 142.137 144.949 106.345C125.295 78.5723 70.5218 65.8723 76.0636 23.3886Z"
        fill="#E42320"
      />
      <path
        d="M148.002 150.156C151.533 138.888 150.953 125.814 144.857 114.455C136.171 98.1316 122.149 90.9757 98.7706 74.4593C75.392 58.046 72.1443 39.428 72.1443 39.428C69.3734 56.6149 85.5992 70.7331 90.2775 74.6527C95.046 78.4692 112.793 90.4084 122.626 98.0413C132.55 105.674 146.958 119.328 142.757 142.523C140.759 153.883 134.173 163.901 117.948 179.656H125.68C125.68 179.656 140.179 167.343 148.002 150.156Z"
        fill="#FFCF00"
      />
      <path
        d="M132.641 108.537C127.292 101.574 109.932 89.5446 102.973 85.625C98.4878 83.0463 90.5746 77.6053 85.3164 74.0725H85.4066C98.8615 85.0448 116.518 95.7463 123.954 103.379C131.39 111.012 141.701 125.814 135.025 146.043C128.349 166.286 106.968 179.644 106.968 179.644H117.845C134.252 163.888 140.644 153.87 142.654 142.511C142.938 141.363 143.041 140.125 143.131 138.978C143.608 121.984 137.409 114.738 132.641 108.524V108.537Z"
        fill="url(#paint4_linear_192_3408)"
      />
      <path
        d="M75.5859 108.91L81.8881 105.094C81.8881 105.094 91.902 109.671 110.899 110.922C110.989 110.922 95.5363 116.453 75.5988 108.923L75.5859 108.91Z"
        fill="#E42320"
      />
      <path
        d="M156.496 124.563C160.028 144.225 146.856 165.796 133.311 179.54C133.311 179.54 133.698 179.45 134.355 179.154C157.63 156.435 164.693 146.031 163.262 127.412C161.832 108.034 136.636 90.3698 133.595 87.9845C131.017 85.986 115.758 74.9106 112.51 72.6156C112.51 72.6156 112.42 72.6156 112.42 72.5253C115.951 75.3877 123.387 81.4991 126.545 84.0778C140.374 94.0057 152.978 105.648 156.509 124.55L156.496 124.563Z"
        fill="url(#paint5_linear_192_3408)"
      />
      <path
        d="M169.474 147.384C169.474 147.384 176.626 129.63 161.741 110.058C149.343 93.6446 118.799 77.2185 112.406 72.7316C113.36 73.6857 147.809 97.4482 156.96 109.581C164.692 119.896 168.881 134.491 161.535 149.77L169.448 147.384H169.474Z"
        fill="url(#paint6_linear_192_3408)"
      />
      <path
        d="M89.9041 86.1021C102.972 95.6432 117.484 100.891 125.204 113.397C130.733 122.178 136.365 140.886 118.335 160.652C116.904 162.173 115.087 163.991 113.089 165.899H123.013C127.781 160.548 132.46 153.87 135.037 146.147C141.713 125.904 131.506 111.115 123.967 103.482C116.53 95.8495 98.874 85.2511 85.4191 74.1757C76.8358 67.1101 72.5442 61.1018 70.2501 57.1822C69.8635 69.0183 83.705 81.5249 89.9041 86.1021Z"
        fill="#089D39"
      />
      <path
        d="M88.6545 98.6086C100.679 105.868 112.033 107.969 120.526 119.225C128.826 130.107 123.774 143.284 115.474 153.302H124.057C129.973 144.045 131.21 135.458 130.36 128.096C127.022 108.91 106.697 99.9366 95.4335 94.212C81.6951 87.2495 75.6893 79.2299 75.6893 79.2299L69.6836 82.5692C73.885 88.1005 79.517 93.0645 88.6674 98.6086H88.6545Z"
        fill="#4CAC33"
      />
      <path
        d="M198.381 95.8366C197.994 93.2579 197.04 90.7823 195.803 88.4873C192.465 85.9086 187.22 82.2856 180.531 79.1267C170.414 74.4464 156.006 72.5382 156.006 72.5382C156.006 72.5382 167.076 71.3004 181.008 77.7858C187.117 80.6481 191.499 83.7039 194.553 86.3728C193.406 84.5548 192.079 82.9303 190.738 81.4991C184.629 75.2974 174.809 69.5727 159.253 69.2762C129.289 68.7991 117.754 49.4204 112.883 41.6843C112.883 41.6843 114.404 57.9171 138.169 71.3649C151.521 78.9978 166.793 78.9075 179.681 85.019C185.119 87.5977 192.852 91.2207 198.471 96.1847C198.381 95.9913 198.381 95.901 198.381 95.7979V95.8366Z"
        fill="url(#paint7_linear_192_3408)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_192_3408"
        x1="125.269"
        y1="266.79"
        x2="125.269"
        y2="4.57714"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32529F" />
        <stop offset="0.24" stopColor="#285BA6" />
        <stop offset="0.51" stopColor="#0B7881" />
        <stop offset="0.73" stopColor="#018C54" />
        <stop offset="0.9" stopColor="#019839" />
        <stop offset="1" stopColor="#089D39" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_192_3408"
        x1="120.822"
        y1="196.547"
        x2="120.822"
        y2="4.03563"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E42320" />
        <stop offset="0.08" stopColor="#D01F17" />
        <stop offset="0.17" stopColor="#A03C16" />
        <stop offset="0.26" stopColor="#76561A" />
        <stop offset="0.36" stopColor="#526D28" />
        <stop offset="0.46" stopColor="#337F33" />
        <stop offset="0.57" stopColor="#1E8C37" />
        <stop offset="0.69" stopColor="#0B9539" />
        <stop offset="0.82" stopColor="#0F9B39" />
        <stop offset="1" stopColor="#089D39" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_192_3408"
        x1="139.987"
        y1="301.486"
        x2="139.987"
        y2="0.644675"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32529F" />
        <stop offset="0.09" stopColor="#3253A0" />
        <stop offset="0.26" stopColor="#2D5BA6" />
        <stop offset="0.5" stopColor="#127C92" />
        <stop offset="0.78" stopColor="#18A644" />
        <stop offset="1" stopColor="#4CAC33" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_192_3408"
        x1="131.636"
        y1="256.617"
        x2="131.636"
        y2="-32.1819"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32529F" />
        <stop offset="0.22" stopColor="#2C5CA6" />
        <stop offset="0.71" stopColor="#0EA34C" />
        <stop offset="1" stopColor="#4CAC33" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_192_3408"
        x1="114.25"
        y1="215.951"
        x2="114.25"
        y2="-46.1454"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E42320" />
        <stop offset="0.14" stopColor="#E63C1E" />
        <stop offset="0.46" stopColor="#F8B114" />
        <stop offset="0.53" stopColor="#FFCF00" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_192_3408"
        x1="137.938"
        y1="-2.82362"
        x2="137.938"
        y2="219.974"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.24" stopColor="#E42320" />
        <stop offset="0.25" stopColor="#E4221C" />
        <stop offset="0.41" stopColor="#AE4217" />
        <stop offset="0.57" stopColor="#707523" />
        <stop offset="0.71" stopColor="#419B35" />
        <stop offset="0.83" stopColor="#3DA935" />
        <stop offset="0.93" stopColor="#48AB33" />
        <stop offset="1" stopColor="#4CAC33" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_192_3408"
        x1="141.803"
        y1="-3.14603"
        x2="141.803"
        y2="219.794"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.24" stopColor="#E42320" />
        <stop offset="0.25" stopColor="#E4221C" />
        <stop offset="0.41" stopColor="#AE4217" />
        <stop offset="0.57" stopColor="#707523" />
        <stop offset="0.71" stopColor="#419B35" />
        <stop offset="0.83" stopColor="#3DA935" />
        <stop offset="0.93" stopColor="#48AB33" />
        <stop offset="1" stopColor="#4CAC33" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_192_3408"
        x1="-177.493"
        y1="68.9667"
        x2="241.542"
        y2="68.9667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32529F" />
        <stop offset="0.5" stopColor="#0F728F" />
        <stop offset="0.84" stopColor="#029148" />
        <stop offset="1" stopColor="#089D39" />
      </linearGradient>
      <clipPath id="clip0_192_3408">
        <rect width="373" height="186" fill="#006DA7" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoFull;
