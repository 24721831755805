/**
 * Properties reducer.
 * @module reducers/toolbar/toolbar
 */

import { GET_G20_FOOTER_LINKS } from '@package/constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: { top: [], bottom: [] },
};

export default function g20FooterLinks(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_G20_FOOTER_LINKS}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        data: {},
      };
    case `${GET_G20_FOOTER_LINKS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        data: action.result.footer_links,
      };
    case `${GET_G20_FOOTER_LINKS}_FAIL`:
      return {
        ...state,
        error: action.result,
        loaded: false,
        loading: false,
        data: {},
      };
    default:
      return state;
  }
}
