import { memo } from 'react';
const SvgSlideshare = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.641 11.562c0 1.734-1.516 3.156-3.375 3.156s-3.375-1.422-3.375-3.156c0-1.75 1.516-3.156 3.375-3.156s3.375 1.406 3.375 3.156zm7.843 0c0 1.734-1.5 3.156-3.375 3.156-1.859 0-3.375-1.422-3.375-3.156 0-1.75 1.516-3.156 3.375-3.156 1.875 0 3.375 1.406 3.375 3.156zM25 14.375V3.953c0-1.797-.578-2.5-2.234-2.5H5.391c-1.734 0-2.219.594-2.219 2.5v10.516c3.703 1.937 6.875 1.594 8.609 1.531.734-.016 1.203.125 1.484.422.047.047.094.094.156.141.328.313.641.562.953.797.063-.859.547-1.406 1.844-1.359 1.766.078 5.016.422 8.781-1.625zm2.547-.078c-1 1.234-2.906 2.75-5.812 3.938 3.078 10.484-7.516 12.156-7.344 6.781 0 .094-.016-2.891-.016-5.109-.234-.047-.469-.109-.75-.172 0 2.234-.016 5.375-.016 5.281.172 5.375-10.422 3.703-7.344-6.781-2.906-1.188-4.813-2.703-5.812-3.938-.5-.75.047-1.547.875-.969.109.078.234.156.344.234V2.718c0-1.5 1.125-2.719 2.516-2.719h19.641c1.391 0 2.516 1.219 2.516 2.719v10.844l.328-.234c.828-.578 1.375.219.875.969z"
    />
  </svg>
);
const Memo = memo(SvgSlideshare);
export default Memo;
