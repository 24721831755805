import { injectIntl } from 'react-intl';
import { Portal } from 'react-portal';
import ManageCookiesButton from '../ManageCookiesButton';
import withLgpdCookie from '../withLgpdCookie';
import { Banner, buttons } from '../../messages';
import '../../theme/lgpd.less';

const CookieBanner = (props) => {
  const { intl, lgpdCookieReady, hasLgpdCookie, acceptCookies, rejectCookies } =
    props;

  if (!lgpdCookieReady || hasLgpdCookie) {
    return null;
  }

  return (
    <Portal>
      <div className="lgpd-cookiebanner">
        <div className="ui container content">
          <p>
            <Banner />
          </p>
          <div className="buttons">
            <ManageCookiesButton
              className="manage"
              label={intl.formatMessage(buttons.manageCookies)}
            />
            <button data-lgpd-action="denied" onClick={() => rejectCookies()}>
              {intl.formatMessage(buttons.rejectCookies)}
            </button>
            <button data-lgpd-action="granted" onClick={() => acceptCookies()}>
              {intl.formatMessage(buttons.acceptCookies)}
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default withLgpdCookie(injectIntl(CookieBanner));
