import { memo } from 'react';
const SvgNavigation2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="navigation-2_svg__feather navigation-2_svg__feather-navigation-2"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="m12 2 7 19-7-4-7 4 7-19z" />
  </svg>
);
const Memo = memo(SvgNavigation2);
export default Memo;
