import { memo } from 'react';
const SvgFolha = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <path d="M23.458.047c-.574.024-14.099.646-18.57 5.117-3.727 3.727-3.842 9.719-.345 13.585L.005 23.288l.707.707 4.539-4.539a9.834 9.834 0 0 0 6.611 2.539 9.832 9.832 0 0 0 6.974-2.884C23.397 14.55 23.958 1.134 23.979.565L24 .023l-.541.023Zm-5.33 18.357c-3.336 3.336-8.694 3.451-12.169.343L16.353 8.353l-.707-.707L5.252 18.04c-3.108-3.476-2.994-8.833.343-12.169C9.308 2.16 20.354 1.243 22.951 1.077c-.156 2.584-1.034 13.54-4.822 17.327Z" />
  </svg>
);
const Memo = memo(SvgFolha);
export default Memo;
