import { memo } from 'react';
const SvgWhatsapp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="M23.331 19.176c-.401-.2-2.356-1.156-2.72-1.288-.364-.135-.631-.2-.897.2-.263.393-1.028 1.285-1.259 1.549-.233.26-.465.28-.861.1-.4-.2-1.684-.62-3.204-1.98-1.184-1.06-1.979-2.36-2.213-2.76-.232-.4-.025-.62.173-.82.181-.18.401-.46.601-.697.195-.241.259-.401.396-.661.133-.28.065-.5-.033-.699-.1-.2-.896-2.16-1.229-2.941-.32-.779-.649-.68-.896-.68-.229-.02-.495-.02-.761-.02s-.697.099-1.063.479c-.364.4-1.393 1.36-1.393 3.3s1.427 3.82 1.625 4.1c.199.26 2.807 4.26 6.8 5.98.952.4 1.693.64 2.272.839.952.303 1.82.26 2.507.161.765-.121 2.356-.961 2.688-1.901.34-.94.34-1.72.24-1.9-.099-.18-.36-.28-.76-.46zM16.081 29h-.021c-2.36 0-4.699-.64-6.74-1.84l-.48-.285-5 1.3 1.34-4.86-.319-.5a13.156 13.156 0 0 1-2.021-7.013c0-7.26 5.94-13.18 13.256-13.18 3.539 0 6.86 1.38 9.361 3.88 2.5 2.479 3.879 5.8 3.879 9.32-.005 7.259-5.947 13.18-13.247 13.18zM27.36 4.599C24.32 1.66 20.32 0 16.06 0 7.284 0 .139 7.112.135 15.857c0 2.795.732 5.52 2.127 7.927L.001 32l8.447-2.203a16.087 16.087 0 0 0 7.613 1.929h.008c8.78 0 15.928-7.115 15.932-15.861 0-4.235-1.653-8.22-4.66-11.215z"
    />
  </svg>
);
const Memo = memo(SvgWhatsapp);
export default Memo;
