import { defineMessages } from 'react-intl';
import NumbersView from './NumbersView';
import NumbersEdit from './NumbersEdit';
import iconSVG from './icons/numbers.svg';

defineMessages({
  numbers: {
    id: 'Numbers',
    defaultMessage: 'Numbers',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.numbersBlock = {
    id: 'numbersBlock',
    title: 'Numbers',
    group: 'layout',
    icon: iconSVG,
    view: NumbersView,
    edit: NumbersEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
