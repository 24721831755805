// import eventsMock from './events-mock';

// const MOCK = true;

const eventsMiddleware = (config) => {
  if (__SERVER__) {
    let eventsApi = process.env.EVENTS_API;
    let eventsKey = process.env.EVENTS_KEY;

    if (__DEVELOPMENT__) {
      eventsApi =
        eventsApi || 'https://hom-g20.serpro.gov.br/G20BrasilAPI/rest/Events';
      eventsKey = eventsKey || 'cG9ydGFsU2VycHJvOmcyMEJyYXNpbCRMb3djb2Rl';
    }

    const express = require('express');
    const middleware = express.Router();
    const superagent = require('superagent');

    const apiRequest = async (res, path) => {
      try {
        const { body } = await superagent
          .get(`${eventsApi}${path}`)
          .set('api-key', eventsKey)
          .set('accept', 'json');
        return body;
      } catch (error) {
        res.status(500).json(error);
      }
    };

    middleware.all('/g20-api/events', async function (req, res, next) {
      const result = await apiRequest(res, '/ListAll');
      res.json(result);
    });
    middleware.all('/g20-api/cities', async function (req, res, next) {
      const result = await apiRequest(res, '/ListCity');
      res.json(result);
    });
    middleware.all('/g20-api/workgroups', async function (req, res, next) {
      const all = await apiRequest(res, '/ListWorkgroup');
      const both = await apiRequest(res, '/ListWorkgroup?Track=BOTH');
      const result = [
        ...all,
        ...both.reduce((prev, group) => {
          const newGroupFromBoth = { ...group, TrackId: 'BOTH' };
          const newGroupFromOtherTrack = {
            ...group,
            TrackId: group.TrackId === 'FINANCE' ? 'SHERPA' : 'FINANCE',
          };
          return [...prev, newGroupFromBoth, newGroupFromOtherTrack];
        }, []),
      ];
      res.json(result);
    });
    middleware.all('/g20-api/tracks', async function (req, res, next) {
      const result = await apiRequest(res, '/ListTrack');
      res.json(result);
    });

    middleware.id = 'events-middleware';
    config.settings.expressMiddleware.push(middleware);
  }

  return config;
};

export default eventsMiddleware;
