import { Checkbox } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import styles from './Group.module.less';

const Group = ({
  intl,
  title,
  children,
  readonly = false,
  checked,
  handleChange,
}) => {
  return (
    <div className={`${styles.root} mb-2x`}>
      <div>
        <Checkbox
          toggle
          readOnly={readonly}
          checked={checked}
          label={title}
          onChange={(e, data) => handleChange(data.checked)}
        />
      </div>
      <div className="mt-base">{children}</div>
    </div>
  );
};

export default injectIntl(Group);
