import { memo } from 'react';
const SvgShieldOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="shield-off_svg__feather shield-off_svg__feather-shield-off"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M19.69 14a6.9 6.9 0 0 0 .31-2V5l-8-3-3.16 1.18M4.73 4.73 4 5v7c0 6 8 10 8 10a20.29 20.29 0 0 0 5.62-4.38M1 1l22 22" />
  </svg>
);
const Memo = memo(SvgShieldOff);
export default Memo;
