import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './Dates.module.less';

const messages = defineMessages({
  not_saved_yet: {
    id: 'Not saved yet',
    defaultMessage: 'Not saved yet',
  },
  published: {
    id: 'Published',
    defaultMessage: 'Published',
  },
  created: {
    id: 'Created',
    defaultMessage: 'Created',
  },
  modified: {
    id: 'Modified',
    defaultMessage: 'Modified',
  },
  not_published_yet: {
    id: 'Not published yet',
    defaultMessage: 'Not published yet',
  },
});

const Dates = ({ properties, intl }) => {
  moment.locale(properties.language.token);

  const { created, modified, effective, review_state } = properties;

  if (!created) {
    return (
      <span className={styles.root}>
        <span className={styles.new}>
          {intl.formatMessage(messages.not_saved_yet)}
        </span>
      </span>
    );
  }

  const showModified =
    modified &&
    ((effective && !moment(effective).isSame(modified)) ||
      (!effective && !moment(created).isSame(modified)));

  return (
    <div className={styles.root}>
      {`${moment(effective || created).format('L')} ${moment(
        effective || created,
      ).format('LT')}`}

      {showModified &&
        ` - ${intl.formatMessage(messages.modified)} ${moment(
          modified,
        ).fromNow()}`}
      {!effective && review_state && (
        <span className={styles.private}>
          {intl.formatMessage(messages.not_published_yet)}
        </span>
      )}
    </div>
  );
};

Dates.propTypes = {
  properties: PropTypes.shape({
    created: PropTypes.string,
    modified: PropTypes.string,
    effective: PropTypes.string,
    language: PropTypes.object,
  }),
  className: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(Dates);
