import PropTypes from 'prop-types';
import { BlockDataForm } from '@plone/volto/components';
import { eventsSchema } from './schema';

const EventsData = (props) => {
  const { data, block, onChangeBlock } = props;
  const schema = eventsSchema({ ...props });
  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeBlock={onChangeBlock}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      block={block}
    />
  );
};

EventsData.propTypes = {
  data: PropTypes.shape({
    size: PropTypes.string,
    spacing: PropTypes.string,
  }),
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
};

export default EventsData;
