import { memo } from 'react';
const SvgIgualdade = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="m23.964 12.314-.003-.006-.002-.005-3.493-8.732a2.488 2.488 0 0 0-2.321-1.572h-5.646V.5a.5.5 0 0 0-1 0V2H5.854a2.488 2.488 0 0 0-2.321 1.572L.041 12.303l-.002.005-.003.006C.012 12.373 0 13.415 0 13.415c0 2.197 1.457 4.041 3.543 4.484.314.067.634.101.95.101h.008A4.51 4.51 0 0 0 9 13.5s-.012-1.126-.036-1.186l-.003-.006-.002-.005-3.676-9.191c.177-.073.37-.112.571-.112H11.5v20h-7a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-7V3h5.646c.201 0 .395.039.571.112l-3.676 9.191-.002.005-.003.006C15.012 12.373 15 13.5 15 13.5a4.51 4.51 0 0 0 4.499 4.5h.008c.316 0 .636-.034.949-.101C22.543 17.455 24 15.611 24 13.415c0 0-.012-1.042-.036-1.101ZM4.499 17a2.62 2.62 0 0 1-.748-.079C2.132 16.577 1 15.135 1 13.415V13h7v.5A3.509 3.509 0 0 1 4.499 17Zm3.263-5H1.238s3.249-8.119 3.264-8.149L7.762 12Zm11.736-8.149c.015.03 3.263 8.149 3.263 8.149h-6.523l3.26-8.149Zm.75 13.07c-.247.053-.466.097-.747.079A3.509 3.509 0 0 1 16 13.5V13h7v.415c0 1.72-1.132 3.162-2.752 3.506Z" />
  </svg>
);
const Memo = memo(SvgIgualdade);
export default Memo;
