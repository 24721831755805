import { MaybeWrap, UniversalLink } from '@plone/volto/components';
import PropTypes from 'prop-types';
import styles from './Organizations.module.less';

const Organizations = (props) => {
  const { data, isEditMode, addLinks } = props;

  return (
    <div className={styles.root}>
      {data.map((organization) => (
        <div key={organization.url} className={styles.card}>
          <MaybeWrap
            condition={isEditMode || !addLinks}
            as="div"
            className={styles.inner}
          >
            <MaybeWrap
              condition={!isEditMode && addLinks}
              as={UniversalLink}
              href={organization.url}
              target="_blank"
              className={styles.inner}
            >
              <span className={styles.title}>{organization.name}</span>
            </MaybeWrap>
          </MaybeWrap>
        </div>
      ))}
    </div>
  );
};

Organizations.propTypes = {
  data: PropTypes.any,
  addLinks: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default Organizations;
