import { memo } from 'react';
const SvgRefreshCw = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="refresh-cw_svg__feather refresh-cw_svg__feather-refresh-cw"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M23 4v6h-6M1 20v-6h6" />
    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
  </svg>
);
const Memo = memo(SvgRefreshCw);
export default Memo;
