import iconSVG from '@plone/volto/icons/slider.svg';
import { defineMessages } from 'react-intl';
import HeroCarouselEdit from './HeroCarouselEdit';
import HeroCarouselView from './HeroCarouselView';
import './theme/main.less';

defineMessages({
  heroCarousel: {
    id: 'Hero carousel',
    defaultMessage: 'Hero carousel',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.heroCarouselBlock = {
    id: 'heroCarouselBlock',
    title: 'Hero carousel',
    group: 'portalg20',
    icon: iconSVG,
    view: HeroCarouselView,
    edit: HeroCarouselEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
