import { defineMessages } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  portuguese: { id: 'Portuguese', defaultMessage: 'Portuguese' },
  english: { id: 'English', defaultMessage: 'English' },
  spanish: { id: 'Spanish', defaultMessage: 'Spanish' },
  label: { id: 'Label', defaultMessage: 'Label' },
  URL: { id: 'URL', defaultMessage: 'URL' },
  URL_Description: {
    id: 'URL_Description',
    defaultMessage:
      'For external links, insert full URL. Eg.: https://www.site.com/path',
  },
  topRow: { id: 'Top row', defaultMessage: 'Top row' },
  bottomRow: { id: 'Bottom row', defaultMessage: 'Bottom row' },
  footerLink: { id: 'Footer link', defaultMessage: 'Footer link' },
  refreshPage: {
    id: 'Refresh the page to see your changes',
    defaultMessage: 'Refresh the page to see your changes',
  },
});

const footerLinksSchema = ({ intl }) => {
  return {
    title: intl.formatMessage(messages.footerLink),
    properties: {
      'label_pt-br': {
        title: intl.formatMessage(messages.label),
        required: true,
      },
      'url_pt-br': {
        title: intl.formatMessage(messages.URL),
        description: intl.formatMessage(messages.URL_Description),
        required: true,
      },

      label_en: {
        title: intl.formatMessage(messages.label),
        required: true,
      },
      url_en: {
        title: intl.formatMessage(messages.URL),
        description: intl.formatMessage(messages.URL_Description),
        required: true,
      },

      label_es: {
        title: intl.formatMessage(messages.label),
        required: true,
      },
      url_es: {
        title: intl.formatMessage(messages.URL),
        description: intl.formatMessage(messages.URL_Description),
        required: true,
      },
    },
    fieldsets: [
      {
        id: 'pt',
        title: intl.formatMessage(messages.portuguese),
        fields: ['label_pt-br', 'url_pt-br'],
      },

      {
        id: 'en',
        title: intl.formatMessage(messages.english),
        fields: ['label_en', 'url_en'],
      },

      {
        id: 'es',
        title: intl.formatMessage(messages.spanish),
        fields: ['label_es', 'url_es'],
      },
    ],
    required: [],
  };
};

const FooterLinksWidget = (props) => {
  return (
    <>
      <div className="ui equal width grid">
        <div className="column">
          <ObjectListWidget
            schema={footerLinksSchema}
            {...props}
            title={props.intl.formatMessage(messages.topRow)}
            value={props.value?.top || props.default?.top || []}
            onChange={(id, value) =>
              props.onChange(id, { ...props.value, top: value })
            }
          />
        </div>
        <div className="column">
          <ObjectListWidget
            schema={footerLinksSchema}
            {...props}
            title={props.intl.formatMessage(messages.bottomRow)}
            value={props.value?.bottom || props.default?.bottom || []}
            onChange={(id, value) =>
              props.onChange(id, { ...props.value, bottom: value })
            }
          />
        </div>
      </div>
      <p className="help" style={{ padding: 8, textAlign: 'center' }}>
        {props.intl.formatMessage(messages.refreshPage)}
      </p>
    </>
  );
};

export default FooterLinksWidget;
