import { injectIntl } from 'react-intl';

const GroupStrictlyNecessary = ({ intl }) => {
  if (intl.locale === 'en') {
    return (
      <>
        These cookies enable core functionality such as security, identity
        verification, and network management.
        <strong className="text-weight-semi-bold">
          These cookies cannot be deactivated in our systems.
        </strong>
        Although they are necessary, you can block these cookies directly in
        your browser, but this may compromise your experience and impair the
        website's functionality.
      </>
    );
  }

  if (intl.locale === 'es') {
    return (
      <>
        Estas cookies habilitan funciones básicas como seguridad, verificación
        de identidad y administración de red.
        <div className="text-weight-semi-bold">
          Estas cookies no se pueden desactivar en nuestros sistemas.
        </div>{' '}
        Aunque son necesarias, puedes bloquear estas cookies directamente en tu
        navegador, pero esto puede comprometer tu experiencia y afectar la
        funcionalidad del sitio web.
      </>
    );
  }

  return (
    <>
      Esses cookies permitem funcionalidades essenciais, tais como segurança,
      verificação de identidade e gestão de rede.
      <strong className="text-weight-semi-bold">
        Esses cookies não podem ser desativados em nossos sistemas.
      </strong>
      Embora sejam necessários, você pode bloquear esses cookies diretamente no
      seu navegador, mas isso pode comprometer sua experiência e prejudicar o
      funcionamento do site.
    </>
  );
};

export default injectIntl(GroupStrictlyNecessary);
