import { ConditionalLink, MaybeWrap } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import config from '@plone/volto/registry';
import moment from 'moment';
import PropTypes from 'prop-types';
import { MoreInfo } from '@package/components';

import styles from './News.module.less';

const NewsTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  const Image = config.getComponent('Image').component;

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }
  return (
    <>
      <div>
        {items.map((item) => {
          const {
            '@id': url,
            '@type': type,
            id,
            title,
            description,
            head_title,
            review_state,
            effective,
            created,
          } = item;

          return (
            // <MaybeWrap
            //   condition={isEditMode}
            //   as="div"
            //   className={styles.item}
            //   key={url}
            // >
            //   <ConditionalLink
            //     item={item}
            //     condition={!isEditMode}
            //     className={styles[review_state]}
            //     key={url}
            //   >
            <div key={url} className={styles[review_state || 'published']}>
              <div className={styles.thumb}>
                <Image
                  item={item}
                  imageField={item.image_field}
                  alt=""
                  loading="lazy"
                  responsive={true}
                />
                {/* <ContentImage item={item} alt="" size="preview" /> */}
              </div>
              <div className={styles.text}>
                {head_title ? (
                  <div className={styles.head}>{head_title}</div>
                ) : null}
                <div className={styles.title}>{title ? title : id}</div>
                <div className={styles.date}>
                  {moment(
                    review_state === 'published' ? effective : created,
                  ).format('LL')}
                </div>
                {description ? (
                  <div className={styles.desc}>{description}</div>
                ) : null}
                <div className="more-link">
                  <MaybeWrap condition={isEditMode} as="span" key={url}>
                    <ConditionalLink
                      item={item}
                      condition={!isEditMode}
                      key={url}
                    >
                      <MoreInfo title={title} type={type} />
                    </ConditionalLink>
                  </MaybeWrap>
                </div>
              </div>
              {/* </ConditionalLink> */}
              {/* </MaybeWrap> */}
            </div>
          );
        })}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

NewsTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default NewsTemplate;
