import { memo } from 'react';
const SvgKoo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M27.103 5.312c-.06-.681-.408-3.24-2.294-3.595-.452-.085-1.279.421.587 1.767-.204-.152-1.836-1.323-2.187-.126-.086.292-.28.84 1.559 1.349 0 0-1.565-.505-1.639.417.495-.073.995-.124 1.507-.124.846 0 1.669.115 2.467.312zM35.457 19.694c-.239-.795-.8-4.07-.8-4.07C33.808 10.154 29.686 6 24.717 6 19.135 6 14.61 11.24 14.61 17.704c0 .44.028.872.07 1.299a.48.48 0 0 1 .181.052c2.33 1.185 4.481 5.144 3.577 7.904-.485 1.479-1.908 3.047-5.759 3.047-.809 0-1.733-.074-2.771-.228-2.176.229-1.682 1.758-1.682 1.758 1.621 4.864 7.556 6.636 10.295 7.206L19.94 43h-1.306a.5.5 0 0 0 0 1h1.793l1.854 1.854a.498.498 0 0 0 .707 0 .5.5 0 0 0 0-.707L21.841 44h1.793a.5.5 0 0 0 0-1h-2.64l-1.352-4.056c.171.025.288.04.288.04 2.309.385 4.295.342 6.015.03L26.94 42h-1.306a.5.5 0 0 0 0 1h1.793l1.854 1.854a.498.498 0 0 0 .707 0 .5.5 0 0 0 0-.707L28.841 43h1.793a.5.5 0 0 0 0-1h-2.64l-1.066-3.198c3.968-1.023 6.322-3.562 7.61-5.574 1.306-2.675 2.844-7.116.919-13.534z"
    />
    <path
      fill="currentColor"
      d="M17.487 26.647c.754-2.303-1.084-5.442-2.799-6.537-1.409 1.986-3.894 5.793-4.538 8.684 4.079.583 6.687-.163 7.337-2.147zM36.784 21.48c.541 3.667.376 6.583-.141 8.876 4.022-.809 3.406-4.867.141-8.876z"
    />
    <circle cx={24} cy={16} r={1} fill="#fff" />
    <circle cx={31} cy={16} r={1} fill="#fff" />
    <path
      fill="#fff"
      d="m25.881 18.306 1.53-.755a.251.251 0 0 1 .225.002l1.474.758a.25.25 0 0 1 .004.443l-1.447.775a.25.25 0 0 1-.23.003l-1.557-.778a.25.25 0 0 1 .001-.448z"
    />
  </svg>
);
const Memo = memo(SvgKoo);
export default Memo;
