import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Group, Footer } from './components';
import { SearchWidget, LanguageSelector } from '@plone/volto/components';
import { SocialLinks } from '@package/components';
import { headerMessages } from '@package/messages';

import styles from './SiteNavigation.module.less';
import { accessibleOnClick } from '@package/helpers';

const SiteNavigation = ({
  title,
  'menu-label': menuLabel,
  'close-label': closeLabel,
  active,
  onToggleMenu,
  children,
}) => {
  const intl = useIntl();

  const Groups = React.Children.map(children, (child) =>
    child.type.displayName === 'Group' ? child : null,
  );

  const [activeGroup, setActiveGroup] = useState(
    React.Children.count(Groups) > 0 && Groups[0].key,
  );

  if (React.Children.count(Groups) === 0) {
    return null;
  }

  const Content = React.Children.map(Groups, (group) => {
    const collapsible = React.Children.count(Groups) > 1;
    const isActive = group.key === activeGroup;

    const onGroupSelect = () => {
      if (activeGroup === group.key) {
        setActiveGroup('');
      } else {
        setActiveGroup(group.key);
      }
    };

    return React.cloneElement(group, {
      active: isActive,
      collapsible,
      onGroupSelect: collapsible ? onGroupSelect : null,
      onNavigate: onToggleMenu,
    });
  });

  const Footer = React.Children.map(children, (child) =>
    child.type.displayName === 'Footer' ? child : null,
  );

  return (
    <>
      {!!active && (
        <div
          className="br-scrim-util foco active"
          aria-expanded="true"
          aria-label={closeLabel}
          {...accessibleOnClick(onToggleMenu)}
        />
      )}
      <nav
        className={active ? styles.active : styles.root}
        aria-label={menuLabel}
      >
        {/* {title && (
          <Header
            title={title}
            handleToggleMenu={onToggleMenu}
            closeLabel={closeLabel}
          />
        )} */}
        <div className={styles.top}>
          <div className={styles.search}>
            <SearchWidget />
          </div>
          <div className={styles.dashboard}>
            <a href="https://g20.org/accreditation/" className={styles.dash}>
              {intl.formatMessage(headerMessages.delegates_area)}
            </a>
            <LanguageSelector />
          </div>
        </div>
        <div className={styles.content}>{!!active && Content}</div>
        <SocialLinks className={styles.social} />
        {Footer}
      </nav>
    </>
  );
};

SiteNavigation.Group = Group;
SiteNavigation.Footer = Footer;

SiteNavigation.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool,
  onToggleMenu: PropTypes.func,
  'menu-label': PropTypes.string,
  'close-label': PropTypes.string,
  children: PropTypes.node,
};

SiteNavigation.defaultProps = {
  'menu-label': 'Main navigation menu',
  'close-label': 'Close menu',
  active: false,
};

export default SiteNavigation;
