import { SocialShare } from '@package/components';
import PropTypes from 'prop-types';
import styles from './DateShareBody.module.less';
import DocumentDates from './Dates';

const DateShareBody = (props) => {
  const { properties, data } = props;
  const { hideSocialShare } = data;

  return (
    <div className={styles.root}>
      <DocumentDates properties={properties} />
      {hideSocialShare ? null : (
        <SocialShare url={properties['@id']} text={properties.title} />
      )}
    </div>
  );
};

DateShareBody.propTypes = {
  properties: PropTypes.shape({
    created: PropTypes.string,
    modified: PropTypes.string,
    effective: PropTypes.string,
  }),
};

export default DateShareBody;
