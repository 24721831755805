import { GradientButton } from '@package/components';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';
import CardListCountries from './components/CardListCountries/CardListCountries';
import linksList from './data';

import styles from './CountriesListBody.module.less';
import Organizations from './components/Organizations/Organizations';

const CountriesListBody = (props) => {
  const intl = useIntl();
  const { data, isEditMode } = props;
  const {
    title,
    subtitle,
    decorationLeft,
    decorationRight,
    countries,
    buttonLabel,
    buttonHref,
    addLinks,
  } = data;

  const sorted = linksList(countries, intl).sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });

  const content =
    countries === 'organizacoes' ? (
      <Organizations
        data={sorted}
        isEditMode={isEditMode}
        addLinks={addLinks}
      />
    ) : (
      <CardListCountries
        data={sorted}
        isEditMode={isEditMode}
        addLinks={addLinks}
      />
    );

  const blockClass = cx(styles.wrap, {
    'full-width': !isEditMode,
    decorationLeft,
    decorationRight,
  });

  return (
    <div className={blockClass}>
      <Container className={styles.cont}>
        {subtitle || title ? (
          <div className={styles.title}>
            {subtitle ? (
              <div className="sectionSubtitle">{subtitle}</div>
            ) : null}
            {title ? <h2 className="sectionTitle invert">{title}</h2> : null}
          </div>
        ) : null}

        {content}

        {buttonLabel ? (
          <div className={styles.foot}>
            <GradientButton
              item={buttonHref?.length && !isEditMode ? buttonHref[0] : null}
              label={buttonLabel}
            />
          </div>
        ) : null}
      </Container>
    </div>
  );
};

CountriesListBody.propTypes = {
  isEditMode: PropTypes.bool,
};

export default CountriesListBody;

// Precisamos incluir, no módulo “Lista de países”, um novo sub-módulo para “ORGANIZAÇÕES INTERNACIONAIS CONVIDADAS” - que deve seguir o mesmo padrão visual do módulo-pai.

// Este módulo deve conter apenas uma listagem, em texto e em quatro colunas, para as seguintes organizações com seus respectivos links e em todos os idiomas:

// PT

// ORGANIZAÇÕES INTERNACIONAIS CONVIDADAS

// Banco Interamericano de Desenvolvimento (https://www.iadb.org/pt-br)
// Banco Mundial (https://www.worldbank.org/pt/country/brazil)
// Banco de Desenvolvimento da América Latina e Caribe (https://www.caf.com/pt/)
// Organização das Nações Unidas para a Alimentação e a Agricultura (https://www.fao.org/brasil/pt/)
// Fundo Monetário Internacional (https://www.imf.org/en/home)
// New Development Bank (https://www.ndb.int/)
// Organização Internacional do Trabalho (https://www.ilo.org/brasilia/lang--es/index.htm)
// World Trade Organization (https://www.wto.org/index.htm)
// World Health Organization (https://www.who.int/)
// Nações Unidas (https://brasil.un.org/pt-br)
// United Nations Conference on Trade and Development (https://unctad.org/)
// Unesco (https://www.unesco.org/en)

// EN

// INVITED INTERNATIONAL ORGANIZATIONS

// Inter-American Development Bank (https://www.iadb.org/en)
// The World Bank (https://www.worldbank.org/en/home)
// Development Bank of the Latin America and The Caribbean (https://www.caf.com/en/)
// Food and Agriculture Organization of the United Nations (https://www.fao.org/home/en/)
// International Monetary Fund (https://www.imf.org/en/home)
// New Development Bank (https://www.ndb.int/)
// International Labour Organization (https://www.ilo.org/global/lang--en/index.htm)
// World Trade Organization (https://www.wto.org/index.htm)
// World Health Organization (https://www.who.int/)
// United Nations (https://www.un.org/en/)
// United Nations Conference on Trade and Development (https://unctad.org/)
// Unesco (https://www.unesco.org/en)

// ES

// ORGANIZACIONES INTERNACIONALES INVITADAS

// Banco Interamericano de Desarrollo (https://www.iadb.org/es)
// Banco Mundial (https://www.bancomundial.org/es/home)
// Banco de Desarrollo de América Latina y el Caribe  (https://www.caf.com/)
// Organización de las Naciones Unidas para la Alimentación y la Agricultura (https://www.fao.org/home/es)
// Fondo Monetary Internacional (https://www.imf.org/es/home)
// New Development Bank (https://www.ndb.int/)
// Organización Internacional del Trabajo (https://www.ilo.org/global/lang--es/index.htm)
// Organización Mundial del Comercio (https://www.wto.org/indexsp.htm)
// Organización Mundial de la Salud (https://www.who.int/es/)
// Naciones Unidas (https://www.un.org/es/)
// Conferencia de las Naciones Unidas sobre Comercio y Desarrollo (https://unctad.org/es)
// Unesco (https://www.unesco.org/es)
