import { memo } from 'react';
const SvgFacebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="M31.997 15.999C31.997 7.163 24.834 0 15.998 0S-.001 7.163-.001 15.999c0 7.985 5.851 14.604 13.499 15.804v-11.18H9.436v-4.625h4.062v-3.525c0-4.01 2.389-6.225 6.043-6.225 1.75 0 3.581.313 3.581.313v3.937h-2.017c-1.987 0-2.607 1.233-2.607 2.498v3.001h4.437l-.709 4.625h-3.728v11.18c7.649-1.2 13.499-7.819 13.499-15.804z"
    />
  </svg>
);
const Memo = memo(SvgFacebook);
export default Memo;
