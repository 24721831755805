import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ContentImage, DateInfo } from '@package/components';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';

import styles from './SummaryTemplate.module.less';

// TODO: Testar tamanhos das fontes em celular real
// TODO: Usar srcset

const SummaryTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div>
        {items.map((item) => {
          // console.log(item);
          const { '@id': url, id, title, description } = item;
          return (
            <ConditionalLink
              item={item}
              condition={!isEditMode}
              className="br-item"
              key={url}
            >
              <div className="py-3 d-flex align-items-center" key={url}>
                <div className="flex-fill">
                  <div className="text-base text-sm-up-02 text-weight-medium text-interactive">
                    {title ? title : id}
                  </div>
                  <div className="mb-2 text-down-01 text-sm-up-01 text-gray-80">
                    {description}
                  </div>
                  <DateInfo item={item} className="text-down-01 text-sm-base" />
                </div>
                <ContentImage
                  item={item}
                  alt=""
                  size="mini"
                  className={styles.thumb}
                />
              </div>
            </ConditionalLink>
          );
        })}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

SummaryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default SummaryTemplate;
