import iconSVG from '@plone/volto/icons/calendar.svg';
import { defineMessages } from 'react-intl';
import CalendarEdit from './CalendarEdit';
import CalendarView from './CalendarView';

defineMessages({
  calendar: {
    id: 'Calendar',
    defaultMessage: 'Calendar',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.calendarBlock = {
    id: 'calendarBlock',
    title: 'Calendar',
    group: 'portalg20',
    icon: iconSVG,
    view: CalendarView,
    edit: CalendarEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
