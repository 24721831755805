import { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL, toBackendLang } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import cx from 'classnames';
import styles from './NavItem.module.less';
import { Icon } from '@package/icons';

const NavItem = ({ item, lang }) => {
  const { settings } = config;
  const menuRef = useRef();

  const itemClass = (review_state) => {
    return cx('item', styles.link, {
      [styles[review_state]]: review_state && review_state !== 'published',
    });
  };

  if (item.items.length > 0) {
    return (
      <div className={styles.parent} ref={menuRef}>
        <NavLink
          to={item.url === '' ? '/' : item.url}
          className={itemClass(item.review_state)}
          activeClassName={styles.active}
          onClick={(e) => {
            /*  e.preventDefault();

            toggleMenu();*/
          }}
        >
          {item.title}
          <Icon icon="ChevronDown" size="15px" />
        </NavLink>
        <div className={styles.sub}>
          {item.items.map((child) => (
            <NavLink
              to={child.url === '' ? '/' : child.url}
              key={child.url}
              className={itemClass(child.review_state)}
              activeClassName={styles.active}
              exact={
                settings.isMultilingual
                  ? child.url === `/${toBackendLang(lang)}`
                  : child.url === ''
              }
              // onClick={toggleMenu}
            >
              {child.title}
            </NavLink>
          ))}
        </div>
      </div>
    );
  }

  // The item.url in the root is ''
  // TODO: make more consistent it everywhere (eg. reducers to return '/' instead of '')
  if (isInternalURL(item.url) || item.url === '') {
    return (
      <NavLink
        to={item.url === '' ? '/' : item.url}
        key={item.url}
        className={itemClass(item.review_state)}
        activeClassName={styles.active}
        exact={
          settings.isMultilingual
            ? item.url === `/${toBackendLang(lang)}`
            : item.url === ''
        }
      >
        {item.title}
      </NavLink>
    );
  }

  return (
    <a
      href={item.url}
      key={item.url}
      className={itemClass(item.review_state)}
      rel="noopener noreferrer"
      target={settings.openExternalLinkInNewTab ? '_blank' : '_self'}
    >
      {item.title}
    </a>
  );
};

export default NavItem;
