import { defineMessages } from 'react-intl';
import DateShareView from './DateShareView';
import DateShareEdit from './DateShareEdit';
import iconSVG from './icons/date-share.svg';

defineMessages({
  dateShare: {
    id: 'Date / Social share',
    defaultMessage: 'Date / Social share',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.dateShareBlock = {
    id: 'dateShareBlock',
    title: 'Date / Social share',
    group: 'layout',
    icon: iconSVG,
    view: DateShareView,
    edit: DateShareEdit,
    restricted: ({ properties, block }) =>
      properties.blocks_layout?.items?.find(
        (uid) => properties.blocks?.[uid]?.['@type'] === block.id,
      ),
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
