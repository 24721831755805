import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';

import { Icon } from '@package/icons';

import styles from './IconButton.module.less';

const IconButton = ({
  icon,
  dark,
  className,
  title,
  type = 'button',
  small,
  large,
  border,
  href,
  iconSize,
  forceExternal = false,
  ...rest
}) => {
  const classes = cx(
    styles.btn,
    {
      [styles.small]: small,
      [styles.large]: large,
      [styles.border]: border,
      [styles.dark]: dark,
    },
    className,
  );

  const commonProps = {
    title,
    className: classes,
    ...rest,
  };

  const content = <Icon icon={icon} size={iconSize} />;

  if (!href) {
    return (
      <button type={type} {...commonProps}>
        {content}
      </button>
    );
  }

  return forceExternal ? (
    <a href={href} {...commonProps}>
      {content}
    </a>
  ) : (
    <UniversalLink href={href} {...commonProps}>
      {content}
    </UniversalLink>
  );
};

export default IconButton;
