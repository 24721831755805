import { memo } from 'react';
const SvgAltaTemperatura = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12 15.05V4h-1v11.05c-1.14.232-2 1.242-2 2.45 0 1.379 1.121 2.5 2.5 2.5s2.5-1.121 2.5-2.5c0-1.208-.86-2.218-2-2.45ZM11.5 19c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5Zm10-19C20.121 0 19 1.121 19 2.5S20.121 5 21.5 5 24 3.879 24 2.5 22.879 0 21.5 0Zm0 4c-.827 0-1.5-.673-1.5-1.5S20.673 1 21.5 1s1.5.673 1.5 1.5S22.327 4 21.5 4ZM16 12.819V4.5C16 2.019 13.981 0 11.5 0S7 2.019 7 4.5v8.319c-1.627 1.561-2.32 3.805-1.859 6.049.508 2.472 2.506 4.476 4.972 4.987.459.096.92.143 1.376.143A6.493 6.493 0 0 0 16 12.819Zm-1.031 8.949c-1.291 1.051-2.989 1.454-4.653 1.108-2.081-.432-3.767-2.124-4.194-4.21-.405-1.968.235-3.933 1.713-5.258l.166-.148V4.5c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5v8.761l.166.148a5.507 5.507 0 0 1 1.834 4.091c0 1.662-.74 3.218-2.031 4.269Z" />
  </svg>
);
const Memo = memo(SvgAltaTemperatura);
export default Memo;
