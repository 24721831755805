import { GET_G20_EVENTS } from '../constants/ActionTypes';

/**
 * Get site propreties.
 * @function getSiteProperties
 * @returns {Object} Get the site propreties
 */

import config from '@plone/volto/registry';

export function getG20Events() {
  const { settings } = config;

  return {
    type: GET_G20_EVENTS,
    request: {
      op: 'get',
      path: `${settings.apiPath}/g20-api/events`,
    },
  };
}
