import { Icon } from '@package/icons';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  moreInfo: { id: 'More info', defaultMessage: 'More info' },
  download: { id: 'Download', defaultMessage: 'Download' },
  about: { id: 'about', defaultMessage: 'about' },
});

const MoreInfo = ({ title, type }) => {
  const intl = useIntl();

  if (type === 'File') {
    return (
      <>
        <Icon icon="Download" size="14px" aria-hidden="true" />
        {intl.formatMessage(messages.download)}
        {title ? (
          <span className="sr-only">
            {intl.formatMessage(messages.about)}
            {title}
          </span>
        ) : null}
      </>
    );
  }

  return (
    <>
      {intl.formatMessage(messages.moreInfo)}
      {title ? (
        <span className="sr-only">
          {intl.formatMessage(messages.about)}
          {title}
        </span>
      ) : null}
      <Icon icon="ArrowRight" size="14px" aria-hidden="true" />
    </>
  );
};

export default MoreInfo;
