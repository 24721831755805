/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import { useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Slider from 'react-slick';
import { Message } from 'semantic-ui-react';
import CarouselBody from './CarouselBody';
import { Icon } from '@package/icons';

const messages = defineMessages({
  EmptyCarousel: {
    id: 'The carousel is empty',
    defaultMessage: 'The carousel is empty',
    // defaultMessage: 'O carrossel está vazio. Por favor, adicione algum item a ele usando a barra lateral'
  },
  YouCanAddItems: {
    id: 'You can add items using the sidebar',
    defaultMessage: 'You can add items using the sidebar',
    // defaultMessage: 'O carrossel está vazio. Por favor, adicione algum item a ele usando a barra lateral'
  },
});

const Arrow = ({ icon, className, style, onClick }) => {
  return (
    <div className={className} style={style}>
      <button
        aria-hidden="true"
        onClick={(e) => {
          if (onClick && typeof onClick == 'function') {
            onClick(e);
          } else {
            return;
          }
        }}
      >
        <Icon icon={icon} />
      </button>
    </div>
  );
};

const View = (props) => {
  const { block, data, isEditMode, openObjectBrowser, onChangeBlock } = props;
  const intl = useIntl();
  const sliderRef = useRef(null);

  const {
    columns,
    infinite,
    autoplay,
    autoplaySpeed,
    aspectRatioDesktop = '1.7778',
    aspectRatioMobile = '1.5',
    layout = 'news',
    // ratio = 'wide',
  } = data;

  const stopAutoPlay = () => {
    if (sliderRef) {
      sliderRef?.current.slickPause();
    }
  };

  return (
    <div
      className={cx('block carousel', layout)}
      style={{
        '--carousel-user-aspect-ratio-mobile': aspectRatioMobile,
        '--carousel-user-aspect-ratio-desktop': aspectRatioDesktop,
      }}
      onClick={() => {
        stopAutoPlay();
      }}
    >
      {(columns?.length === 0 || !columns) && isEditMode && (
        <Message>
          <div className="slide-content empty">
            <div style={{ fontWeight: 600 }}>
              {intl.formatMessage(messages.EmptyCarousel)}
            </div>
            <p>{intl.formatMessage(messages.YouCanAddItems)}</p>
            <div className="imgs">
              <span>
                <Icon icon="Image" size={42} />
              </span>
              <span>
                <Icon icon="Youtube" size={42} />
              </span>
              <span>
                <Icon icon="FileText" size={42} />
              </span>
            </div>
          </div>
        </Message>
      )}

      <Slider
        dots={true}
        arrows={true}
        speed={500}
        infinite={infinite}
        autoplay={autoplay}
        autoplaySpeed={
          parseInt(autoplaySpeed) ? parseInt(autoplaySpeed) * 1000 : null
        }
        pauseOnHover={true}
        pauseOnFocus={true}
        pauseOnDotsHover={true}
        nextArrow={<Arrow icon="ChevronRight" />}
        prevArrow={<Arrow icon="ChevronLeft" />}
        onSwipe={() => {
          stopAutoPlay();
        }}
        ref={sliderRef}
      >
        {columns &&
          columns.map((item, index) => {
            const { href, url_video } = item;
            if (!isEditMode && !url_video && !href) {
              return null;
            }

            return (
              <CarouselBody
                key={item['@id']}
                data={item}
                isEditMode={isEditMode}
                dataBlock={data}
                index={index}
                block={block}
                openObjectBrowser={openObjectBrowser}
                onChangeBlock={onChangeBlock}
              />
            );
          })}
      </Slider>
    </div>
  );
};

export default View;
