import { Checkbox } from 'semantic-ui-react';

const FilterCheckbox = ({ checked, indeterminate, label, onChange }) => {
  // console.log(indeterminate);

  return (
    <Checkbox
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
      label={label}
    />
  );
};

export default FilterCheckbox;
