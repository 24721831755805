import { memo } from 'react';
const SvgMusic = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="music_svg__feather music_svg__feather-music"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M9 18V5l12-2v13" />
    <circle cx={6} cy={18} r={3} />
    <circle cx={18} cy={16} r={3} />
  </svg>
);
const Memo = memo(SvgMusic);
export default Memo;
