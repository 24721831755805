import { defineMessages } from 'react-intl';
import textCenteredSVG from '@plone/volto/icons/align-center.svg';
import textLeftSVG from '@plone/volto/icons/align-left.svg';
import textRightSVG from '@plone/volto/icons/align-right.svg';

const messages = defineMessages({
  sectionSubtitle: {
    id: 'Section subtitle',
    defaultMessage: 'Section subtitle',
  },
  align: {
    id: 'Align',
    defaultMessage: 'Align',
  },
  left: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  right: {
    id: 'Right',
    defaultMessage: 'Right',
  },
  center: {
    id: 'Center',
    defaultMessage: 'Center',
  },
});

export const Schema = ({ intl }) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.sectionSubtitle),
        fields: ['title', 'align'],
      },
    ],
    properties: {
      title: { title: intl.formatMessage(messages.sectionSubtitle) },
      align: {
        title: intl.formatMessage(messages.align),
        required: false,
        default: 'center',
        type: 'string',
        widget: 'buttons',
        actions: ['left', 'center', 'right'],
        actionsInfoMap: {
          left: [textLeftSVG, intl.formatMessage(messages.left)],
          center: [textCenteredSVG, intl.formatMessage(messages.center)],
          right: [textRightSVG, intl.formatMessage(messages.right)],
        },
      },
    },
    required: [],
  };
};
