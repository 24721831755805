import { memo } from 'react';
const SvgTriangleDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="currentColor" d="M5 6h10l-5 9-5-9z" />
  </svg>
);
const Memo = memo(SvgTriangleDown);
export default Memo;
