import { CityLink, TrackTag } from '@package/components';
import moment from 'moment';

const listEvent = ({ event }) => {
  const showEnd = !moment(event.start).isSame(event.end, 'day');

  const eventDay = (
    <div className="day">
      {/* <span>{moment(event.start).format(showMonth ? 'MMM DD' : 'DD')}</span> */}
      <span>{moment(event.start).format('MMM DD')}</span>
      {showEnd && (
        // <span>{moment(event.end).format(showMonth ? 'MMM DD' : 'DD')}</span>
        <span>{moment(event.end).format('MMM DD')}</span>
      )}
    </div>
  );

  return (
    <div className="rbc-list-event">
      {eventDay}
      <div className="inner">
        <div className="title">{event.title}</div>
        <div className="info">
          <TrackTag track={event.track} className="track" />
          <CityLink name={event.city} className="city" />
        </div>
      </div>
    </div>
  );
};

export default listEvent;
