import { Icon } from '@package/icons';
import { useDispatch } from 'react-redux';
import { openManageCookies } from '../../actions';

const ManageCookiesButton = ({
  label,
  'label-hidden': labelHidden,
  className,
}) => {
  const dispatch = useDispatch();

  return (
    <button
      className={`lgpd-button ${className}`}
      type="button"
      aria-label={label}
      title={labelHidden ? label : null}
      onClick={() => {
        dispatch(openManageCookies());
      }}
    >
      <Icon icon="Cookie" />
      {labelHidden ? null : <span className="label">{label}</span>}
    </button>
  );
};

export default ManageCookiesButton;
