import { memo } from 'react';
const SvgArvoreDeFolhaCaduca = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M18.783 5.236a1.489 1.489 0 0 1-1.057-1.021c-.785-2.521-3.086-4.216-5.727-4.216S7.058 1.694 6.273 4.216c-.16.514-.545.886-1.057 1.021C1.815 6.13-.4 9.446.063 12.951c.401 3.036 2.79 5.486 5.808 5.96.377.059.757.089 1.129.089h4.5v4.5a.5.5 0 0 0 1 0V19H17c.372 0 .752-.03 1.129-.089 3.018-.474 5.406-2.924 5.808-5.96.463-3.505-1.752-6.821-5.154-7.715Zm4.163 7.584c-.338 2.559-2.429 4.704-4.971 5.103A6.34 6.34 0 0 1 17 18h-4.5v-2.793l4.354-4.354a.5.5 0 0 0-.707-.707l-3.646 3.646V8.499a.5.5 0 0 0-1 0v5.293l-3.646-3.646a.5.5 0 0 0-.707.707l4.354 4.354V18H7c-.32 0-.648-.026-.974-.077-2.542-.398-4.633-2.544-4.972-5.103-.397-3.007 1.501-5.852 4.417-6.617a2.502 2.502 0 0 0 1.758-1.69C7.882 2.412 9.8 1 12 1s4.118 1.412 4.771 3.513c.26.838.917 1.47 1.758 1.69 2.915.766 4.813 3.61 4.417 6.617Z" />
  </svg>
);
const Memo = memo(SvgArvoreDeFolhaCaduca);
export default Memo;
