import { defineMessages } from 'react-intl';

const messages = defineMessages({
  subtitle: { id: 'Subtitle', defaultMessage: 'Subtitle' },
  title: { id: 'Title', defaultMessage: 'Title' },
  calendar: { id: 'Calendar', defaultMessage: 'Calendar' },
});

export const calendarSchema = ({ intl }) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.calendar),
        fields: ['subtitle', 'title'],
      },
    ],
    properties: {
      subtitle: { title: intl.formatMessage(messages.subtitle) },
      title: { title: intl.formatMessage(messages.title) },
    },
    required: [],
  };
};
