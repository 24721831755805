import { defineMessages } from 'react-intl';

const messages = defineMessages({
  hideSocialShare: {
    id: 'Hide Social share',
    defaultMessage: 'Hide Social share',
  },
});

export const dateShareSchema = ({ intl }) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['hideSocialShare'],
      },
    ],
    properties: {
      hideSocialShare: {
        title: intl.formatMessage(messages.hideSocialShare),
        required: false,
        default: false,
        type: 'boolean',
      },
    },
    required: [],
  };
};
