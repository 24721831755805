import { memo } from 'react';
const SvgTumblr = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.417 32c-6.791 0-9.375-5.008-9.375-8.548V12.996H6.821V8.864c4.84-1.751 6.016-6.128 6.28-8.625.019-.171.153-.239.231-.239h4.689v8.152h6.401v4.844h-6.427v9.96c.021 1.335.5 3.161 2.943 3.161h.12c.841-.027 1.981-.273 2.581-.559l1.541 4.567c-.581.848-3.2 1.832-5.541 1.872h-.237z"
    />
  </svg>
);
const Memo = memo(SvgTumblr);
export default Memo;
