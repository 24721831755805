import iconSVG from '@plone/volto/icons/flag.svg';
import { defineMessages } from 'react-intl';
import CountriesListEdit from './CountriesListEdit';
import CountriesListView from './CountriesListView';

defineMessages({
  countriesList: {
    id: 'Countries list',
    defaultMessage: 'Countries list',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.countriesListBlock = {
    id: 'countriesListBlock',
    title: 'Countries list',
    group: 'portalg20',
    icon: iconSVG,
    view: CountriesListView,
    edit: CountriesListEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
