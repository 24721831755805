import { memo } from 'react';
const SvgLivroAbertoCapa = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M24 3.5v13.961a4.512 4.512 0 0 1-3.294 4.335l-7.12 1.981a5.924 5.924 0 0 1-3.173 0l-7.12-1.981A4.513 4.513 0 0 1 0 17.461V4.5c-.01-.649 1.01-.649 1 0v12.961a3.511 3.511 0 0 0 2.562 3.372l7.121 1.981c.862.24 1.773.24 2.636 0l7.12-1.982a3.51 3.51 0 0 0 2.562-3.372V3.5c-.01-.649 1.01-.649 1 0ZM3 15.911V3.5A3.47 3.47 0 0 1 4.392.706 3.475 3.475 0 0 1 7.461.134l3.225.922c.538.153.993.48 1.313.908.32-.428.776-.755 1.313-.908l3.225-.922a3.47 3.47 0 0 1 3.069.572A3.47 3.47 0 0 1 20.998 3.5v12.411a3.517 3.517 0 0 1-2.538 3.366l-5.609 1.603a3.11 3.11 0 0 1-1.706 0l-5.608-1.603a3.516 3.516 0 0 1-2.539-3.366ZM12.5 3.46v16.478c.026-.006 5.687-1.623 5.687-1.623A2.51 2.51 0 0 0 20 15.911V3.5c0-.792-.362-1.519-.995-1.996a2.48 2.48 0 0 0-2.192-.408l-3.225.922A1.506 1.506 0 0 0 12.5 3.46ZM4 15.911c0 1.11.746 2.099 1.813 2.404 0 0 5.661 1.617 5.687 1.623V3.46c0-.666-.447-1.259-1.088-1.442l-3.225-.922C5.658.607 3.951 1.889 4 3.5v12.411Z" />
  </svg>
);
const Memo = memo(SvgLivroAbertoCapa);
export default Memo;
