import { defineMessages } from 'react-intl';

export default defineMessages({
  linkViewEditMode: {
    id: 'linkViewEditMode',
    defaultMessage:
      "You see this page because you have permission to edit this link. Others will be immediately redirected to the link's target URL.",
  },
  linkViewLinksAddress: {
    id: 'linkViewLinksAddress',
    defaultMessage: 'The link address is',
  },
});
