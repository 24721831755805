import moment from 'moment';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import ListViewEvent from './ListViewEvent';

const messages = defineMessages({
  NoEventsScheduled: {
    id: 'No events scheduled yet',
    defaultMessage: 'No events scheduled yet',
  },
});

function CustomListWrapper({ events }) {
  const intl = useIntl();

  const months = events
    ?.sort((a, b) => {
      const diff = moment(a.start).diff(b.start, 'day');
      return diff ? diff : moment(a.end).diff(b.end, 'day');
    })

    .reduce(
      (prev, event) => {
        const eventMonth = moment(event.start).format('MMMM YYYY');
        const result = { ...prev };

        if (!Object(result).hasOwnProperty(eventMonth)) {
          result[eventMonth] = [];
          result.names.push(eventMonth);
        }

        result[eventMonth].push(event);

        return result;
      },
      { names: [] },
    );

  const renderEvents = (events) => {
    if (!events.length) {
      return (
        <span className="rbc-list-empty">
          {intl.formatMessage(messages.NoEventsScheduled)}
        </span>
      );
    }

    return events.map((event) => <ListViewEvent event={event} />);
  };

  if (!events.length) {
    return (
      <span className="rbc-list-empty">
        {intl.formatMessage(messages.NoEventsScheduled)}
      </span>
    );
  }

  return (
    <div className="rbc-list-view">
      <div className="rbc-list-content">
        {months.names.map((month) => (
          <div key={month} className="rbc-list-month">
            <div className="rbc-list-month-title">{month}</div>
            <div className="rbc-list-month-content">
              {renderEvents(months[month])}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

CustomListWrapper.propTypes = {
  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  date: PropTypes.instanceOf(Date),
  events: PropTypes.array,
  getters: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired,
  localizer: PropTypes.object.isRequired,
  onSelectEvent: PropTypes.func,
  onDoubleClickEvent: PropTypes.func,
  selected: PropTypes.object,
};

CustomListWrapper.defaultProps = {
  length: 30,
};

CustomListWrapper.range = (
  start,
  { length = CustomListWrapper.defaultProps.length, localizer },
) => {
  let end = localizer.add(start, length, 'day');
  return { start, end };
};

CustomListWrapper.navigate = (
  date,
  action,
  { length = CustomListWrapper.defaultProps.length, localizer },
) => {
  switch (action) {
    case navigate.PREVIOUS:
      return localizer.add(date, -length, 'day');

    case navigate.NEXT:
      return localizer.add(date, length, 'day');

    default:
      return date;
  }
};

CustomListWrapper.title = (
  start,
  { length = CustomListWrapper.defaultProps.length, localizer },
) => {
  let end = localizer.add(start, length, 'day');
  return localizer.format({ start, end }, 'listHeaderFormat');
};

export default CustomListWrapper;
