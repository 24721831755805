import PropTypes from 'prop-types';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import { UniversalLink, MaybeWrap } from '@plone/volto/components';
import { Icon } from '@package/icons';

import styles from './CardsBody.module.less';

const messages = defineMessages({
  empty: {
    id: 'Use the sidebar to add some cards',
    defaultMessage: 'Use the sidebar to add some cards',
  },
});

const CardsBody = (props) => {
  const { data, isEditMode } = props;
  const {
    cards,
    cardsPerRow = 4,
    cardsPerRowOnMobile = 1,
    align,
    label: globalLabel,
    style: globalStyle,
  } = data;
  const intl = useIntl();

  if (!cards || !cards.length) {
    return (
      <div style={{ textAlign: 'center' }}>
        {intl.formatMessage(messages.empty)}
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={cx(styles.wrap, styles[`mobile-${cardsPerRowOnMobile}`])}>
        {cards.map((card, idx) => {
          const {
            style = globalStyle,
            icon,
            title,
            description,
            href,
            label,
            openLinkInNewTab,
          } = card;

          const cardStyle = cx(styles[`card-${cardsPerRow}`], styles[align]);

          if (!icon && !title && !description && !href && isEditMode) {
            return (
              <div className={cardStyle}>
                <div className={styles.placeholder}>
                  <div className={styles.phIcon} />
                  <div className={styles.phTitle} />
                  <div className={styles.phDesc} />
                  <div className={styles.phDesc} />
                </div>
              </div>
            );
          }

          return (
            <div key={idx} className={cardStyle}>
              <MaybeWrap
                condition={href?.length}
                as={UniversalLink}
                openLinkInNewTab={openLinkInNewTab}
                className={styles.inner}
                href={href?.length && !isEditMode ? href[0]['@id'] : null}
              >
                <MaybeWrap
                  condition={!href?.length}
                  as="div"
                  className={styles.inner}
                >
                  {icon ? (
                    <div className={styles.icon}>
                      <span className={style}>
                        <Icon icon={icon} size={42} />
                      </span>
                    </div>
                  ) : null}
                  {title ? <div className={styles.title}>{title}</div> : null}
                  {description ? (
                    <div className={styles.desc}>{description}</div>
                  ) : null}
                  {href?.length && (label || globalLabel) ? (
                    <span className="more-link">{label || globalLabel}</span>
                  ) : null}
                </MaybeWrap>
              </MaybeWrap>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CardsBody.propTypes = {
  isEditMode: PropTypes.bool,
};

export default CardsBody;
