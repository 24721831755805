/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import { useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Slider from 'react-slick';
import { Container, Message } from 'semantic-ui-react';
import HeroCarouselBody from './HeroCarouselBody';
import { Icon } from '@package/icons';
import styles from './HeroCarouselView.module.less';

const messages = defineMessages({
  EmptyCarousel: {
    id: 'The carousel is empty',
    defaultMessage: 'The carousel is empty',
    // defaultMessage: 'O carrossel está vazio. Por favor, adicione algum item a ele usando a barra lateral'
  },
  YouCanAddItems: {
    id: 'You can add items using the sidebar',
    defaultMessage: 'You can add items using the sidebar',
    // defaultMessage: 'O carrossel está vazio. Por favor, adicione algum item a ele usando a barra lateral'
  },
});

const Arrow = ({ icon, className, style, onClick }) => {
  return (
    <button
      aria-hidden="true"
      onClick={(e) => {
        onClick(e);
      }}
      className={className}
      style={style}
    >
      <Icon icon={icon} />
    </button>
  );
};

const View = (props) => {
  const { block, data, isEditMode, openObjectBrowser, onChangeBlock } = props;
  const intl = useIntl();
  const sliderRef = useRef(null);

  const {
    heroSubtitle,
    heroTitle,
    columns,
    infinite,
    autoplay,
    autoplaySpeed,
  } = data;

  const stopAutoPlay = () => {
    if (sliderRef) {
      sliderRef?.current.slickPause();
    }
  };

  const rootClass = cx('full-width', 'block hero-carousel', styles.hero);

  return (
    <div className={rootClass}>
      <div className={styles.dec}>
        <Container>
          <div className={styles.align}>
            <div className={styles.wrapper}>
              {heroSubtitle ? (
                <h2 className={styles.subtitle}>{heroSubtitle}</h2>
              ) : null}
              {heroTitle ? (
                <div className={styles.title}>{heroTitle}</div>
              ) : null}
            </div>
          </div>
          <div
            className={cx('block hero-carousel', styles.slider)}
            onClick={() => {
              stopAutoPlay();
            }}
          >
            {(columns?.length === 0 || !columns) && isEditMode && (
              <Message>
                <div className="slide-content empty">
                  <div style={{ fontWeight: 600 }}>
                    {intl.formatMessage(messages.EmptyCarousel)}
                  </div>
                  <p>{intl.formatMessage(messages.YouCanAddItems)}</p>
                  <div className="imgs">
                    <span>
                      <Icon icon="Image" size={42} />
                    </span>
                    <span>
                      <Icon icon="Youtube" size={42} />
                    </span>
                    <span>
                      <Icon icon="FileText" size={42} />
                    </span>
                  </div>
                </div>
              </Message>
            )}
            <Slider
              dots
              speed={500}
              infinite={infinite}
              autoplay={autoplay}
              autoplaySpeed={
                parseInt(autoplaySpeed) ? parseInt(autoplaySpeed) * 1000 : null
              }
              pauseOnHover={true}
              pauseOnFocus={true}
              pauseOnDotsHover={true}
              nextArrow={<Arrow icon="ChevronRight" />}
              prevArrow={<Arrow icon="ChevronLeft" />}
              onSwipe={() => {
                stopAutoPlay();
              }}
              ref={sliderRef}
            >
              {columns &&
                columns.map((item, index) => {
                  const { href, url_video } = item;
                  if (!isEditMode && !url_video && !href) {
                    return null;
                  }

                  return (
                    <HeroCarouselBody
                      key={item['@id']}
                      data={item}
                      isEditMode={isEditMode}
                      dataBlock={data}
                      index={index}
                      block={block}
                      openObjectBrowser={openObjectBrowser}
                      onChangeBlock={onChangeBlock}
                    />
                  );
                })}
            </Slider>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default View;
