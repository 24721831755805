import { defineMessages } from 'react-intl';

export default defineMessages({
  menu_label: {
    id: 'Main navigation menu',
    defaultMessage: 'Main navigation menu',
  },
  close_label: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  open_search: {
    id: 'Open search',
    defaultMessage: 'Open search',
  },
  close_search: {
    id: 'Close search',
    defaultMessage: 'Close search',
  },
  delegates_area: {
    id: "Delegates' Area",
    defaultMessage: "Delegates' Area",
  },
  BackToHome: {
    id: 'Back to home page',
    defaultMessage: 'Back to home page',
  },
});
