import { injectIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';

const CookieBannerText = ({ intl }) => {
  if (intl.locale === 'en') {
    return (
      <>
        To improve your experience on the platform and provide personalized
        services, we use cookies.
        <strong>
          By accepting,you will have access to all the site's features. If you
          click on "Reject Cookies", cookies that are not strictly necessary
          will be disabled.
        </strong>
        To choose which ones you want to authorize, click on "Manage cookies".
        Find out more in our{' '}
        <UniversalLink href="/en/lgpd" openLinkInNewTab>
          Cookie Declaration
        </UniversalLink>
        .
      </>
    );
  }

  if (intl.locale === 'es') {
    return (
      <>
        Para mejorar su experiencia en la plataforma y brindarle servicios
        personalizados, utilizamos cookies.
        <strong>
          Al aceptar, tendrá acceso a todas las funciones del sitio. Si hace
          clic en "Rechazar Cookies" se desactivarán las cookies que no sean
          estrictamente necesarias.
        </strong>
        Para elegir cuáles desea autorizar haga clic en "Gestionar cookies".
        Obtenga más información en nuestra{' '}
        <UniversalLink href="/es/lgpd" openLinkInNewTab>
          Declaración de cookies
        </UniversalLink>
        .
      </>
    );
  }

  return (
    <>
      Para melhorar a sua experiência na plataforma e prover serviços
      personalizados, utilizamos cookies.
      <strong>
        Ao aceitar, você terá acesso a todas as funcionalidades do site. Se
        clicar em "Rejeitar Cookies", os cookies que não forem estritamente
        necessários serão desativados.
      </strong>
      Para escolher quais quer autorizar, clique em "Gerenciar cookies". Saiba
      mais em nossa{' '}
      <UniversalLink href="/pt-br/lgpd" openLinkInNewTab>
        Declaração de Cookies
      </UniversalLink>
      .
    </>
  );
};

export default injectIntl(CookieBannerText);
