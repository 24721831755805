import PropTypes from 'prop-types';
import { SidebarPortal } from '@plone/volto/components';
import EventsData from './EventsData';
import EventsBody from './EventsBody';

const Edit = (props) => {
  const { data, block, selected } = props;

  return (
    <>
      <EventsBody data={data} id={block} isEditMode />
      <SidebarPortal selected={selected}>
        <EventsData key={block} {...props} data={data} block={block} />
      </SidebarPortal>
    </>
  );
};

Edit.propTypes = {
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.bool,
};

export default Edit;
