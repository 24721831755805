/**
 * RelatedItems component.
 * @module components/theme/RelatedItems/RelatedItems
 */

import { UniversalLink, Image } from '@plone/volto/components';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { MoreInfo } from '@package/components';

import styles from './RelatedItems.module.less';

const messages = defineMessages({
  OtherNews: {
    id: 'Other news',
    defaultMessage: 'Other news',
  },
});

const RelatedItems = ({ relatedItems = [], intl }) => {
  const token = useSelector((state) => state.userSession.token, shallowEqual);

  const shownRelatedItems = relatedItems.filter(
    // Pode ser que o related item seja null. Então precisamos remover ele da lista.
    // Ver: https://github.com/plone/plone.restapi/issues/1745
    (item) => item && (!!token || item.review_state === 'published'),
  );

  if (!shownRelatedItems || !shownRelatedItems.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      <h2 className={cx('sectionSubtitle left', styles.other)}>
        {intl.formatMessage(messages.OtherNews)}
      </h2>
      <Grid>
        {shownRelatedItems.map((relatedItem) => {
          const {
            head_title,
            title,
            image_field,
            effective,
            review_state,
            created,
            description,
            '@id': url,
          } = relatedItem;

          return (
            <Grid.Column
              mobile={12}
              tablet={4}
              computer={12}
              className={styles.item}
              key={url}
            >
              <Image
                item={relatedItem}
                imageField={image_field}
                alt=""
                // loading="lazy"
                responsive={false}
              />
              {head_title ? (
                <div className={cx('sectionSubtitle left', styles.head)}>
                  {head_title}
                </div>
              ) : null}
              <h3 className={cx('sectionTitle left', styles[review_state])}>
                {title}
              </h3>
              {effective || created ? (
                <div className={styles.date}>
                  {moment(effective || created).format('LL')}
                </div>
              ) : null}
              {description ? (
                <div className={styles.desc}>{description}</div>
              ) : null}

              <UniversalLink className="more-link" item={relatedItem}>
                <MoreInfo title={title} />
              </UniversalLink>
            </Grid.Column>
          );
        })}
      </Grid>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
RelatedItems.propTypes = {
  relatedItems: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
RelatedItems.defaultProps = {
  RelatedItems: null,
};

export default injectIntl(RelatedItems);
