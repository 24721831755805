import { memo } from 'react';
const SvgVideoOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="video-off_svg__feather video-off_svg__feather-video-off"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10M1 1l22 22" />
  </svg>
);
const Memo = memo(SvgVideoOff);
export default Memo;
