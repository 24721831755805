const MAX_FLAGS_PER_LINE = { desktop: 7, tablet: 4, mobile: 3 };

const calcFlagNum = (numFlags = MAX_FLAGS_PER_LINE.desktop) => {
  const styles = {};

  Object.keys(MAX_FLAGS_PER_LINE).forEach((device) => {
    if (numFlags <= MAX_FLAGS_PER_LINE[device]) {
      // console.log(`${device}: ${MAX_FLAGS_PER_LINE[device]}`);
      styles[`--num-flags-${device}`] = MAX_FLAGS_PER_LINE[device];
    } else {
      let rows = 1;
      for (let i = rows; numFlags / rows > MAX_FLAGS_PER_LINE[device]; i++) {
        rows = i;
      }

      // console.log(`${device}: ${Math.ceil( numFlags / rows)}`);
      styles[`--num-flags-${device}`] = Math.ceil(numFlags / rows);
    }
  });

  return styles;
};

export default calcFlagNum;
