import PropTypes from 'prop-types';
import itemsShape from '../../itemsShape';
import GroupHeader from '../GroupHeader';
import Page from '../Page';

import styles from './Group.module.less';

const Group = (props) => {
  const {
    title,
    description,
    logo,
    items,
    renderLink,
    collapsible,
    onNavigate,
    active,
    onGroupSelect,
    lang,
  } = props;

  return (
    <div className={active ? styles.active : styles.group}>
      <GroupHeader
        title={title}
        description={description}
        logo={logo}
        collapsible={collapsible}
        active={active}
        onClick={onGroupSelect}
      />

      <div className={active ? styles.on : styles.off}>
        <Page
          items={items}
          renderLink={renderLink}
          onNavigate={onNavigate}
          lang={lang}
        />
      </div>
    </div>
  );
};

// @id: "http://localhost:3000"
// description: ""
// items: []
// review_state: null
// title: "Home"

Group.displayName = 'Group';

Group.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.bool,
  items: itemsShape.items,
  renderLink: PropTypes.func,
  onGroupSelect: PropTypes.func,
  onNavigate: PropTypes.func,
  collapsible: PropTypes.bool,
  active: PropTypes.bool,
};

Group.defaultProps = {
  collapsible: false,
  active: false,
};

export default Group;
