import { memo } from 'react';
const SvgFolderPlus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="folder-plus_svg__feather folder-plus_svg__feather-folder-plus"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2zM12 11v6M9 14h6" />
  </svg>
);
const Memo = memo(SvgFolderPlus);
export default Memo;
