/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogoG20, SocialLinks } from '@package/components';
import { footerMessages, siteMessages } from '@package/messages';
import { ManageCookiesButton } from 'lgpd.plugin/components';
import { injectIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { toBackendLang } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

import styles from './Footer.module.less';
import { getG20FooterLinks } from '@package/actions';

const FooterLinks = ({ links, lang, className }) => {
  if (!links?.length) return null;

  return (
    <ul className={className}>
      {links.map((link, id) => {
        const { [`url_${lang}`]: url, [`label_${lang}`]: label } = link;

        if (!url || !label) return null;

        return (
          <li key={id}>
            <UniversalLink href={url}>{label}</UniversalLink>
          </li>
        );
      })}
    </ul>
  );
};

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { locale } = intl;
  const { settings } = config;
  const lang = toBackendLang(locale);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getG20FooterLinks());
  }, [dispatch]);

  const result = useSelector((state) => {
    return state.g20FooterLinks;
  });

  return (
    <footer className={styles.footer}>
      <Container className={styles.flex}>
        <div className={styles.logo}>
          <UniversalLink
            href={settings.isMultilingual ? `/${lang}` : '/'}
            title={intl.formatMessage(siteMessages.siteName)}
          >
            <LogoG20
              full
              invert-text
              title={intl.formatMessage(footerMessages.BackToHome)}
              titleId="logo-footer"
            />
          </UniversalLink>
        </div>
        <div>
          <div className={styles.top}>
            <SocialLinks className={styles.social} />
            <ManageCookiesButton
              label={intl.formatMessage(footerMessages.manageCookies)}
              className="manage"
            />
          </div>

          <FooterLinks
            links={result.data.top}
            lang={lang}
            className={styles.menu}
          />
          <FooterLinks
            links={result.data.bottom}
            lang={lang}
            className={styles.submenu}
          />

          <div className={styles.horizontal}>
            {intl.formatMessage(footerMessages.copyright)}
          </div>
        </div>
      </Container>
    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
