import { Icon as VoltoIcon } from '@plone/volto/components';
import { Icon } from '@package/icons';

import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import checkSVG from '@plone/volto/icons/check.svg';

import { defineMessages } from 'react-intl';

import * as featherIcons from '@package/icons/assets';

const messages = defineMessages({
  indexNum: { id: 'Index {id}', defaultMessage: 'Index {id}' },
  value: { id: 'Value', defaultMessage: 'Value' },
  description: { id: 'Description', defaultMessage: 'Description' },
  category: { id: 'Category', defaultMessage: 'Category' },
  icon: { id: 'Icon', defaultMessage: 'Icon' },
  typeToSearch: {
    id: 'Type to search…',
    defaultMessage: 'Type to search…',
  },
  iconStyle: { id: 'Icon style', defaultMessage: 'Icon style' },
  numbers: { id: 'Numbers', defaultMessage: 'Numbers' },
  lightBlueDarkBlue: {
    id: 'Light blue / Dark blue',
    defaultMessage: 'Light blue / Dark blue',
  },
  yellowDarkBlue: {
    id: 'Yellow / Dark blue',
    defaultMessage: 'Yellow / Dark blue',
  },
  greenDarkBlue: {
    id: 'Green / Dark blue',
    defaultMessage: 'Green / Dark blue',
  },
  darkBlueWhite: {
    id: 'Dark blue / White',
    defaultMessage: 'Dark blue / White',
  },
  redWhite: { id: 'Red / White', defaultMessage: 'Red / White' },
  transparentDarkBlue: {
    id: 'Transparent / Dark blue',
    defaultMessage: 'Transparent / Dark blue',
  },
  transparentLightBlue: {
    id: 'Transparent / Light blue',
    defaultMessage: 'Transparent / Light blue',
  },
});

const colors = (intl) => [
  {
    name: 'icon-light-blue-dark-blue',
    label: intl.formatMessage(messages.lightBlueDarkBlue),
  },
  {
    name: 'icon-yellow-dark-blue',
    label: intl.formatMessage(messages.yellowDarkBlue),
  },
  {
    name: 'icon-green-dark-blue',
    label: intl.formatMessage(messages.greenDarkBlue),
  },
  {
    name: 'icon-dark-blue-white',
    label: intl.formatMessage(messages.darkBlueWhite),
  },
  { name: 'icon-red-white', label: intl.formatMessage(messages.redWhite) },
  {
    name: 'icon-transparent-dark-blue',
    label: intl.formatMessage(messages.transparentDarkBlue),
  },
  {
    name: 'icon-transparent-light-blue',
    label: intl.formatMessage(messages.transparentLightBlue),
  },
];

const IconItem = injectLazyLibs('reactSelect')((props) => {
  const { Option } = props.reactSelect.components;

  return (
    <Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon icon={props.value} />
        <span style={{ marginLeft: 8 }}>{props.label}</span>
      </div>
      {props.isFocused && !props.isSelected && (
        <VoltoIcon name={checkSVG} size="24px" color="#b8c6c8" />
      )}
      {props.isSelected && (
        <VoltoIcon name={checkSVG} size="24px" color="#007bc1" />
      )}
    </Option>
  );
});

const indexProperties = (id, intl) => {
  return {
    [`value${id}`]: {
      title: intl.formatMessage(messages.value),
      // description: intl.formatMessage(messages.indexValueDescription),
    },
    [`description${id}`]: {
      title: intl.formatMessage(messages.description),
    },
    [`category${id}`]: {
      title: intl.formatMessage(messages.category),
    },
    [`icon${id}`]: {
      title: intl.formatMessage(messages.icon),
      placeholder: intl.formatMessage(messages.typeToSearch),
      noValueOption: false,
      isMulti: false,
      choices: Object.keys(featherIcons).map((icon) => [icon, icon]),
      customOptionStyling: IconItem,
    },
    [`style${id}`]: {
      title: intl.formatMessage(messages.iconStyle),
      widget: 'color_picker',
      colors: colors(intl),
    },
  };
};

const indexFieldset = (id, intl) => {
  return {
    id: `index${id}`,
    title: intl.formatMessage(messages.indexNum, { id }),
    fields: [
      `value${id}`,
      `category${id}`,
      `description${id}`,
      `icon${id}`,
      `style${id}`,
    ],
  };
};

export const numbersSchema = ({ intl }) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.numbers),
        fields: [],
      },
      indexFieldset(1, intl),
      indexFieldset(2, intl),
      indexFieldset(3, intl),
    ],
    properties: {
      ...indexProperties(1, intl),
      ...indexProperties(2, intl),
      ...indexProperties(3, intl),
    },
    required: [],
  };
};
