import { defineMessages } from 'react-intl';
import dividerHorizontalSVG from '@plone/volto/icons/divide-horizontal.svg';
import DividerView from './DividerView';
import DividerEdit from './DividerEdit';

defineMessages({
  divider: {
    id: 'Divider',
    defaultMessage: 'Divider',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.dividerLine = {
    id: 'dividerLine',
    title: 'Divider',
    group: 'layout',
    icon: dividerHorizontalSVG,
    view: DividerView,
    edit: DividerEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
