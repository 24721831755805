export const CLOSE_MANAGE_COOKIES = 'CLOSE_MANAGE_COOKIES';
export const OPEN_MANAGE_COOKIES = 'OPEN_MANAGE_COOKIES';

export function openManageCookies() {
  return {
    type: OPEN_MANAGE_COOKIES,
  };
}

export function closeManageCookies() {
  return {
    type: CLOSE_MANAGE_COOKIES,
  };
}
