import _ from 'lodash';
import cx from 'classnames';
import styles from './TrackTag.module.less';

const TrackTag = ({ track, className }) => {
  if (!track) return null;

  return (
    <div
      key={track}
      className={cx(styles[_.camelCase(track)], {
        [className]: className,
      })}
    >
      {track}
    </div>
  );
};

export default TrackTag;
