import { parse } from 'node-html-parser';
import { GROUP_DOMAINS } from '../constants/Lgpd';

const hidenSrc = (cookie, key, src) => {
  if (GROUP_DOMAINS['noCookies'].some((v) => src.indexOf(v) >= 0)) {
    return false;
  }
  let hiden = true;
  // Se o usuário aceitou o cookie e o dominio está na lista branca,
  // não demevemos esconder o src
  if (cookie && GROUP_DOMAINS[key].some((v) => src.indexOf(v) >= 0)) {
    hiden = false;
  }
  return hiden;
};

export const srcAllowed = (src, performanceCookies, thirdPartyCookies) => {
  let hidenSrcPerformance = true;
  const hidenSrcThirdParty = hidenSrc(thirdPartyCookies, 'thirdParty', src);
  if (!hidenSrcThirdParty) {
    hidenSrcPerformance = hidenSrc(performanceCookies, 'performance', src);
  }
  if (hidenSrcThirdParty && hidenSrcPerformance) {
    return false;
  }
  return true;
};

export const blockHTML = (html, performanceCookies, thirdPartyCookies) => {
  let blocked = false;
  if (!html.includes('<iframe')) {
    return { html: html, blocked: blocked };
  }
  const root = parse(html);
  const iframes = root.getElementsByTagName('iframe');
  iframes.forEach((element) => {
    const src = element.getAttribute('src');
    if (!srcAllowed(src, performanceCookies, thirdPartyCookies)) {
      element.removeAttribute('src');
      element.setAttribute('data-src', src);
      blocked = true;
    }
  });
  return { html: root.toString(), blocked: blocked };
};
