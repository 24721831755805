import { defineMessages } from 'react-intl';

export default defineMessages({
  Accordion: { id: 'Accordion', defaultMessage: 'Accordion' },
  AccordionBlock: { id: 'Accordion block', defaultMessage: 'Accordion block' },
  AccordionTitle: { id: 'Accordion title', defaultMessage: 'Accordion title' },
  firstOpen: {
    id: 'First panel opened on start',
    defaultMessage: 'First panel opened on start',
  },
  Default: { id: 'Default', defaultMessage: 'Default' },
  friendly_name: { id: 'Friendly name', defaultMessage: 'Friendly name' },
  non_exclusive_description: {
    id: 'Allow multiple panels open at a time',
    defaultMessage: 'Allow multiple panels open at a time',
  },
  exclusive: {
    id: 'Open one panel at a time',
    defaultMessage: 'Open one panel at a time',
  },
  Options: { id: 'Options', defaultMessage: 'Options' },
  left_arrows: {
    id: 'Title Icon on the left',
    defaultMessage: 'Title Icon on the left',
  },
  TextSubtitle: {
    id: 'Subtitle accordion',
    defaultMessage: 'Subtitle accordion',
  },
  TextTitle: { id: 'Title accordion', defaultMessage: 'Title accordion' },
  Title: { id: 'Title', defaultMessage: 'Title' },
});
