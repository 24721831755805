import { memo } from 'react';
const SvgEdit2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="edit-2_svg__feather edit-2_svg__feather-edit-2"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
  </svg>
);
const Memo = memo(SvgEdit2);
export default Memo;
