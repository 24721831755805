import { defineMessages } from 'react-intl';

const messages = defineMessages({
  countriesList: { id: 'Countries-list', defaultMessage: 'Countries-list' },
  title: { id: 'Title', defaultMessage: 'Title' },
  subtitle: { id: 'SubTitle', defaultMessage: 'SubTitle' },
  countries: { id: 'Countries', defaultMessage: 'Countries' },
  members: { id: 'Members', defaultMessage: 'Members' },
  invitees: { id: 'Invitees', defaultMessage: 'Invitees' },
  orgs: {
    id: 'International organizations',
    defaultMessage: 'International organizations',
  },
  buttonLabel: { id: 'Button Label', defaultMessage: 'Button Label' },
  linkUrl: { id: 'Link URL', defaultMessage: 'Link URL' },
  addLinks: {
    id: 'Add links',
    defaultMessage: 'Add links',
  },
  footer: { id: 'Footer', defaultMessage: 'Footer' },
  decoration: { id: 'Decoration', defaultMessage: 'Decoration' },
  decorationLeft: { id: 'Left decoration', defaultMessage: 'Left decoration' },
  decorationRight: {
    id: 'Right decoration',
    defaultMessage: 'Right decoration',
  },
});

export const countriesListSchema = ({ intl }) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.countriesList),
        fields: ['subtitle', 'title', 'countries', 'addLinks'],
      },
      {
        id: 'footer',
        title: intl.formatMessage(messages.footer),
        fields: ['buttonLabel', 'buttonHref'],
      },
      {
        id: 'decoration',
        title: intl.formatMessage(messages.decoration),
        fields: ['decorationLeft', 'decorationRight'],
      },
    ],
    properties: {
      title: { title: intl.formatMessage(messages.title) },
      subtitle: { title: intl.formatMessage(messages.subtitle) },
      countries: {
        title: intl.formatMessage(messages.countries),
        required: false,
        noValueOption: false,
        default: 'membros',
        type: 'string',
        factory: 'Choice',
        choices: [
          ['membros', intl.formatMessage(messages.members)],
          ['convidados', intl.formatMessage(messages.invitees)],
          ['organizacoes', intl.formatMessage(messages.orgs)],
        ],
      },
      decorationLeft: {
        title: intl.formatMessage(messages.decorationLeft),
        type: 'boolean',
        default: false,
      },
      decorationRight: {
        title: intl.formatMessage(messages.decorationRight),
        type: 'boolean',
        default: false,
      },
      addLinks: {
        title: intl.formatMessage(messages.addLinks),
        type: 'boolean',
        default: true,
      },
      buttonLabel: { title: intl.formatMessage(messages.buttonLabel) },
      buttonHref: {
        title: intl.formatMessage(messages.linkUrl),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title'],
        allowExternals: true,
      },
    },
    required: [],
  };
};
