import { defineMessages } from 'react-intl';
import textCenteredSVG from '@plone/volto/icons/align-center.svg';
import textLeftSVG from '@plone/volto/icons/align-left.svg';
import textRightSVG from '@plone/volto/icons/align-right.svg';

const messages = defineMessages({
  title: { id: 'Title', defaultMessage: 'Title' },
  carousel: { id: 'Carousel', defaultMessage: 'Carousel' },
  Source: { id: 'Source', defaultMessage: 'Source' },
  VideoURL: { id: 'Video URL', defaultMessage: 'Video URL' },
  description: { id: 'Description', defaultMessage: 'Description' },
  descriptionHint: {
    id: 'Displayed on mobile only if title is empty',
    defaultMessage: 'Displayed on mobile only if title is empty',
  },
  imageOverride: { id: 'Image override', defaultMessage: 'Image override' },
  Carousel: { id: 'Carousel', defaultMessage: 'Carousel' },
  Settings: { id: 'Settings', defaultMessage: 'Settings' },
  Video: { id: 'Video', defaultMessage: 'Video' },
  Image: { id: 'Image', defaultMessage: 'Image' },
  item: { id: 'Item', defaultMessage: 'Item' },
  items: { id: 'Items', defaultMessage: 'Items' },
  addItem: { id: 'Add item', defaultMessage: 'Add item' },
  headline: { id: 'Headline', defaultMessage: 'Headline' },
  itemsToShow: { id: 'Items to show', defaultMessage: 'Items to show' },
  hideDescription: {
    id: 'Hide description',
    defaultMessage: 'Hide description',
  },
  removeImageFirst: {
    id: 'This item is already being used with an image. Clear all the other fields before entering a video',
    defaultMessage:
      'This item is already being used with an image. Clear all the other fields before entering a video',
  },
  removeVideoFirst: {
    id: 'This item is already being used with a video. Remove it first before entering other content',
    defaultMessage:
      'This item is already being used with a video. Remove it first before entering other content',
  },
  playAlign: { id: 'Play button align', defaultMessage: 'Play button align' },
  left: { id: 'Left', defaultMessage: 'Left' },
  center: { id: 'Center', defaultMessage: 'Center' },
  right: { id: 'Right', defaultMessage: 'Right' },
  OnlyPublishedContentsAllowed: {
    id: 'Only published contents are allowed',
    defaultMessage: 'Only published contents are allowed',
  },
  Preview_image: {
    id: 'Preview Image URL',
    defaultMessage: 'Preview Image URL',
  },
  infinite: { id: 'Infinite', defaultMessage: 'Infinite' },
  infiniteDesc: {
    id: 'Slide back to start after last item',
    defaultMessage: 'Slide back to start after last item',
  },
  autoplay: { id: 'Autoplay', defaultMessage: 'Autoplay' },
  autoplayOn: { id: 'Enable', defaultMessage: 'Enable' },
  autoplaySpeed: { id: 'Speed', defaultMessage: 'Speed' },
  autoplaySpeedDesc: {
    id: 'Time between items, in seconds',
    defaultMessage: 'Time between items, in seconds',
  },
  layout: { id: 'Layout', defaultMessage: 'Layout' },
  news: { id: 'News', defaultMessage: 'News' },
  gallery: { id: 'Image gallery', defaultMessage: 'Image gallery' },
  // size: { id: 'Size', defaultMessage: 'Size' },
  // small: { id: 'Small', defaultMessage: 'Small' },
  // medium: { id: 'Medium', defaultMessage: 'Medium' },
  // large: { id: 'Large', defaultMessage: 'Large' },
  aspectRatioMobile: {
    id: 'Aspect ratio - Mobile',
    defaultMessage: 'Aspect ratio - Mobile',
  },
  aspectRatioDesktop: {
    id: 'Aspect ratio - Desktop',
    defaultMessage: 'Aspect ratio - Desktop',
  },
});

const schemaExtender = (schema, data, intl) => {
  return schema({ intl, data });
};

const itemSchema = (props) => {
  const { intl, data = {} } = props;

  const { href, title, description, url_video } = data;

  const hasVideo = !!url_video;
  const hasImage = href?.length > 0 || !!title || !!description;

  const videoFieldset = {
    id: 'video',
    title: intl.formatMessage(messages.Video),
    fields: ['url_video', 'playAlign', 'preview_image'],
  };
  const imageFieldset = {
    id: 'image',
    title: intl.formatMessage(messages.Image),
    fields: ['href', 'title', 'description'],
  };

  return {
    title: intl.formatMessage(messages.item),
    addMessage: intl.formatMessage(messages.addItem),
    fieldsets: [imageFieldset, videoFieldset],
    properties: {
      href: {
        title: intl.formatMessage(messages.Source),
        widget: hasVideo ? null : 'object_browser',
        mode: 'link',
        selectedItemAttrs: [
          'Title',
          'Description',
          'image_field',
          'review_state',
        ],
        allowExternals: true,
        isDisabled: hasVideo,
        description: hasVideo
          ? intl.formatMessage(messages.removeVideoFirst)
          : intl.formatMessage(messages.OnlyPublishedContentsAllowed),
      },
      title: {
        title: intl.formatMessage(messages.title),
        isDisabled: hasVideo,
      },
      description: {
        title: intl.formatMessage(messages.description),
        description: intl.formatMessage(messages.descriptionHint),
        isDisabled: hasVideo,
      },
      url_video: {
        title: props.intl.formatMessage(messages.VideoURL),
        // widget: hasImage ?  null : 'url' ,
        isDisabled: hasImage,
        description: hasImage
          ? intl.formatMessage(messages.removeImageFirst)
          : null,
      },
      playAlign: {
        title: intl.formatMessage(messages.playAlign),
        required: false,
        default: 'center',
        type: 'string',
        isDisabled: hasImage,
        widget: 'buttons',
        actions: ['left', 'center', 'right'],
        actionsInfoMap: {
          left: [textLeftSVG, intl.formatMessage(messages.left)],
          center: [textCenteredSVG, intl.formatMessage(messages.center)],
          right: [textRightSVG, intl.formatMessage(messages.right)],
        },
      },
      preview_image: {
        title: props.intl.formatMessage(messages.Preview_image),
        widget: hasImage ? 'text' : 'url',
        isDisabled: hasImage,
      },
    },
    required: [],
  };
};

export const carouselSchema = (props) => {
  const { intl, data = {} } = props;

  const aspectRatioChoices = [
    ['1', '1/1'],
    ['1.5', '3/2'],
    ['1.3333', '4/3'],
    ['1.7778', '16/9'],
    ['2', '2/1'],
    ['3', '3/1'],
  ];

  return {
    title: intl.formatMessage(messages.carousel),
    block: 'carousel',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['columns'],
      },
      {
        id: 'settings',
        title: intl.formatMessage(messages.Settings),
        fields: [
          'layout',
          'aspectRatioDesktop',
          'aspectRatioMobile',
          'infinite',
          'autoplay',
          'autoplaySpeed',
        ],
      },
    ],
    properties: {
      columns: {
        widget: 'object_list',
        title: intl.formatMessage(messages.items),
        schema: itemSchema,
        schemaExtender,
      },
      infinite: {
        title: intl.formatMessage(messages.infinite),
        description: intl.formatMessage(messages.infiniteDesc),
        type: 'boolean',
      },
      autoplay: {
        title: intl.formatMessage(messages.autoplay),
        type: 'boolean',
      },
      autoplaySpeed: {
        title: intl.formatMessage(messages.autoplaySpeed),
        description: intl.formatMessage(messages.autoplaySpeedDesc),
        type: 'number',
        default: 6,
        isDisabled: !data.autoplay,
      },
      layout: {
        title: intl.formatMessage(messages.layout),
        required: true,
        noValueOption: true,
        default: 'news',
        type: 'string',
        factory: 'Choice',
        choices: [
          ['news', intl.formatMessage(messages.news)],
          ['gallery', intl.formatMessage(messages.gallery)],
        ],
      },
      aspectRatioDesktop: {
        title: intl.formatMessage(messages.aspectRatioDesktop),
        required: true,
        noValueOption: true,
        default: '1.7778',
        type: 'string',
        factory: 'Choice',
        choices: aspectRatioChoices,
      },
      aspectRatioMobile: {
        title: intl.formatMessage(messages.aspectRatioMobile),
        required: true,
        noValueOption: true,
        default: '1.5',
        type: 'string',
        factory: 'Choice',
        choices: aspectRatioChoices,
      },
    },
    required: [],
  };
};
