import { defineMessages } from 'react-intl';

export default defineMessages({
  verticalSpacing: {
    id: 'Vertical spacing',
    defaultMessage: 'Vertical spacing',
  },
  none: {
    id: 'None [male]',
    defaultMessage: 'None',
  },
  small: {
    id: 'Small [male]',
    defaultMessage: 'Small',
  },
  medium: {
    id: 'Medium [male]',
    defaultMessage: 'Medium',
  },
  large: {
    id: 'Large',
    defaultMessage: 'Large',
  },
  invertOnMobileTitle: {
    id: 'Reverse on mobile',
    defaultMessage: 'Reverse on mobile',
  },
  invertOnMobileDescription: {
    id: 'Reverse column order when opening site on mobile',
    defaultMessage: 'Reverse column order when opening site on mobile',
  },
});
