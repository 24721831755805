import { memo } from 'react';
const SvgAmbulancia = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M24 15.062a5.97 5.97 0 0 0-.517-2.438l-2.777-6.249A4.007 4.007 0 0 0 17.05 4H14L13.243.97a1.281 1.281 0 0 0-2.486 0L10 4H3a3 3 0 0 0-3 3v13h2.041a3.465 3.465 0 0 0-.041.5 3.5 3.5 0 0 0 7 0 3.465 3.465 0 0 0-.041-.5h6.082a3.465 3.465 0 0 0-.041.5 3.5 3.5 0 0 0 7 0 3.465 3.465 0 0 0-.041-.5H24Zm-5.122-7.874L20.572 11H16V6h1.05a2 2 0 0 1 1.828 1.188ZM7 20.5a1.5 1.5 0 0 1-3 0 1.418 1.418 0 0 1 .093-.5h2.814a1.418 1.418 0 0 1 .093.5Zm13 0a1.5 1.5 0 0 1-3 0 1.418 1.418 0 0 1 .093-.5h2.814a1.418 1.418 0 0 1 .093.5Zm2-2.5H2V7a1 1 0 0 1 1-1h11v7h7.461l.194.437A3.969 3.969 0 0 1 22 15.062ZM9 11h2v2H9v2H7v-2H5v-2h2V9h2Z" />
  </svg>
);
const Memo = memo(SvgAmbulancia);
export default Memo;
