import PropTypes from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { HtmlHeader, SocialLinks, IconButton } from '@package/components';
import { headerMessages } from '@package/messages';
import {
  LanguageSelector,
  Logo,
  Navigation,
  SearchWidget,
} from '@plone/volto/components';

import styles from './Header.module.less';

const Header = ({ pathname }) => {
  const intl = useIntl();
  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <>
      <HtmlHeader />

      <header className={styles.header}>
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <Logo
              invert-text
              title={intl.formatMessage(headerMessages.BackToHome)}
              titleId="logo-header"
            />
          </div>
          <div className={styles.inner}>
            <div className={styles.tools}>
              <SocialLinks className={styles.social} />
              <div className={styles.right}>
                <IconButton
                  icon={searchOpen ? 'Close' : 'Search'}
                  border
                  aria-label="Open search"
                  className={searchOpen ? 'close-button' : 'search-button'}
                  onClick={() => setSearchOpen(!searchOpen)}
                />
                {searchOpen ? (
                  <div className={styles.search}>
                    <SearchWidget />
                  </div>
                ) : null}
                <a href="https://g20.org/accreditation" className={styles.dash}>
                  {intl.formatMessage(headerMessages.delegates_area)}
                </a>
                <LanguageSelector />
              </div>
            </div>

            <Navigation pathname={pathname} />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
  token: null,
  content: null,
};
