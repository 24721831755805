import { defineMessages } from 'react-intl';

export default defineMessages({
  manageCookies: {
    id: 'Manage cookies',
    defaultMessage: 'Manage cookies',
  },
  BackToHome: {
    id: 'Back to home page',
    defaultMessage: 'Back to home page',
  },
  Policy: {
    id: 'Copyright policy',
    defaultMessage: 'Copyright policy',
  },
  ManageCookies: {
    id: 'Manage cookies',
    defaultMessage: 'Manage cookies',
  },
  copyright: {
    id: 'G20 Brazil 2024 Content developed by the Ministry of Foreign Affairs and the Secretariat of Social Communication of the Presidency of the Republic of Brazil',
    defaultMessage:
      'G20 Brazil 2024 Content developed by the Ministry of Foreign Affairs and the Secretariat of Social Communication of the Presidency of the Republic of Brazil',
  },
});
