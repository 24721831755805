import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { accessibleOnClick } from '@package/helpers';
import { MenuLink } from '@package/components';
import { Icon } from '@package/icons';

import styles from './Page.module.less';

const messages = defineMessages({
  GoBack: { id: 'GoBack', defaultMessage: 'Back' },
});

const BackButton = ({
  onBackClick,
  // title
}) => {
  const intl = useIntl();

  return (
    <li
      className={styles.back}
      // onClick={onBackClick}
    >
      <div {...accessibleOnClick(onBackClick)}>
        <span className={styles['arrow-back']}>
          <Icon icon="ChevronLeft" size="20px" />
        </span>
        {intl.formatMessage(messages.GoBack)}
      </div>
    </li>
  );
};

BackButton.propTypes = {
  onBackClick: PropTypes.func,
  // title: PropTypes.string,
};

const ParentItem = ({ title, url, onNavigate, lang }) => (
  <li className={styles.parentitem}>
    <MenuLink
      href={url}
      className={styles.link}
      onClick={onNavigate}
      lang={lang}
    >
      {title}
    </MenuLink>
  </li>
);

ParentItem.propTypes = {
  title: PropTypes.string,
};

const Item = ({ item, onSelect, onBack, onNavigate, lang }) => {
  const [active, setActive] = useState(false);
  const [childActive, setChildActive] = useState(false);
  const { title, url, items } = item;
  const hasItems = items && items.length;

  const onItemClick = () => {
    setActive(true);
    onSelect();
  };

  const onBackClick = () => {
    setActive(false);
    onBack();
  };

  const onChildStateChange = () => {
    setActive(false);
    setChildActive(true);
    onSelect();
  };

  const onChildBack = () => {
    setActive(true);
    setChildActive(false);
  };

  const childrenClass = cx({
    [styles.on]: active,
    [styles.prev]: childActive,
    [styles.off]: !active && !childActive,
  });

  return (
    <li className={hasItems ? styles.sub : styles.leaf}>
      <MenuLink
        href={url}
        className={styles.link}
        onClick={onNavigate}
        lang={lang}
      >
        {title}
      </MenuLink>

      {!!hasItems && (
        <>
          <span className={`br-divider vertical ${styles.div}`} />
          <button
            className={`br-button circle mx-1 ${styles.arrow}`}
            onClick={hasItems ? onItemClick : null}
          >
            <Icon icon="ChevronRight" size="25px" />
            <span className="sr-only">Abrir {title}</span>
          </button>
        </>
      )}

      {!!hasItems && (
        <div className={childrenClass}>
          <ul>
            <BackButton title={title} onBackClick={onBackClick} />
            <ParentItem
              title={title}
              url={url}
              onNavigate={onNavigate}
              lang={lang}
            />
            {items.map((item) => (
              <Item
                item={item}
                key={item.title}
                onSelect={onChildStateChange}
                onBack={onChildBack}
                onNavigate={onNavigate}
                lang={lang}
              />
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  onBack: PropTypes.func,
  onNavigate: PropTypes.func,
};

const Root = ({ items, onNavigate, lang }) => {
  const [active, setActive] = useState(true);

  if (!items || !items.length) {
    return null;
  }

  const onChildSelect = () => {
    setActive(false);
  };

  const onChildBack = () => {
    setActive(true);
  };

  return (
    <div className={active ? styles.rooton : styles.rootprev}>
      <ul>
        {items.map((item) => (
          <Item
            item={item}
            key={item.title}
            onSelect={onChildSelect}
            onBack={onChildBack}
            onNavigate={onNavigate}
            lang={lang}
          />
        ))}
      </ul>
    </div>
  );
};

Root.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onNavigate: PropTypes.func,
};

const Page = ({ items, onNavigate, lang }) => {
  return (
    <div className={styles.page}>
      <Root items={items} onNavigate={onNavigate} lang={lang} />
    </div>
  );
};

Page.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onNavigate: PropTypes.func,
};

export default Page;
