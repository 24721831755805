import { memo } from 'react';
const SvgFlickr = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.5 2C21.984 2 24 4.016 24 6.5v15c0 2.484-2.016 4.5-4.5 4.5h-15A4.502 4.502 0 0 1 0 21.5v-15C0 4.016 2.016 2 4.5 2h15zm-8.594 12a3.314 3.314 0 1 0-3.313 3.313A3.314 3.314 0 0 0 10.906 14zm8.813 0a3.314 3.314 0 1 0-3.313 3.313A3.314 3.314 0 0 0 19.719 14z"
    />
  </svg>
);
const Memo = memo(SvgFlickr);
export default Memo;
