/**
 * RequestTimeout.
 * @module components/theme/RequestTimeout/RequestTimeout
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from 'semantic-ui-react';
import config from '@plone/volto/registry';

/**
 * @function RequestTimeout
 * @returns {string} Markup of the not found page.
 */
const RequestTimeout = () => (
  <Container className="view-wrapper error-page">
    {' '}
    {__DEVELOPMENT__ && (
      <>
        <h1 style={{ textAlign: 'center', lineHeight: '40px' }}>
          <FormattedMessage
            id="No connection to the server"
            defaultMessage="There is no connection to the server, due to a timeout o no network connection."
          />
          <br />
          <a href={config.settings.apiPath}>{config.settings.apiPath}</a>
        </h1>
        <p className="description">
          <FormattedMessage
            id="The backend is not responding, due to a server timeout or a connection problem of your device. Please check your connection and try again."
            defaultMessage="The backend is not responding, due to a server timeout or a connection problem of your device. Please check your connection and try again."
          />
        </p>
      </>
    )}
    {!__DEVELOPMENT__ && (
      <>
        <p className="description">
          <FormattedMessage
            id="The backend is not responding, due to a server timeout or a connection problem of your device. Please check your connection and try again."
            defaultMessage="The backend is not responding, due to a server timeout or a connection problem of your device. Please check your connection and try again."
          />
        </p>

        <p style={{ textAlign: 'center' }}>
          <FormattedMessage id="Thank you." defaultMessage="Thank you." />
        </p>
      </>
    )}
  </Container>
);

export default RequestTimeout;
