/**
 * Cookie banner text reducer.
 * @module reducers/cookieBannerReducer
 */

import { OPEN_MANAGE_COOKIES, CLOSE_MANAGE_COOKIES } from '../actions';

const initialState = {
  modalOpen: false,
};

export const manageCookies = (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_MANAGE_COOKIES:
      return {
        ...state,
        modalOpen: true,
      };

    case CLOSE_MANAGE_COOKIES:
      return {
        ...state,
        modalOpen: false,
      };

    default:
      return state;
  }
};
