import React from 'react';
import { ConditionalLink, MaybeWrap } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import moment from 'moment';
import PropTypes from 'prop-types';

import styles from './GroupedByDate.module.less';

const GroupedByDateTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  let currDate;

  return (
    <>
      <div>
        {items
          // .sort(
          //   (a, b) =>
          //     moment(b.effective).diff(a.effective, 'day')
          // )
          .map((item) => {
            const {
              '@id': url,
              // '@type': type,
              id,
              title,
              description,
              review_state,
              effective,
              created,
            } = item;

            const itemDate = moment(
              review_state === 'published' ? effective : created,
            ).format('LL');
            const showDate = itemDate !== currDate;
            currDate = itemDate;

            return (
              <React.Fragment key={item.id}>
                {showDate ? <h2 className={styles.date}>{itemDate}</h2> : null}
                <div key={url} className={styles[review_state || 'published']}>
                  <div className={styles.title}>
                    <MaybeWrap condition={isEditMode} as="span" key={url}>
                      <ConditionalLink
                        item={item}
                        condition={!isEditMode}
                        key={url}
                      >
                        {title ? title : id}
                      </ConditionalLink>
                    </MaybeWrap>
                  </div>

                  {description ? (
                    <div className={styles.desc}>{description}</div>
                  ) : null}
                </div>
              </React.Fragment>
            );
          })}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

GroupedByDateTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default GroupedByDateTemplate;
