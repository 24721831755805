import { memo } from 'react';
const SvgLinkedin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="M27.263 27.269h-4.739v-7.425c0-1.771-.036-4.049-2.469-4.049-2.471 0-2.848 1.927-2.848 3.919v7.556h-4.739V12.001h4.552v2.081h.061c.636-1.2 2.183-2.467 4.493-2.467 4.801 0 5.689 3.16 5.689 7.273zM7.116 9.911a2.75 2.75 0 0 1-2.751-2.753 2.752 2.752 0 1 1 2.751 2.753zm2.376 17.358H4.74V12h4.752zM29.633 0H2.361C1.056 0 0 1.032 0 2.305v27.389c0 1.275 1.056 2.305 2.361 2.305h27.268c1.304 0 2.371-1.031 2.371-2.305V2.305C32 1.032 30.933 0 29.629 0z"
    />
  </svg>
);
const Memo = memo(SvgLinkedin);
export default Memo;
