import TagManager from '@sooro-io/react-gtm-module';

if (__CLIENT__) {
  const gtmId = window?.env?.RAZZLE_GTM_CODE || process.env.RAZZLE_GTM_CODE;

  if (gtmId) {
    const tagManagerArgs = {
      gtmId,
    };

    TagManager.initialize(tagManagerArgs);
  }
}

const GTMVolto = () => {
  return null;

  //   return (
  //     <div
  //       style={{
  //         position: 'absolute',
  //         top: 0,
  //         left: 0,
  //         padding: 8,
  //         background: 'red',
  //         color: 'white',
  //         fontWeight: 600,
  //       }}
  //     >
  //       Tag Manager inserido
  //     </div>
  //   );
};

export default GTMVolto;
