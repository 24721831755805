import { injectIntl } from 'react-intl';

const GroupPerformance = ({ intl }) => {
  if (intl.locale === 'en') {
    return (
      <>
        They aim to improve the performance of the website by collecting
        anonymized data on navigation and use of available resources. If you do
        not allow the collection of these cookies, this data will not be used to
        improve the website.
      </>
    );
  }

  if (intl.locale === 'es') {
    return (
      <>
        Su objetivo es mejorar el rendimiento del sitio web mediante la
        recopilación de datos anónimos sobre la navegación y el uso de los
        recursos disponibles. Si no permite la recopilación de estas cookies,
        estos datos no se utilizarán para mejorar el sitio web.
      </>
    );
  }

  return (
    <>
      Visam a melhoria do desempenho do site por meio da coleta de dados
      anonimizados sobre navegação e uso dos recursos disponibilizados. Se você
      não permitir a coleta desses cookies, esses dados não serão usados para
      melhoria do site.
    </>
  );
};

export default injectIntl(GroupPerformance);
