import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {
  COOKIE_NAME,
  COOKIE_VERSION,
  COOKIE_MAX_AGE,
} from '../../constants/Lgpd';

const withLgpdCookie = (OriginalComponent) => {
  function NewComponent(props) {
    const [cookies, setCookie] = useCookies([COOKIE_NAME]);

    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
      setIsClient(true);
    }, []);

    const newProps = {
      lgpdCookieReady: false,
      hasLgpdCookie: false,
      performanceCookies: false,
      thirdPartyCookies: false,
      ...props,
    };

    // if (!isClient) {
    //   // return null;
    //   return <OriginalComponent {...newProps} />;
    // }

    const writeCookie = (performance, thirdParty) => {
      setCookie(
        COOKIE_NAME,
        // { ...toGovPlone4Cookie(value) },
        { v: COOKIE_VERSION, p: performance, t: thirdParty },
        {
          maxAge: COOKIE_MAX_AGE,
          encode: (v) => v,
          path: '/',
        },
      );
    };

    // if (hasLgpdCookie && lgpdCookie.v !== COOKIE_VERSION) {
    //   // TODO: remover dentro de m useEffect - testar!!
    //   // removeCookie(COOKIE_NAME);
    //   hasLgpdCookie = false;
    // }

    if (isClient) {
      const lgpdCookie = cookies[COOKIE_NAME];
      const hasLgpdCookie = lgpdCookie?.v === COOKIE_VERSION;

      newProps.lgpdCookieReady = true;
      newProps.hasLgpdCookie = hasLgpdCookie;
      newProps.performanceCookies = hasLgpdCookie && lgpdCookie.p;
      newProps.thirdPartyCookies = hasLgpdCookie && lgpdCookie.t;

      newProps.acceptCookies = () => writeCookie(true, true);
      newProps.rejectCookies = () => writeCookie(false, false);
      newProps.saveCookies = ({ performance, thirdParty }) =>
        writeCookie(performance, thirdParty);
    }

    return <OriginalComponent {...newProps} />;
  }

  return NewComponent;
};
export default withLgpdCookie;
