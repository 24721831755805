import PropTypes from 'prop-types';
import styles from './Button.module.less';
import GradientButton from '@package/components/GradientButton';

const ButtonBody = (props) => {
  const { data, isEditMode } = props;
  const {
    label,
    href,
    align = 'center',
    inverted,
    hideArrow,
    openLinkInNewTab,
  } = data;

  const hasLink = href?.[0];

  return (
    <div className={styles[align]}>
      <GradientButton
        href={isEditMode || !hasLink ? null : href?.[0]['@id']}
        invert={inverted}
        openLinkInNewTab={openLinkInNewTab}
        hideArrow={hideArrow}
        label={label}
      />
    </div>
  );
};

ButtonBody.propTypes = {
  isEditMode: PropTypes.bool,
};

export default ButtonBody;
