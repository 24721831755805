import { memo } from 'react';
const SvgMilho = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M22.341 10.309a1.514 1.514 0 0 0-1.451-.203c-.694.268-1.338.588-1.936.948C18.511 4.759 14.977-.001 12-.001S5.49 4.76 5.046 11.055c-.598-.36-1.242-.68-1.936-.948a1.509 1.509 0 0 0-1.451.203 1.637 1.637 0 0 0-.655 1.445C1.626 20.169 7.14 24.001 12 24.001s10.374-3.832 10.996-12.246a1.637 1.637 0 0 0-.655-1.445ZM2.002 11.681a.64.64 0 0 1 .252-.568.537.537 0 0 1 .313-.109c.056 0 .117.01.182.035.858.332 1.635.753 2.339 1.238.067.1.17.175.29.207 4.664 3.426 5.956 9.652 6.111 10.497-3.277-.241-8.86-2.807-9.487-11.3ZM8.443 14H10.5a.5.5 0 0 0 0-1H7.528a13.4 13.4 0 0 0-1.515-1.3c.022-.579.072-1.147.147-1.7H8.5a.5.5 0 0 0 0-1H6.325c.21-1.077.512-2.086.88-3H10.5a.5.5 0 0 0 0-1H7.65C8.856 2.554 10.53 1 12 1s3.144 1.554 4.35 4H13.5a.5.5 0 0 0 0 1h3.295c.368.914.67 1.923.88 3H15.5a.5.5 0 0 0 0 1h2.34c.075.553.125 1.121.147 1.7-.548.403-1.052.84-1.515 1.3H13.5a.5.5 0 0 0 0 1h2.057c-1.955 2.343-3.016 5.04-3.557 6.856-.54-1.816-1.602-4.513-3.557-6.856Zm13.555-2.319c-.627 8.493-6.21 11.059-9.487 11.3.156-.845 1.447-7.071 6.111-10.497a.499.499 0 0 0 .29-.207 11.298 11.298 0 0 1 2.339-1.238.5.5 0 0 1 .495.074.64.64 0 0 1 .252.568ZM13 9.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 .5.5Z" />
  </svg>
);
const Memo = memo(SvgMilho);
export default Memo;
