import PropTypes from 'prop-types';
import { withBlockExtensions } from '@plone/volto/helpers';
import { SidebarPortal } from '@plone/volto/components';
import DateShareData from './DateShareData';
import DateShareBody from './DateShareBody';

const Edit = (props) => {
  const { data, block, selected } = props;

  return (
    <>
      <DateShareBody isEditMode {...props} />
      <SidebarPortal selected={selected}>
        <DateShareData key={block} {...props} data={data} block={block} />
      </SidebarPortal>
    </>
  );
};

Edit.propTypes = {
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.bool,
};

export default withBlockExtensions(Edit);
