import { getG20Events } from '@package/actions';
import { GradientButton, TrackTag, CityLink } from '@package/components';
import { formatDateRange } from '@package/helpers';
import { Icon } from '@package/icons';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';

import styles from './EventsBody.module.less';

const EventsBody = ({ isEditMode, data }) => {
  const {
    variation,
    title,
    subject,
    ongoingTitle,
    upcomingTitle,
    buttonHref,
    buttonLabel,
  } = data;

  const withImage = variation === 'withImage';

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getG20Events());
  }, [dispatch]);
  const events = useSelector((state) => state.g20Events);

  const { error, loaded, loading, data: res } = events;

  if (loading) {
    return <div>Carregando eventos...</div>;
  }

  if (error || !loaded) {
    return isEditMode ? <div>Falha ao acessar a api</div> : null;
  }

  const sortFunction = (a, b) => {
    return (
      moment(a.start).diff(b.start, 'day') || moment(a.end).diff(b.end, 'day')
      // || a.track?.localeCompare(b.track)
      // || a.title?.localeCompare(b.title)
      // || a.id - b.id
    );
  };

  // moment.locale(toBackendLang(lang));

  const numCards = withImage ? 3 : 4;

  const ongoing = res.ongoing
    .sort((a, b) => sortFunction(a, b))
    .slice(0, numCards);
  const future =
    ongoing.length < numCards
      ? res.future
          .sort((a, b) => sortFunction(a, b))
          .slice(0, numCards - ongoing.length)
      : [];

  const renderCards = (events, title) => {
    if (!events.length) return null;

    const cards = events.map((event) => {
      const formattedDate = formatDateRange(event.start, event.end);

      const cardClass = cx(styles.card, [styles[_.camelCase(event.track)]]);

      return (
        <div className={cardClass} key={event.id}>
          <TrackTag track={event.track} />
          <div className={styles.title}>{event.title}</div>
          <div className={styles.date}>
            <Icon icon="Calendar" size="18px" />
            {formattedDate}
          </div>
          <CityLink name={event.city} className={styles.city} />
        </div>
      );
    });

    return (
      <div className={styles.list} style={{ flex: events.length }}>
        <div className={styles.listTitle}>{title}</div>
        <div className={styles.cards}>{cards}</div>
      </div>
    );
  };

  const blockClass = cx(styles[variation], {
    'full-width': withImage,
    decorationRight: withImage,
  });

  return (
    <div className={blockClass}>
      <Container>
        {subject ? (
          <div className={cx('sectionSubtitle', { invert: withImage })}>
            {subject}
          </div>
        ) : null}
        {title ? (
          <h2 className={cx('sectionTitle', { invert: withImage })}>{title}</h2>
        ) : null}
        <div className={styles.inner}>
          {renderCards(ongoing, ongoingTitle)}
          {renderCards(future, upcomingTitle)}
        </div>
        <div className={styles.foot}>
          {buttonHref || buttonLabel ? (
            <GradientButton
              item={buttonHref?.length ? buttonHref[0] : null}
              label={buttonLabel}
              invert={!withImage}
            />
          ) : null}
        </div>
      </Container>
    </div>
  );
};

EventsBody.propTypes = {
  isEditMode: PropTypes.bool,
};

export default EventsBody;
