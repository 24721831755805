import { injectIntl } from 'react-intl';

const ModalFooter = ({ intl }) => {
  const browserLinks = (
    <ul>
      <li>
        <a
          href="https://support.google.com/chrome/answer/95647"
          target="_blank"
          rel="noreferrer"
        >
          Chrome
        </a>
      </li>
      <li>
        <a
          href="https://support.mozilla.org/pt-BR/kb/desative-cookies-terceiros-impedir-rastreamento"
          target="_blank"
          rel="noreferrer"
        >
          Firefox
        </a>
      </li>
      <li>
        <a
          href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies"
          target=" _blank"
        >
          Microsoft Edge
        </a>
      </li>
      <li>
        <a
          href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage -cookies"
          target="_blank"
          rel="noreferrer"
        >
          Internet Explorer
        </a>
      </li>
    </ul>
  );

  if (intl.locale === 'en') {
    return (
      <>
        <div className="text-weight-semi-bold mb-3">
          Setting cookies in the browser
        </div>
        <div className="mb-3">
          You can disable them by changing your browser settings:
        </div>
        {browserLinks}
      </>
    );
  }

  if (intl.locale === 'es') {
    return (
      <>
        <div className="text-weight-semi-bold mb-3">
          Configurar cookies en el navegador
        </div>
        <div className="mb-3">
          Puede desactivarlos cambiando la configuración de su navegador:
        </div>
        {browserLinks}
      </>
    );
  }

  return (
    <>
      <div className="text-weight-semi-bold mb-3">
        Configuração de cookies no navegador
      </div>
      <div className="mb-3">
        Você pode desabilitá-los alterando as configurações do seu navegador:
      </div>
      {browserLinks}
    </>
  );
};

export default injectIntl(ModalFooter);
