import { memo } from 'react';
const SvgDolarDaFaturaDoArquivo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M5 9h5v1H5V9Zm5-4H5v1h5V5ZM2 23h15v1H1V2.5C1 1.122 2.122 0 3.5 0h10.207L21 7.293V8h-8V1H3.5C2.673 1 2 1.673 2 2.5V23ZM14 7h5.293L14 1.707V7Zm10 8v-.5c0-1.378-1.121-2.5-2.5-2.5h-1v-2h-1v2h-1a2.503 2.503 0 0 0-2.5 2.5c0 1.37.981 2.521 2.334 2.739l3.174.51A1.766 1.766 0 0 1 23 19.5c0 .827-.673 1.5-1.5 1.5h-3c-.827 0-1.5-.673-1.5-1.5V19h-1v.5c0 1.378 1.121 2.5 2.5 2.5h1v2h1v-2h1c1.379 0 2.5-1.122 2.5-2.5 0-1.37-.981-2.521-2.334-2.739l-3.174-.51A1.766 1.766 0 0 1 17 14.5c0-.827.673-1.5 1.5-1.5h3c.827 0 1.5.673 1.5 1.5v.5h1Zm-9.949-1c.039-.347.112-.681.226-1H5v6h9v-1H6v-4h8.051Z" />
  </svg>
);
const Memo = memo(SvgDolarDaFaturaDoArquivo);
export default Memo;
