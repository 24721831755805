import { defineMessages } from 'react-intl';

export default defineMessages({
  VideoBlock: {
    id: 'Video Block',
    defaultMessage: 'Video Block',
  },
  showPreviewImage: {
    id: 'Show preview image',
    defaultMessage: 'Show preview image',
  },
});
