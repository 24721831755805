import { memo } from 'react';
const SvgNavigation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="navigation_svg__feather navigation_svg__feather-navigation"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="m3 11 19-9-9 19-2-8-8-2z" />
  </svg>
);
const Memo = memo(SvgNavigation);
export default Memo;
