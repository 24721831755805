import { defineMessages } from 'react-intl';
import textCenteredSVG from '@plone/volto/icons/align-center.svg';
import textLeftSVG from '@plone/volto/icons/align-left.svg';
import textRightSVG from '@plone/volto/icons/align-right.svg';

// TODO: Deduplicar strings de tamanho - small, large, etc
const messages = defineMessages({
  buttonLabel: { id: 'Button Label', defaultMessage: 'Button Label' },
  button: { id: 'Button', defaultMessage: 'Button' },
  layout: { id: 'Layout', defaultMessage: 'Layout' },
  linkUrl: { id: 'Link URL', defaultMessage: 'Link URL' },
  inverted: { id: 'Inverted', defaultMessage: 'Inverted' },
  align: { id: 'Align', defaultMessage: 'Align' },
  left: { id: 'Left', defaultMessage: 'Left' },
  center: { id: 'Center', defaultMessage: 'Center' },
  right: { id: 'Right', defaultMessage: 'Right' },
  hideArrow: { id: 'Hide arrow', defaultMessage: 'Hide arrow' },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
});

export const buttonSchema = ({ intl }) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.button),
        fields: ['label', 'href', 'openLinkInNewTab'],
      },
      {
        id: 'layout',
        title: intl.formatMessage(messages.layout),
        fields: ['align', 'inverted', 'hideArrow'],
      },
    ],
    properties: {
      label: { title: intl.formatMessage(messages.buttonLabel) },
      href: {
        title: intl.formatMessage(messages.linkUrl),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title'],
        allowExternals: true,
      },
      align: {
        title: intl.formatMessage(messages.align),
        required: false,
        default: 'center',
        type: 'string',
        widget: 'buttons',
        actions: ['left', 'center', 'right'],
        actionsInfoMap: {
          left: [textLeftSVG, intl.formatMessage(messages.left)],
          center: [textCenteredSVG, intl.formatMessage(messages.center)],
          right: [textRightSVG, intl.formatMessage(messages.right)],
        },
      },
      openLinkInNewTab: {
        title: intl.formatMessage(messages.openLinkInNewTab),
        type: 'boolean',
      },
      inverted: {
        title: intl.formatMessage(messages.inverted),
        required: false,
        default: false,
        type: 'boolean',
      },
      hideArrow: {
        title: intl.formatMessage(messages.hideArrow),
        required: false,
        default: false,
        type: 'boolean',
      },
    },
    required: [],
  };
};
