import PropTypes from 'prop-types';
import styles from './Footer.module.less';

const Footer = ({ children }) => {
  if (!children) {
    return null;
  }

  return <div className={styles.footer}>{children}</div>;
};

Footer.displayName = 'Footer';

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
