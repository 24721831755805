import { defineMessages } from 'react-intl';

export default defineMessages({
  save: { id: 'Save', defaultMessage: 'Save' },
  closeWithoutSave: {
    id: 'Close without save',
    defaultMessage: 'Close without save',
  },
  resetCookies: { id: 'Reset cookies', defaultMessage: 'Reset cookies' },
  manageCookies: { id: 'Manage cookies', defaultMessage: 'Manage cookies' },
  acceptCookies: { id: 'Accept cookies', defaultMessage: 'Accept cookies' },
  rejectCookies: { id: 'Reject cookies', defaultMessage: 'Reject cookies' },
});
