import { addStyling } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { accordionMessages as messages } from '@package/messages';
import './theme/theme-g20.less';

export const AccordionSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.Accordion),
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.Default),
        fields: ['panel_title'],
      },
    ],
    properties: {
      panel_title: {
        title: intl.formatMessage(messages.AccordionTitle),
      },
    },
    required: [],
  };
};

export const AccordionBlockSchema = ({ intl }) => ({
  title: intl.formatMessage(messages.AccordionBlock),
  fieldsets: [
    {
      id: 'default',
      title: intl.formatMessage(messages.Default),
      fields: ['data'],
    },
    {
      id: 'options',
      title: intl.formatMessage(messages.Options),
      fields: [
        'subtitle',
        'title',
        // 'textTitle',
        'left_arrows',
        'firstOpen',
        'exclusive',
      ],
    },
  ],
  properties: {
    data: {
      title: intl.formatMessage(messages.Accordion),
      type: 'panels',
      schema: AccordionSchema(intl),
    },
    // title: {
    //   title: intl.formatMessage(messages.Title),
    //   description: intl.formatMessage(messages.friendly_name),
    //   type: 'string',
    // },
    title: {
      title: intl.formatMessage(messages.TextTitle),
      type: 'string',
    },
    subtitle: {
      title: intl.formatMessage(messages.TextSubtitle),
      type: 'string',
    },
    left_arrows: {
      title: intl.formatMessage(messages.left_arrows),
      type: 'boolean',
    },
    firstOpen: {
      title: intl.formatMessage(messages.firstOpen),
      type: 'boolean',
      default: false,
    },
    exclusive: {
      title: intl.formatMessage(messages.exclusive),
      type: 'boolean',
    },
  },
  required: [],
});

export const AccordionStylingSchema = (props) => {
  const { intl } = props;
  const schema = addStyling(props);
  schema.properties.styles.schema = {
    title: intl.formatMessage(messages.Accordion),
    block: 'accordion',
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.Default),
        fields: ['theme'],
      },
    ],
    properties: {
      theme: {
        title: intl.formatMessage(messages.Theme),
        description: intl.formatMessage(messages.ThemeHelp),
        widget: 'theme_picker',
        colors: [
          ...(config.settings && config.settings.themeColors
            ? config.settings.themeColors.map(({ value, title }) => ({
                name: value,
                label: title,
              }))
            : []),
        ],
      },
    },
    required: [],
  };
  return schema;
};
