/**
 * Add your config changes here.
 * @module config
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';
import { uniqBy } from 'lodash';
import { defineMessages } from 'react-intl';
import { BackToTop } from '@package/components';
import blockVariations from './components/BlockTemplates';
import eventsMiddleware from './helpers/events-middleware';
// import { GA } from '@serpro/google-analytics/components';
// import { withLgpdCookie } from 'lgpd.plugin/components';

/* applyConfig imports */
import applyConfigBlockCards from './components/Blocks/Cards';
import applyConfigBlockDivider from './components/Blocks/Divider';
import applyConfigBlockNumbers from './components/Blocks/Numbers';
import applyConfigBlockCalendar from './components/Blocks/Calendar';
import applyConfigBlockHeroCarousel from './components/Blocks/HeroCarousel';
import applyConfigBlockCarousel from './components/Blocks/Carousel';
import applyConfigBlockButton from './components/Blocks/Button';
import applyConfigBlockCountriesList from './components/Blocks/CountriesList';
import applyConfigBlockDateShare from './components/Blocks/DateShare';
import applyConfigBlockEvents from './components/Blocks/Events';
import applyConfigBlockHeadTitle from './components/Blocks/HeadTitle';
import applyConfigBlockSectionSubtitle from './components/Blocks/SectionSubtitle';
import applyConfigBlockSectionTitle from './components/Blocks/SectionTitle';
import SocialNetworksWidget from './widgets/social_networks';
import FooterLinksWidget from './widgets/footer_links';
import GTMVolto from './components/GTM/volto-gtm';

defineMessages({
  Advanced: {
    id: 'advanced',
    defaultMessage: 'Advanced',
  },
  contentTitle: {
    id: 'Content title',
    defaultMessage: 'Content title',
  },
  contentDescription: {
    id: 'Content description',
    defaultMessage: 'Content description',
  },
  leadimage: {
    id: 'Lead image field',
    defaultMessage: 'Lead image field',
  },
  searchBlock: {
    id: 'Search block title',
    defaultMessage: 'Search',
  },
});

const hideBlocks = ['maps', 'gridBlock', 'slateTable', 'hero', 'toc'];

export default function applyConfig(config) {
  /* applyConfig calls */
  applyConfigBlockCards(config);
  applyConfigBlockDivider(config);
  applyConfigBlockNumbers(config);
  applyConfigBlockCalendar(config);
  applyConfigBlockDateShare(config);
  applyConfigBlockCountriesList(config);
  applyConfigBlockHeroCarousel(config);
  applyConfigBlockEvents(config);
  applyConfigBlockCarousel(config);
  applyConfigBlockCountriesList(config);
  applyConfigBlockButton(config);
  applyConfigBlockSectionSubtitle(config);
  applyConfigBlockSectionTitle(config);
  applyConfigBlockHeadTitle(config);

  // Remove blocos padrão do Volto que não serão usados pelo projeto
  hideBlocks.forEach((block) => delete config.blocks.blocksConfig[block]);

  config.settings = {
    ...config.settings,

    // Define o idioma do site
    supportedLanguages: ['pt-br', 'en', 'es'],
    defaultLanguage: 'pt-br',
    isMultilingual: true,

    // Altera a profundidade do menu
    navDepth: 2,

    showRelatedItems: true,
    defaultPageSize: 6,
    searchPageSize: 25,

    appExtras: [
      ...config.settings.appExtras,
      // {
      //   match: '',
      //   component: withLgpdCookie(GA),
      // },
      {
        match: '',
        component: GTMVolto,
      },
      {
        match: '',
        component: BackToTop,
      },
    ],

    htmlScriptAllowedDomains: ['twitter.com/', 'instagram.com/'],
  };

  config.blocks = {
    ...config.blocks,

    // Remove obrigatoriedade do bloco de título
    requiredBlocks: [],

    // Adiciona grupos Metadados e Portal G20
    // Altera ordem dos grupos
    // Para traduzir, o id do bloco deve corresponder ao id da mensagem
    groupBlocksOrder: uniqBy(
      [
        { id: 'mostUsed', title: 'Most used' },
        { id: 'text', title: 'Text' },
        { id: 'layout', title: 'Layout' },
        { id: 'listing', title: 'Listing' },
        { id: 'media', title: 'Media' },
        { id: 'portalg20', title: 'Portal G20' },
        { id: 'advanced', title: 'Advanced' },
        ...config.blocks.groupBlocksOrder,
      ],
      'id',
    ),
  };

  // Adiciona o bloco de colunas ao grupo Mais Usados
  config.blocks.blocksConfig.columnsBlock.mostUsed = true;

  // Remove o bloco de listagem do grupo Mais Usados
  config.blocks.blocksConfig.listing.mostUsed = false;

  // Move blocos padrão do Volto para os grupos definidos para o projeto
  config.blocks.blocksConfig.slate.group = 'text';
  config.blocks.blocksConfig.text.group = 'text';
  config.blocks.blocksConfig.title.group = 'text';
  config.blocks.blocksConfig.description.group = 'text';
  config.blocks.blocksConfig.listing.group = 'listing';
  config.blocks.blocksConfig.search.group = 'listing';
  config.blocks.blocksConfig.teaser.group = 'layout';
  // config.blocks.blocksConfig.slateTable.group = 'layout';
  config.blocks.blocksConfig.html.group = 'advanced';
  config.blocks.blocksConfig.columnsBlock.group = 'layout';

  // Altera nomes dos blocos do conteúdo
  config.blocks.blocksConfig.title.title = 'Content title';
  config.blocks.blocksConfig.description.title = 'Content description';
  config.blocks.blocksConfig.leadimage.title = 'Lead image field';

  // Altera nome do bloco de Busca
  config.blocks.blocksConfig.search.title = 'Search block title';

  // Habilita novo botão de adicionar blocos
  config.experimental.addBlockButton.enabled = true;

  // Remove links dos blocos de título
  config.settings.slate.useLinkedHeadings = false;

  // Altera o grupo do bloco Accordion
  config.blocks.blocksConfig.accordion.group = 'layout';

  // Remove configuração de tema do Accordion
  config.blocks.blocksConfig.accordion.schemaEnhancer = null;

  // Widget redes sociais
  config.widgets.widget.social_networks_widget = SocialNetworksWidget;

  // Widget links rodape
  config.widgets.widget.footer_links_widget = FooterLinksWidget;

  // Adiciona variações ao bloco de listagem
  // config.blocks.blocksConfig.listing.variations =
  //   config.blocks.blocksConfig.listing.variations.map((variation) => {
  //     if (variation.id === 'summary')
  //       return {
  //         ...variation,
  //         title: 'With image',
  //       };
  //     return variation;
  //   });

  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations.filter(
      (v) => v.id === 'default',
    ),
    ...blockVariations.listing,
  ];

  config.blocks.blocksConfig.teaser.variations = [
    ...config.blocks.blocksConfig.teaser.variations,
    ...blockVariations.teaser,
  ];

  eventsMiddleware(config);

  return config;
}
