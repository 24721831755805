import iconSVG from '@plone/volto/icons/slider.svg';
import { defineMessages } from 'react-intl';
import CarouselEdit from './CarouselEdit';
import CarouselView from './CarouselView';
import './theme/main.less';

defineMessages({
  cachesarousel: {
    id: 'Carousel',
    defaultMessage: 'Carousel',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.carouselBlock = {
    id: 'carouselBlock',
    title: 'Carousel',
    group: 'layout',
    icon: iconSVG,
    view: CarouselView,
    edit: CarouselEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
