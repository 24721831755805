import { defineMessages } from 'react-intl';
import GroupPerformance from './Texts/GroupPerformance';
import GroupStrictlyNecessary from './Texts/GroupStrictlyNecessary';
import GroupThirdParty from './Texts/GroupThirdParty';

const titles = defineMessages({
  performance: {
    id: 'Performance cookies',
    defaultMessage: 'Performance cookies',
  },
  strictlyNecessary: {
    id: 'Strictly necessary cookies',
    defaultMessage: 'Strictly necessary cookies',
  },
  thirdParty: {
    id: 'Third-party cookies',
    defaultMessage: 'Third-party cookies',
  },
});

const performance = {
  title: titles.performance,
  description: GroupPerformance,
};

const strictlyNecessary = {
  title: titles.strictlyNecessary,
  description: GroupStrictlyNecessary,
};
const thirdParty = {
  title: titles.thirdParty,
  description: GroupThirdParty,
};

const groups = {
  strictlyNecessary,
  performance,
  thirdParty,
};

export default groups;
