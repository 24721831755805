import { GET_G20_CITIES } from '../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export default function g20Cities(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_G20_CITIES}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        data: {},
      };
    case `${GET_G20_CITIES}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        data: action.result,
      };
    case `${GET_G20_CITIES}_FAIL`:
      return {
        ...state,
        error: action.result,
        loaded: false,
        loading: false,
        data: {},
      };
    default:
      return state;
  }
}
