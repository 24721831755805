import { defineMessages } from 'react-intl';
import ButtonView from './ButtonView';
import ButtonEdit from './ButtonEdit';
import iconSVG from './icons/button.svg';

defineMessages({
  button: {
    id: 'Button',
    defaultMessage: 'Button',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.buttonBlock = {
    id: 'buttonBlock',
    title: 'Button',
    group: 'text',
    icon: iconSVG,
    view: ButtonView,
    edit: ButtonEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
