import { memo } from 'react';
const SvgPresentes = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M21.5 14h-.014c.253-.334.427-.721.488-1.146.095-.661-.074-1.319-.475-1.854s-.986-.881-1.646-.975a2.471 2.471 0 0 0-1.854.475c-.741.555-1.2 1.322-1.496 2.038-.296-.714-.763-1.483-1.504-2.038a2.504 2.504 0 0 0-3.5.5 2.484 2.484 0 0 0-.475 1.854c.061.426.236.813.488 1.146h-.013a2.503 2.503 0 0 0-2.5 2.5V23H1V7.5C1 6.673 1.673 6 2.5 6h10c.827 0 1.5.673 1.5 1.5v.745c.346.106.682.249 1 .43V7.5C15 6.121 13.878 5 12.5 5H8.207l3.396-3.396-.707-.707L8 3.793V0H7v3.793L4.104.896l-.707.707 3.396 3.396H2.5a2.503 2.503 0 0 0-2.5 2.5V24h24v-7.5c0-1.379-1.122-2.5-2.5-2.5Zm-2.9-2.699a1.5 1.5 0 1 1 1.799 2.399 2.42 2.42 0 0 1-.553.3H17.09c.151-.716.54-1.973 1.51-2.699Zm-6 2.398c-.32-.24-.528-.591-.585-.987s.044-.792.285-1.112a1.494 1.494 0 0 1 2.1-.299c.969.726 1.358 1.984 1.509 2.699h-2.755a2.413 2.413 0 0 1-.554-.301ZM10 16.5c0-.827.673-1.5 1.5-1.5H16v8h-6v-6.5ZM23 23h-6l-.002-8H21.5c.827 0 1.5.673 1.5 1.5V23Z" />
  </svg>
);
const Memo = memo(SvgPresentes);
export default Memo;
