import { memo } from 'react';
const SvgSemeaduraManual = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="m23.208 16.354-5.523 4.96a10.492 10.492 0 0 1-7.015 2.687H.5a.5.5 0 0 1 0-1h10.17c2.346 0 4.6-.863 6.347-2.431l5.504-4.941c.539-.539.643-1.399.223-1.983a1.493 1.493 0 0 0-1.104-.63c-.436-.03-.87.123-1.183.435l-4.179 3.808a2.493 2.493 0 0 1-1.777.743h-5a.5.5 0 0 1 0-1h5a1.495 1.495 0 0 0 1.075-.458l.018-.016c.252-.268.407-.629.407-1.026 0-.827-.673-1.5-1.5-1.5H8.589a9.442 9.442 0 0 0-6.718 2.782L.863 17.846a.497.497 0 0 1-.706.019.498.498 0 0 1-.019-.706l1.017-1.071a10.44 10.44 0 0 1 7.434-3.085h5.912c1.296 0 2.365.991 2.488 2.255l2.778-2.53a2.468 2.468 0 0 1 1.954-.707c.732.059 1.4.438 1.835 1.042.704.977.555 2.393-.347 3.293ZM5.004 1.627c-.031-.437.131-.867.443-1.18s.736-.468 1.181-.443C9.795.227 11.675 1.42 12.5 3.857c.826-2.437 2.705-3.63 5.873-3.853.436-.021.867.13 1.18.443.312.312.475.743.443 1.181C19.692 5.94 17.591 7.866 13 7.993V9.5a.5.5 0 0 1-1 0V7.993C7.409 7.866 5.308 5.94 5.004 1.627Zm8.003 5.366c4.034-.117 5.727-1.673 5.991-5.437a.516.516 0 0 0-.152-.402A.517.517 0 0 0 18.48 1c-.012 0-.023 0-.035.002-3.765.265-5.32 1.957-5.438 5.991ZM6.002 1.556c.265 3.765 1.957 5.32 5.991 5.438-.117-4.034-1.673-5.727-5.437-5.991l-.036-.002a.52.52 0 0 0-.366.154.516.516 0 0 0-.152.401Z" />
  </svg>
);
const Memo = memo(SvgSemeaduraManual);
export default Memo;
