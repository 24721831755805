import { defineMessages } from 'react-intl';

const messages = defineMessages({
  events: { id: 'Events', defaultMessage: 'Events' },
  layout: { id: 'Layout', defaultMessage: 'Layout' },
  title: { id: 'Title', defaultMessage: 'Title' },
  titles: { id: 'Titles', defaultMessage: 'Titles' },
  subject: { id: 'Subject', defaultMessage: 'Subject' },
  Ongoing: { id: 'Ongoing', defaultMessage: 'Ongoing' },
  Upcoming: { id: 'Upcoming', defaultMessage: 'Upcoming' },
  variation: { id: 'Variation', defaultMessage: 'Variation' },
  simple: { id: 'Simple', defaultMessage: 'Simple' },
  withImage: { id: 'With image', defaultMessage: 'With image' },
  buttonLabel: { id: 'Button Label', defaultMessage: 'Button Label' },
  linkUrl: { id: 'Link URL', defaultMessage: 'Link URL' },
  footer: { id: 'Footer', defaultMessage: 'Footer' },
});

export const eventsSchema = ({ intl }) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.layout),
        fields: ['variation'],
      },
      {
        id: 'titles',
        title: intl.formatMessage(messages.titles),
        fields: ['subject', 'title', 'ongoingTitle', 'upcomingTitle'],
      },
      {
        id: 'footer',
        title: intl.formatMessage(messages.footer),
        fields: ['buttonLabel', 'buttonHref'],
      },
    ],
    properties: {
      subject: { title: intl.formatMessage(messages.subject) },
      title: { title: intl.formatMessage(messages.title) },
      ongoingTitle: {
        title: intl.formatMessage(messages.Ongoing),
        default: intl.formatMessage(messages.Ongoing),
      },
      upcomingTitle: {
        title: intl.formatMessage(messages.Upcoming),
        default: intl.formatMessage(messages.Upcoming),
      },
      buttonLabel: { title: intl.formatMessage(messages.buttonLabel) },
      buttonHref: {
        title: intl.formatMessage(messages.linkUrl),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title'],
        allowExternals: true,
      },
      variation: {
        title: intl.formatMessage(messages.variation),
        required: false,
        noValueOption: false,
        default: 'simple',
        type: 'string',
        factory: 'Choice',
        choices: [
          ['simple', intl.formatMessage(messages.simple)],
          ['withImage', intl.formatMessage(messages.withImage)],
        ],
      },
    },
    required: [],
  };
};
