/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { find, map } from 'lodash';
import { useSelector } from 'react-redux';

import {
  Helmet,
  flattenToAppURL,
  langmap,
  toReactIntlLang,
  toBackendLang,
} from '@plone/volto/helpers';

import config from '@plone/volto/registry';

import { Dropdown } from 'semantic-ui-react';
import { Icon } from '@package/icons';

const LanguageSelector = ({ onClickAction, history }) => {
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  const { settings } = config;

  const languageOptions = map(settings.supportedLanguages, (lang) => {
    return {
      key: `language-selector-${lang}`,
      value: lang,
      text: langmap[lang].nativeName,
    };
  });

  return settings.isMultilingual ? (
    <Dropdown
      selection
      icon={<Icon icon="TriangleDown" />}
      options={languageOptions}
      value={toBackendLang(currentLang)}
      className="language-select"
      onChange={(event, data) => {
        const translation = find(translations, { language: data.value });

        history.push(
          translation ? flattenToAppURL(translation['@id']) : `/${data.value}`,
        );

        onClickAction();
      }}
    />
  ) : (
    <Helmet>
      <html lang={toReactIntlLang(settings.defaultLanguage)} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

export default withRouter(LanguageSelector);
