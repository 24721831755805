import { memo } from 'react';
const SvgCameraOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="camera-off_svg__feather camera-off_svg__feather-camera-off"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="m1 1 22 22M21 21H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3m3-3h6l2 3h4a2 2 0 0 1 2 2v9.34m-7.72-2.06a4 4 0 1 1-5.56-5.56" />
  </svg>
);
const Memo = memo(SvgCameraOff);
export default Memo;
