import { MoreInfo } from '@package/components';
import { UniversalLink } from '@plone/volto/components';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { isInternalURL, toBackendLang } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { teaserMessages as messages } from '@package/messages';

import styles from './DefaultBody.module.less';

const LargeTemplate = (props) => {
  const { className, data, isEditMode } = props;
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  const Image = config.getComponent('Image').component;
  const { openExternalLinkInNewTab } = config.settings;

  const lang = useSelector((state) => state.intl.locale);
  moment.locale(toBackendLang(lang));

  if (!href) {
    return isEditMode ? (
      <Message>
        <div className={cx('block teaser', className)}>
          <div className="teaser-item placeholder">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </div>
      </Message>
    ) : null;
  }

  const {
    '@id': url,
    '@type': type,
    effective,
    hasPreviewImage,
    image_field,
    review_state,
  } = href;

  const isPublished = !review_state || review_state === 'published';
  const showDate = review_state && isPublished;

  if (!isPublished && !isEditMode) {
    return null;
  }

  return (
    <div
      className={cx(
        'block teaser',
        { [styles.root]: isPublished, [styles.error]: !isPublished },
        className,
      )}
    >
      {(hasPreviewImage || image_field || image) && (
        <div className={styles.img}>
          <Image
            item={image || href}
            imageField={image ? image.image_field : image_field}
            alt=""
            loading="lazy"
            responsive={true}
          />
        </div>
      )}
      <div className={styles.content}>
        {!isPublished && (
          <div className={styles.private}>
            {intl.formatMessage(messages.PrivateContentOrPublishedAfterAdd)}
          </div>
        )}
        <h3 className={styles.title}>{data?.title}</h3>
        {showDate && (
          <div className={styles.date}>{moment(effective).format('LL')}</div>
        )}
        <UniversalLink
          href={url}
          className="more-link"
          target={
            data.openLinkInNewTab ||
            (openExternalLinkInNewTab && !isInternalURL(url))
              ? '_blank'
              : null
          }
        >
          <MoreInfo title={data?.title} type={type} />
        </UniversalLink>
      </div>
    </div>
  );
};

LargeTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default LargeTemplate;
