import { memo } from 'react';
const SvgDollarSign = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="dollar-sign_svg__feather dollar-sign_svg__feather-dollar-sign"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12 1v22M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
  </svg>
);
const Memo = memo(SvgDollarSign);
export default Memo;
