import { memo } from 'react';
const SvgAtom = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={24}
    height={24}
    fill="currentColor"
    style={{
      enableBackground: 'new 0 0 24 24',
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M16.523 22.931c-2.448 0-5.965-3.469-8.692-8.771-.258-.505-.5-1.01-.724-1.511-2.157 4.235-2.51 7.701-1.296 8.51.723.459 2.048.076 3.624-1.054a.5.5 0 0 1 .583.813c-1.982 1.421-3.625 1.794-4.752 1.08-2.126-1.417-1.048-6.114 1.342-10.532a25.254 25.254 0 0 1-.963-2.908c-1.808.367-3.241.941-4.042 1.671-.384.35-.586.713-.601 1.079-.049.778.704 1.606 2.118 2.342a.5.5 0 1 1-.462.887C.369 13.345-.049 12.095.003 11.256c.027-.632.338-1.23.927-1.766.925-.844 2.506-1.497 4.486-1.905-.396-1.89-.428-3.53-.048-4.688.253-.771.679-1.311 1.265-1.604.704-.365 1.989-.465 4.077 1.148a.5.5 0 0 1-.611.791C8.795 2.225 7.725 1.853 7.087 2.184c-.346.173-.602.516-.769 1.025-.329 1.002-.284 2.478.081 4.198a26.347 26.347 0 0 1 3.068-.286c3.017-3.843 6.728-6.694 8.877-5.354 1.123.748 1.455 2.278 1 4.554a.499.499 0 1 1-.98-.196c.253-1.265.361-2.903-.561-3.518-1.175-.733-4.09.944-7.04 4.494.484.003.975.016 1.473.042 6.817.348 11.874 2.651 11.762 5.358-.118 1.186-1.191 2.226-3.023 2.959a.5.5 0 0 1-.372-.929c1.427-.571 2.323-1.356 2.397-2.101.071-1.749-4.352-3.959-10.816-4.289a31.293 31.293 0 0 0-2.22-.033 30.853 30.853 0 0 0-1.27 1.807 30.544 30.544 0 0 0-.982 1.61c.299.72.636 1.45 1.008 2.179 2.967 5.769 6.786 8.903 8.359 8.103 1.532-.778 1.329-5.388-1.416-11.01a.5.5 0 0 1 .899-.439c2.862 5.863 3.279 11.167.97 12.34a2.201 2.201 0 0 1-1.009.233zM6.634 8.383c.169.63.375 1.285.617 1.954a30.205 30.205 0 0 1 1.432-2.173c-.713.047-1.4.121-2.049.219z" />
    <path d="M13.468 11.674c.161.774-.323 1.548-1.097 1.709a1.407 1.407 0 0 1-1.677-1.097c-.161-.774.323-1.548 1.097-1.709a1.423 1.423 0 0 1 1.677 1.097z" />
  </svg>
);
const Memo = memo(SvgAtom);
export default Memo;
