export const COOKIE_NAME = 'lgpd-cookie-v2';
export const COOKIE_VERSION = 9;
export const COOKIE_MAX_AGE = 31536000; // 365 * 24 * 60 * 60;

export const GROUP_DOMAINS = {
  performance: ['//www.google-analytics'],
  thirdParty: [
    '//www.youtube',
    '//youtu.be',
    '//www.google.com',
    '//www.instagram.com',
    '//platform.twitter.com',
    'static.ads-twitter.com',
  ],
  noCookies: ['//www.youtube-nocookie.com'],
};
