import { memo } from 'react';
const SvgXadrez = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M24 21.5V24h-7v-1h6v-1.5c0-.864-.621-1.481-1.511-1.5h-4.766a4.534 4.534 0 0 0-.485-1H21v-9h-5v8.706a4.531 4.531 0 0 0-1-.945V9.999h-1v-3h1v2h3v-2h1v2h3v-2h1v3h-1v9.054c1.183.23 2 1.194 2 2.446Zm-9 0V24H0v-2.5c0-1.245.916-2.271 2.108-2.46.37-1.368 1.773-6.824 1.88-11.04H3V7h.694L2.108 3.799c-.164-.452-.103-.895.141-1.239s.64-.55 1.062-.55h3.69V0h1v2.01h3.708a1.302 1.302 0 0 1 1.225 1.734L11.324 7h.677v1h-.988c.111 4.202 1.511 9.668 1.88 11.04a2.498 2.498 0 0 1 2.108 2.46ZM4.81 7h5.397l1.807-3.646c.024-.077-.034-.18-.061-.218s-.105-.127-.245-.127H3.31a.299.299 0 0 0-.283.4l1.783 3.59Zm.178 1c-.098 4.123-1.363 9.233-1.834 11h8.692c-.469-1.768-1.732-6.888-1.834-11H4.989Zm9.011 13.5c0-.827-.673-1.5-1.5-1.5H2.5c-.827 0-1.5.673-1.5 1.5V23h13v-1.5Z" />
  </svg>
);
const Memo = memo(SvgXadrez);
export default Memo;
