import _ from 'lodash';
import cx from 'classnames';
import { IconButton } from '@package/components';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import FilterCheckbox from './FilterCheckbox';

const TrackCheckbox = ({ track, values, onFilter }) => {
  const [open, setOpen] = useState(false);

  const currentValues = values.tracks.find((t) => t.name === track.name) || {
    name: track.name,
    checked: false,
    workgroups: [],
  };
  const checked =
    currentValues.checked && currentValues.workgroups.length === 0;
  const indeterminate = currentValues.workgroups.length > 0;

  const onFilterTrack = (checked) => {
    const newFilters = {
      ...values,
      tracks: values.tracks.filter((t) => t.name !== track.name),
    };
    newFilters.tracks.push({ name: track.name, checked, workgroups: [] });

    onFilter(newFilters);
  };

  const onFilterGroup = (group, groupChecked) => {
    const newFilters = {
      ...values,
      tracks: values.tracks.filter((t) => t.name !== track.name),
    };

    if (groupChecked) {
      const newWorkgroups = [...currentValues.workgroups, group];
      newFilters.tracks.push({
        ...currentValues,
        checked: true,
        workgroups:
          newWorkgroups.length === track.workgroups.length ? [] : newWorkgroups,
      });
    } else {
      const newWorkgroups =
        currentValues.checked && currentValues.workgroups.length === 0
          ? track.workgroups.filter((g) => g.name !== group).map((g) => g.name)
          : currentValues.workgroups.filter((g) => g !== group);

      newFilters.tracks.push({
        ...currentValues,
        checked: newWorkgroups.length > 0,
        workgroups: newWorkgroups,
      });
    }

    onFilter(newFilters);
  };

  const hasWorkgroups = track?.workgroups?.length > 0;

  return (
    <div className="rbc-filterbar-track">
      <div className={cx('rbc-filterbar-track-title', _.camelCase(track.name))}>
        <FilterCheckbox
          label={track.name}
          checked={checked}
          indeterminate={indeterminate}
          onChange={(e, filterData) => {
            onFilterTrack(filterData.checked);
          }}
        />
        {hasWorkgroups && (
          <IconButton
            icon={open ? 'ChevronUp' : 'ChevronDown'}
            dark
            small
            iconSize={16}
            onClick={() => setOpen(!open)}
          />
        )}
      </div>

      {hasWorkgroups && (
        <AnimateHeight animateOpacity duration={300} height={open ? 'auto' : 0}>
          {track.workgroups
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((group) => {
              const groupChecked =
                checked || currentValues.workgroups.includes(group.name);

              return (
                <FilterCheckbox
                  key={group.id}
                  label={group.name}
                  checked={groupChecked}
                  onChange={(e, filterData) => {
                    onFilterGroup(group.name, filterData.checked);
                  }}
                />
              );
            })}
        </AnimateHeight>
      )}
    </div>
  );
};

export default TrackCheckbox;
