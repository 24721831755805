import { defineMessages } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  socialNetwork: { id: 'Social Network', defaultMessage: 'Social Network' },
  socialNetworkUrl: {
    id: 'Social Network URL',
    defaultMessage: 'Social Network URL',
  },
  socialNetworkUrlHelp: {
    id: 'Social Network URL Help',
    defaultMessage:
      'Full site URL to destination. Eg.: https://www.nomedarede.com/governodobrasil',
  },
  refreshPage: {
    id: 'Refresh the page to see your changes',
    defaultMessage: 'Refresh the page to see your changes',
  },
});

const itemSchema = ({ intl }) => {
  return {
    title: intl.formatMessage(messages.socialNetwork),
    properties: {
      social_network: {
        title: intl.formatMessage(messages.socialNetwork),
        required: true,
        noValueOption: false,
        default: 'Facebook',
        type: 'string',
        factory: 'Choice',
        choices: [
          ['Facebook', 'Facebook'],
          ['Flickr', 'Flickr'],
          ['Instagram', 'Instagram'],
          ['Koo', 'Koo'],
          ['Linkedin', 'Linkedin'],
          ['Slideshare', 'Slideshare'],
          ['Soundcloud', 'Soundcloud'],
          ['Tumblr', 'Tumblr'],
          ['Twitter', 'Twitter'],
          ['Whatsapp', 'Whatsapp'],
          ['Youtube', 'Youtube'],
        ],
      },
      social_network_url: {
        title: intl.formatMessage(messages.socialNetworkUrl),
        description: intl.formatMessage(messages.socialNetworkUrlHelp),
        required: true,
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.socialNetwork),
        fields: ['social_network', 'social_network_url'],
      },
    ],
    required: ['social_network', 'social_network_url'],
  };
};

const SocialNetworksWidget = (props) => {
  return (
    <>
      <ObjectListWidget
        schema={itemSchema}
        {...props}
        value={props.value?.items || props.default?.items || []}
        onChange={(id, value) => props.onChange(id, { items: value })}
      />
      <p className="help" style={{ textAlign: 'center', padding: 8 }}>
        {props.intl.formatMessage(messages.refreshPage)}
      </p>
    </>
  );
};

export default SocialNetworksWidget;
