import { GET_G20_WORKGROUPS } from '../constants/ActionTypes';

import config from '@plone/volto/registry';

export function getG20Workgroups() {
  const { settings } = config;

  return {
    type: GET_G20_WORKGROUPS,
    request: {
      op: 'get',
      path: `${settings.apiPath}/g20-api/workgroups`,
    },
  };
}
