import { defineMessages } from 'react-intl';
import CardsView from './CardsView';
import CardsEdit from './CardsEdit';
import iconSVG from '@plone/volto/icons/cards.svg';

defineMessages({
  cards: {
    id: 'Cards',
    defaultMessage: 'Cards',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.cardsBlock = {
    id: 'cardsBlock',
    title: 'Cards',
    group: 'layout',
    icon: iconSVG,
    view: CardsView,
    edit: CardsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
