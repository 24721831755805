import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InnerHTML from '@serpro/react-dangerously-set-html-content';
import { withLgpdCookie } from 'lgpd.plugin/components';
import { blockHTML } from 'lgpd.plugin/helpers';
import config from '@plone/volto/registry';

const View = ({ data, performanceCookies, thirdPartyCookies, isEditMode }) => {
  const [contentBlocked, setContentBlocked] = useState(false);
  const token = useSelector((state) => state.userSession.token, shallowEqual);

  const blockObject = blockHTML(
    data.html,
    performanceCookies,
    thirdPartyCookies,
  );

  return (
    <div className="block html">
      {!!token && contentBlocked && (
        <div
          style={{
            color: '#d22',
            textAlign: 'center',
            fontWeight: 600,
            padding: '0 16px 8px',
          }}
        >
          Um script não permitido foi encontrado no código, e por isso ele foi
          removido.
        </div>
      )}

      {blockObject.html ? (
        <InnerHTML
          scriptAllowedDomains={config.settings.htmlScriptAllowedDomains}
          html={blockObject.html}
          allowRerender={false}
          onBlockScript={() => setContentBlocked(true)}
        />
      ) : null}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withLgpdCookie(View);
