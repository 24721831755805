import { memo } from 'react';
const SvgMove = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="move_svg__feather move_svg__feather-move"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="m5 9-3 3 3 3M9 5l3-3 3 3M15 19l-3 3-3-3M19 9l3 3-3 3M2 12h20M12 2v20" />
  </svg>
);
const Memo = memo(SvgMove);
export default Memo;
