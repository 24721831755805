import { memo } from 'react';
const SvgInstagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16 0c-4.347 0-4.889.02-6.596.096C7.7.176 6.54.444 5.52.84c-1.052.408-1.945.956-2.835 1.845S1.246 4.466.84 5.52C.444 6.54.175 7.7.096 9.404.016 11.111 0 11.653 0 16s.02 4.889.096 6.596c.08 1.703.348 2.864.744 3.884a7.854 7.854 0 0 0 1.845 2.835A7.807 7.807 0 0 0 5.52 31.16c1.021.395 2.181.665 3.884.744 1.707.08 2.249.096 6.596.096s4.889-.02 6.596-.096c1.703-.08 2.864-.349 3.884-.744a7.867 7.867 0 0 0 2.835-1.845 7.799 7.799 0 0 0 1.845-2.835c.395-1.02.665-2.181.744-3.884.08-1.707.096-2.249.096-6.596s-.02-4.889-.096-6.596c-.08-1.703-.349-2.865-.744-3.884a7.859 7.859 0 0 0-1.845-2.835A7.786 7.786 0 0 0 26.48.84C25.46.444 24.299.175 22.596.096 20.889.016 20.347 0 16 0zm0 2.88c4.271 0 4.78.021 6.467.095 1.56.073 2.407.332 2.969.553.749.289 1.28.636 1.843 1.195a4.95 4.95 0 0 1 1.195 1.841c.219.563.48 1.409.551 2.969.076 1.688.093 2.195.093 6.467s-.02 4.78-.099 6.467c-.081 1.56-.341 2.407-.561 2.969-.299.749-.639 1.28-1.199 1.843a5.002 5.002 0 0 1-1.84 1.195c-.56.219-1.42.48-2.98.551-1.699.076-2.199.093-6.479.093-4.281 0-4.781-.02-6.479-.099-1.561-.081-2.421-.341-2.981-.561-.759-.299-1.28-.639-1.839-1.199a4.867 4.867 0 0 1-1.2-1.84c-.22-.56-.479-1.42-.56-2.98-.06-1.68-.081-2.199-.081-6.459 0-4.261.021-4.781.081-6.481.081-1.56.34-2.419.56-2.979.28-.76.639-1.28 1.2-1.841.559-.559 1.08-.919 1.839-1.197.56-.221 1.401-.481 2.961-.561 1.7-.06 2.2-.08 6.479-.08zm0 4.904A8.215 8.215 0 0 0 7.784 16c0 4.54 3.68 8.216 8.216 8.216 4.54 0 8.216-3.68 8.216-8.216 0-4.54-3.68-8.216-8.216-8.216zm0 13.549c-2.947 0-5.333-2.387-5.333-5.333s2.387-5.333 5.333-5.333 5.333 2.387 5.333 5.333-2.387 5.333-5.333 5.333zM26.461 7.46a1.921 1.921 0 0 1-3.84 0 1.92 1.92 0 0 1 3.84 0z"
    />
  </svg>
);
const Memo = memo(SvgInstagram);
export default Memo;
