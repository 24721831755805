import { MaybeWrap, UniversalLink } from '@plone/volto/components';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import calcFlagNum from '../../helpers/calcFlagNum';
import flags from '../../images';
import styles from './CardListCountries.module.less';

const messages = defineMessages({
  contrysFlag: {
    id: "country's flag",
    defaultMessage: "{country}'s flag",
  },
});

const CardListCountries = (props) => {
  const { data, isEditMode, addLinks } = props;
  const intl = useIntl();

  return (
    <div className={styles.root} style={calcFlagNum(data?.length || 1)}>
      {data
        .reduce((prev, curr) => {
          return ['Brasil', 'Brazil'].includes(curr.name)
            ? [curr, ...prev]
            : [...prev, curr];
        }, [])
        .map((countries, index) => (
          <div key={index} className={styles.card}>
            <MaybeWrap
              condition={!isEditMode && addLinks}
              as={UniversalLink}
              href={countries.url}
              target="_blank"
            >
              <img
                src={flags[countries.flag]}
                alt={intl.formatMessage(messages.contrysFlag, {
                  country: countries.name,
                })}
              />
              <span className={styles.title}>{countries.name}</span>
            </MaybeWrap>
          </div>
        ))}
    </div>
  );
};

CardListCountries.propTypes = {
  data: PropTypes.any,
  addLinks: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default CardListCountries;
