import { isInternalURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { NavLink } from 'react-router-dom';

const MenuLink = ({ href, className, onClick, lang, children }) => {
  if (isInternalURL(href) || href === '') {
    const { settings } = config;

    return (
      <NavLink
        to={href === '' ? '/' : href}
        key={href}
        // className="item"
        // activeClassName="active"
        exact={settings.isMultilingual ? href === `/${lang}` : href === ''}
        className={className}
        onClick={onClick}
      >
        {children}
      </NavLink>
    );
  } else {
    return (
      <a
        href={href}
        key={href}
        // className="item"

        rel="noopener noreferrer"
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }
};

export default MenuLink;
