import PropTypes from 'prop-types';
import { SidebarPortal } from '@plone/volto/components';
import ButtonData from './ButtonData';
import ButtonBody from './ButtonBody';

const Edit = (props) => {
  const { data, block, selected, intl } = props;

  return (
    <>
      <ButtonBody data={data} id={block} intl={intl} isEditMode />
      <SidebarPortal selected={selected}>
        <ButtonData key={block} {...props} data={data} block={block} />
      </SidebarPortal>
    </>
  );
};

Edit.propTypes = {
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.bool,
};

export default Edit;
