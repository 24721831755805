/**
 * Componente Customizado para Detalhes de Eventos do react-big-calendar.
 * Este componente compõe a visualização de eventos de data, incluindo rótulos e ações detalhadas.
 * Utiliza o subcomponente Modal para renderizar detalhes do evento em uma janela modal.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {event} props.event - Propriedades do evento do calendário.
 * @returns {JSX.Element} - Representação do componente CustomEventWrapper.
 *
 * @example
 * // Exemplo de uso do componente CustomEventWrapper
 * const MyCalendar = () => {
 *   const events = [...]; // Array de eventos
 *   return (
 *     <Calendar
 *       events={events}
 *       components={{
 *         eventWrapper: CustomEventWrapper,
 *       }}
 *     />
 *   );
 * };
 */

import { CityLink, TrackTag } from '@package/components';
// import { accessibleOnClick } from '@package/helpers';
import { Icon } from '@package/icons';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

const CustomEventWrapper = ({ event, continuesAfter, continuesPrior }) => {
  const { city, start, end, title, track } = event;
  const [open, setOpen] = useState(false);

  function converterData(dataInicio, dataFim) {
    const diaInicio = moment(dataInicio).format('D');
    const diaFim = moment(dataFim).format('D');
    const mesInicio = moment(dataInicio).format('MMMM');
    const ano = moment(dataInicio).format('YYYY');

    let mesDescription;
    if (moment(dataInicio).isSame(dataFim, 'month')) {
      mesDescription = mesInicio;
    } else {
      const mesFim = moment(dataFim).format('MMMM');
      mesDescription = `${mesInicio} - ${mesFim}`;
    }

    return `${mesDescription} ${diaInicio} - ${diaFim}, ${ano}`;
  }

  const eventClass = cx('containerEvents', {
    'rbc-event-continues-after': continuesAfter,
    'rbc-event-continues-prior': continuesPrior,
  });

  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>Events details</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Header>{title}</Header>
            <div className="linkCity">
              <Icon icon="Calendar" size="18px" />
              <div className="linkCityLink">
                <span>{converterData(start, end)}</span>
              </div>
            </div>
            {city && (
              <div className="linkCity">
                <CityLink name={city} iconSize={18} />
              </div>
            )}
            <TrackTag track={track} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Sair
          </Button>
        </Modal.Actions>
      </Modal>
      <div
        className={eventClass}
        // {...accessibleOnClick((e) => setOpen(true))}
      >
        <div className="subContainerEvents">
          <div className="textEventsTitle">
            <span className={cx('badge', _.camelCase(track))}></span>
            <span className="textEventsSpan">{title}</span>
            {event?.city && (
              <div className="containerEventsCity">
                <Icon icon="MapPin" size="12px" />
                <span className="textEventsDate">{event?.city}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

CustomEventWrapper.propTypes = {
  event: PropTypes.object,
};

export default CustomEventWrapper;
