import africanunion from './flags/africanunion.svg';
import angola from './flags/angola.svg';
import argentina from './flags/argentina.svg';
import australia from './flags/australia.svg';
import brazil from './flags/brazil.svg';
import canada from './flags/canada.svg';
import china from './flags/china.svg';
import egypt from './flags/egypt.svg';
import europeanunion from './flags/europeanunion.svg';
import france from './flags/france.svg';
import germany from './flags/germany.svg';
import india from './flags/india.svg';
import indonesia from './flags/indonesia.svg';
import italy from './flags/italy.svg';
import japan from './flags/japan.svg';
import mexico from './flags/mexico.svg';
import nigeria from './flags/nigeria.svg';
import norway from './flags/norway.svg';
import portugal from './flags/portugal.svg';
import russia from './flags/russia.svg';
import saudiarabia from './flags/saudiarabia.svg';
import singapore from './flags/singapore.svg';
import southafrica from './flags/southafrica.svg';
import republicofkorea from './flags/republicofkorea.svg';
import spain from './flags/spain.svg';
import turkey from './flags/turkey.svg';
import unitedarabemirates from './flags/unitedarabemirates.svg';
import unitedkingdom from './flags/unitedkingdom.svg';
import unitedstates from './flags/unitedstates.svg';

const flags = {
  africanunion,
  angola,
  argentina,
  australia,
  brazil,
  canada,
  china,
  egypt,
  europeanunion,
  france,
  germany,
  india,
  indonesia,
  italy,
  japan,
  mexico,
  nigeria,
  norway,
  portugal,
  russia,
  saudiarabia,
  singapore,
  southafrica,
  republicofkorea,
  spain,
  turkey,
  unitedarabemirates,
  unitedkingdom,
  unitedstates,
};

export default flags;
