import { SidebarPortal } from '@plone/volto/components';
import { map } from 'lodash';
import { v4 as uuid } from 'uuid';
import View from './CarouselView';
import Sidebar from './Sidebar';

const Edit = (props) => {
  const { onChangeBlock, block, selected } = props;
  const data = {
    // columns: map([1, 2, 3, 4], () => ({ '@id': uuid() })), // Padrao do componente para 04 slides
    columns: map([1], () => ({ '@id': uuid() })),
    ...props.data,
  };
  if (!props.data.columns) {
    onChangeBlock(block, data);
  }

  return (
    <>
      <div className="ui container">
        <View {...props} isEditMode />
      </div>
      <SidebarPortal selected={selected}>
        <Sidebar
          {...props}
          data={data}
          selected={selected}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};

export default Edit;
