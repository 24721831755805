import { memo } from 'react';
const SvgBellOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="bell-off_svg__feather bell-off_svg__feather-bell-off"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M13.73 21a2 2 0 0 1-3.46 0M18.63 13A17.89 17.89 0 0 1 18 8M6.26 6.26A5.86 5.86 0 0 0 6 8c0 7-3 9-3 9h14M18 8a6 6 0 0 0-9.33-5M1 1l22 22" />
  </svg>
);
const Memo = memo(SvgBellOff);
export default Memo;
