import { defineMessages } from 'react-intl';
import iconSvg from './icons/section-title.svg';
import SectionTitleView from './View';
import SectionTitleEdit from './Edit';

defineMessages({
  sectionTitle: {
    id: 'Section title',
    defaultMessage: 'Section title',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.sectionTitleBlock = {
    id: 'sectionTitleBlock',
    title: 'Section title',
    group: 'text',
    icon: iconSvg,
    view: SectionTitleView,
    edit: SectionTitleEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
