import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './LogoG20.module.less';
import { LogoFull, LogoSimple } from './variations';

const LogoG20 = ({
  titleId,
  title,
  invert = false,
  'invert-text': invertText = false,
  width,
  height,
  className,
  full,
}) => {
  const classes = cx(
    {
      [styles.logo]: !invert && !invertText,
      [styles.invert]: invert,
      [styles.text]: invertText,
    },
    className,
  );

  const size = {};
  if (width) {
    size.width = width;
  }
  if (height) {
    size.height = height;
  }

  return full ? (
    <LogoFull titleId={titleId} title={title} size={size} className={classes} />
  ) : (
    <LogoSimple
      titleId={titleId}
      title={title}
      size={size}
      className={classes}
    />
  );
};

LogoG20.propTypes = {
  titleId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  invert: PropTypes.bool,
  full: PropTypes.bool,
  'invert-text': PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

export default LogoG20;
