import { defineMessages } from 'react-intl';

const messages = defineMessages({
  /**
   * Members
   * */
  brazil: {
    id: 'Brazil',
    defaultMessage: 'Brazil',
  },
  africanunion: {
    id: 'African union',
    defaultMessage: 'African union',
  },
  argentina: {
    id: 'Argentina',
    defaultMessage: 'Argentina',
  },
  australia: {
    id: 'Australia',
    defaultMessage: 'Australia',
  },
  canada: {
    id: 'Canada',
    defaultMessage: 'Canada',
  },
  europeanunion: {
    id: 'European union',
    defaultMessage: 'European union',
  },
  china: {
    id: 'China',
    defaultMessage: 'China',
  },
  france: {
    id: 'France',
    defaultMessage: 'France',
  },
  germany: {
    id: 'Germany',
    defaultMessage: 'Germany',
  },
  india: {
    id: 'India',
    defaultMessage: 'India',
  },
  indonesia: {
    id: 'Indonesia',
    defaultMessage: 'Indonesia',
  },
  italy: {
    id: 'Italy',
    defaultMessage: 'Italy',
  },
  japan: {
    id: 'Japan',
    defaultMessage: 'Japan',
  },
  mexico: {
    id: 'Mexico',
    defaultMessage: 'Mexico',
  },
  saudiarabia: {
    id: 'Saudi Arabia',
    defaultMessage: 'Saudi Arabia',
  },
  republicofkorea: {
    id: 'Republic of Korea',
    defaultMessage: 'Republic of Korea',
  },
  southafrica: {
    id: 'South Africa',
    defaultMessage: 'South Africa',
  },
  russia: {
    id: 'Russia',
    defaultMessage: 'Russia',
  },
  turkey: {
    id: 'Türkiye',
    defaultMessage: 'Türkiye',
  },
  unitedkingdom: {
    id: 'United Kingdom',
    defaultMessage: 'United Kingdom',
  },
  unitedstates: {
    id: 'United States of America',
    defaultMessage: 'United States of America',
  },
  /**
   * Invitees
   * */
  angola: {
    id: 'Angola',
    defaultMessage: 'Angola',
  },
  egypt: {
    id: 'Egypt',
    defaultMessage: 'Egypt',
  },
  unitedarabemirates: {
    id: 'United Arab Emirates',
    defaultMessage: 'United Arab Emirates',
  },
  spain: {
    id: 'Spain',
    defaultMessage: 'Spain',
  },
  singapore: {
    id: 'Singapore',
    defaultMessage: 'Singapore',
  },
  nigeria: {
    id: 'Nigeria',
    defaultMessage: 'Nigeria',
  },
  portugal: {
    id: 'Portugal',
    defaultMessage: 'Portugal',
  },
  norway: {
    id: 'Norway',
    defaultMessage: 'Norway',
  },
});

const orgs = {
  'pt-BR': [
    {
      name: 'Banco Interamericano de Desenvolvimento',
      url: 'https://www.iadb.org/pt-br',
    },
    {
      name: 'Banco Mundial',
      url: 'https://www.worldbank.org/pt/country/brazil',
    },
    {
      name: 'Banco de Desenvolvimento da América Latina e Caribe',
      url: 'https://www.caf.com/pt/',
    },
    {
      name: 'Organização das Nações Unidas para a Alimentação e a Agricultura',
      url: 'https://www.fao.org/brasil/pt/',
    },
    {
      name: 'Fundo Monetário Internacional',
      url: 'https://www.imf.org/en/home',
    },
    { name: 'New Development Bank', url: 'https://www.ndb.int/' },
    {
      name: 'Organização Internacional do Trabalho',
      url: 'https://www.ilo.org/brasilia/lang--es/index.htm',
    },
    { name: 'World Trade Organization', url: 'https://www.wto.org/index.htm' },
    { name: 'World Health Organization', url: 'https://www.who.int/' },
    { name: 'Nações Unidas', url: 'https://brasil.un.org/pt-br' },
    {
      name: 'United Nations Conference on Trade and Development',
      url: 'https://unctad.org/',
    },
    { name: 'Unesco', url: 'https://www.unesco.org/en' },
  ],

  en: [
    {
      name: 'Inter-American Development Bank',
      url: 'https://www.iadb.org/en',
    },
    { name: 'The World Bank', url: 'https://www.worldbank.org/en/home' },
    {
      name: 'Development Bank of the Latin America and The Caribbean',
      url: 'https://www.caf.com/en/',
    },
    {
      name: 'Food and Agriculture Organization of the United Nations',
      url: 'https://www.fao.org/home/en/',
    },
    {
      name: 'International Monetary Fund',
      url: 'https://www.imf.org/en/home',
    },
    { name: 'New Development Bank', url: 'https://www.ndb.int/' },
    {
      name: 'International Labour Organization',
      url: 'https://www.ilo.org/global/lang--en/index.htm',
    },
    {
      name: 'World Trade Organization',
      url: 'https://www.wto.org/index.htm',
    },
    { name: 'World Health Organization', url: 'https://www.who.int/' },
    { name: 'United Nations', url: 'https://www.un.org/en/' },
    {
      name: 'United Nations Conference on Trade and Development',
      url: 'https://unctad.org/',
    },
    { name: 'Unesco', url: 'https://www.unesco.org/en' },
  ],

  es: [
    {
      name: 'Banco Interamericano de Desarrollo',
      url: 'https://www.iadb.org/es',
    },
    { name: 'Banco Mundial', url: 'https://www.bancomundial.org/es/home' },
    {
      name: 'Banco de Desarrollo de América Latina y el Caribe',
      url: 'https://www.caf.com/',
    },
    {
      name: 'Organización de las Naciones Unidas para la Alimentación y la Agricultura',
      url: 'https://www.fao.org/home/es',
    },
    {
      name: 'Fondo Monetary Internacional',
      url: 'https://www.imf.org/es/home',
    },
    { name: 'New Development Bank', url: 'https://www.ndb.int/' },
    {
      name: 'Organización Internacional del Trabajo',
      url: 'https://www.ilo.org/global/lang--es/index.htm',
    },
    {
      name: 'Organización Mundial del Comercio',
      url: 'https://www.wto.org/indexsp.htm',
    },
    {
      name: 'Organización Mundial de la Salud',
      url: 'https://www.who.int/es/',
    },
    { name: 'Naciones Unidas', url: 'https://www.un.org/es' },
    {
      name: 'Conferencia de las Naciones Unidas sobre Comercio y Desarrollo',
      url: 'https://unctad.org/es',
    },
    { name: 'Unesco', url: 'https://www.unesco.org/es' },
  ],
};

const linksList = (type, intl) => {
  switch (type) {
    case 'membros':
      return [
        {
          name: intl.formatMessage(messages.brazil),
          flag: 'brazil',
          url: 'https://www.gov.br/planalto/en',
        },
        {
          name: intl.formatMessage(messages.africanunion),
          flag: 'africanunion',
          url: 'https://au.int/',
        },
        {
          name: intl.formatMessage(messages.argentina),
          flag: 'argentina',
          url: 'https://www.argentina.gob.ar/',
        },
        {
          name: intl.formatMessage(messages.australia),
          flag: 'australia',
          url: 'https://www.australia.gov.au/',
        },
        {
          name: intl.formatMessage(messages.canada),
          flag: 'canada',
          url: 'https://www.canada.ca/en.html',
        },
        {
          name: intl.formatMessage(messages.china),
          flag: 'china',
          url: 'http://www.gov.cn/',
        },
        {
          name: intl.formatMessage(messages.europeanunion),
          flag: 'europeanunion',
          url: 'https://european-union.europa.eu/index_en',
        },
        {
          name: intl.formatMessage(messages.france),
          flag: 'france',
          url: 'https://www.elysee.fr/en/',
        },
        {
          name: intl.formatMessage(messages.germany),
          flag: 'germany',
          url: 'https://www.bundesregierung.de/breg-en',
        },
        {
          name: intl.formatMessage(messages.india),
          flag: 'india',
          url: 'https://www.india.gov.in/',
        },
        {
          name: intl.formatMessage(messages.indonesia),
          flag: 'indonesia',
          url: 'https://www.indonesia.go.id/',
        },
        {
          name: intl.formatMessage(messages.italy),
          flag: 'italy',
          url: 'https://www.governo.it/en',
        },
        {
          name: intl.formatMessage(messages.japan),
          flag: 'japan',
          url: 'https://www.japan.go.jp/',
        },
        {
          name: intl.formatMessage(messages.mexico),
          flag: 'mexico',
          url: 'https://www.gob.mx/',
        },
        {
          name: intl.formatMessage(messages.russia),
          flag: 'russia',
          url: 'http://government.ru/en/',
        },
        {
          name: intl.formatMessage(messages.saudiarabia),
          flag: 'saudiarabia',
          url: 'https://www.my.gov.sa/wps/portal/snp/main',
        },
        {
          name: intl.formatMessage(messages.republicofkorea),
          flag: 'republicofkorea',
          url: 'https://www.korea.net/',
        },
        {
          name: intl.formatMessage(messages.southafrica),
          flag: 'southafrica',
          url: 'https://www.gov.za/',
        },
        {
          name: intl.formatMessage(messages.turkey),
          flag: 'turkey',
          url: 'https://www.turkiye.gov.tr/',
        },
        {
          name: intl.formatMessage(messages.unitedkingdom),
          flag: 'unitedkingdom',
          url: 'https://www.gov.uk/',
        },
        {
          name: intl.formatMessage(messages.unitedstates),
          flag: 'unitedstates',
          url: 'https://www.usa.gov/',
        },
      ];
    case 'convidados':
      return [
        {
          name: intl.formatMessage(messages.angola),
          flag: 'angola',
          url: 'https://governo.gov.ao',
        },
        {
          name: intl.formatMessage(messages.egypt),
          flag: 'egypt',
          url: 'https://www.presidency.eg/en',
        },
        {
          name: intl.formatMessage(messages.unitedarabemirates),
          flag: 'unitedarabemirates',
          url: 'https://u.ae/en#',
        },
        {
          name: intl.formatMessage(messages.spain),
          flag: 'spain',
          url: 'https://www.lamoncloa.gob.es',
        },
        {
          name: intl.formatMessage(messages.singapore),
          flag: 'singapore',
          url: 'https://www.gov.sg',
        },
        {
          name: intl.formatMessage(messages.nigeria),
          flag: 'nigeria',
          url: 'https://statehouse.gov.ng',
        },
        {
          name: intl.formatMessage(messages.portugal),
          flag: 'portugal',
          url: 'https://www.portugal.gov.pt',
        },
        {
          name: intl.formatMessage(messages.norway),
          flag: 'norway',
          url: 'https://www.regjeringen.no/en',
        },
      ];

    case 'organizacoes':
      return orgs[intl?.locale] || orgs.en;
    default:
      return [];
  }
};

export default linksList;
