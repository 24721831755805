import { IconButton } from '@package/components';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import FilterCheckbox from './FilterCheckbox';

const GroupedCheckbox = ({ name, options, values, onFilter }) => {
  const [open, setOpen] = useState(false);

  if (!options || !options.length) {
    return null;
  }

  return (
    <div className="rbc-filterbar-group">
      <div className="rbc-filterbar-group-title">
        <span>{name}</span>
        <IconButton
          icon={open ? 'ChevronUp' : 'ChevronDown'}
          dark
          onClick={() => setOpen(!open)}
        />
      </div>

      <AnimateHeight animateOpacity duration={300} height={open ? 'auto' : 0}>
        {options.map((option) => {
          return (
            <FilterCheckbox
              key={option.id}
              label={option.name}
              checked={values.includes(option.name)}
              onChange={(e, data) => {
                if (data.checked) {
                  onFilter([...values, option.name]);
                } else {
                  onFilter(values.filter((val) => val !== option.name));
                }
              }}
            />
          );
        })}
      </AnimateHeight>
    </div>
  );
};

export default GroupedCheckbox;
