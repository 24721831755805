import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './BackToTop.module.less';
import { Icon } from '@package/icons';

const BackToTop = ({ 'show-offset': showOffset, alt }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      setShow(window.scrollY > showOffset);
    };

    document.addEventListener('scroll', toggleVisibility);

    return () => document.removeEventListener('scroll', toggleVisibility);
  });

  const scrollToTop = () => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.root}>
      <button title={alt} onClick={scrollToTop}>
        <Icon icon="ChevronUp" />
      </button>
    </div>
  );
};

BackToTop.propTypes = {
  offset: PropTypes.number,
  alt: PropTypes.string,
};

BackToTop.defaultProps = {
  'show-offset': 200,
  alt: 'Voltar ao topo da página',
};

export default BackToTop;
