import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';

import { Icon } from '@package/icons';
import styles from './Breadcrumbs.module.less';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
});

const BreadcrumbsComponent = ({ pathname }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const items = useSelector((state) => state.breadcrumbs.items, shallowEqual);
  const root = useSelector((state) => state.breadcrumbs.root);

  useEffect(() => {
    if (!hasApiExpander('breadcrumbs', getBaseUrl(pathname))) {
      dispatch(getBreadcrumbs(getBaseUrl(pathname)));
    }
  }, [dispatch, pathname]);

  if (!items || !items.length) {
    return null;
  }

  return (
    <Container>
      <div className={styles.items}>
        <Link
          to={root || '/'}
          className="section"
          title={intl.formatMessage(messages.home)}
        >
          {intl.formatMessage(messages.home)}
        </Link>

        {items.map((item, index, items) => [
          <Icon icon="ChevronRight" key={`${item.url}-icon`} />,
          index < items.length - 1 ? (
            <Link key={item.url} to={item.url}>
              {item.title}
            </Link>
          ) : (
            <span key={item.url}>{item.title}</span>
          ),
        ])}
      </div>
    </Container>
  );
};

BreadcrumbsComponent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default BreadcrumbsComponent;
