/**
 * View HeadTitle block.
 * @module volto-slate/blocks/HeadTitle/HeadTitleBlockView
 */

import PropTypes from 'prop-types';

/**
 * View HeadTitle block component.
 * @class View
 * @extends Component
 */
const View = ({ properties, metadata }) => {
  const headTitle = (metadata || properties)['head_title'];

  return headTitle ? (
    <div className="sectionSubtitle documentHeadTitle">{headTitle}</div>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  metadata: PropTypes.objectOf(PropTypes.any),
};

export default View;
