import { memo } from 'react';
const SvgAlertTriangle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="alert-triangle_svg__feather alert-triangle_svg__feather-alert-triangle"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0zM12 9v4M12 17h.01" />
  </svg>
);
const Memo = memo(SvgAlertTriangle);
export default Memo;
