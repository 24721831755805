import { defineMessages } from 'react-intl';

import News from './News';
import GroupedByDate from './GroupedByDate';

defineMessages({
  newsVariation: {
    id: 'Images',
    defaultMessage: 'Images',
  },
  groupedByDateVariation: {
    id: 'Grouped by date',
    defaultMessage: 'Grouped by date',
  },
});

const listingVariations = [
  {
    id: 'news',
    isDefault: false,
    title: 'Images',
    template: News,
  },
  {
    id: 'groupedByDate',
    isDefault: false,
    title: 'Grouped by date',
    template: GroupedByDate,
  },
];

export default listingVariations;
