/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

export const GET_G20_EVENTS = 'GET_G20_EVENTS';
export const GET_G20_TRACKS = 'GET_G20_TRACKS';
export const GET_G20_WORKGROUPS = 'GET_G20_WORKGROUPS';
export const GET_G20_CITIES = 'GET_G20_CITIES';
export const GET_G20_SOCIAL_NETWORKS = 'GET_G20_SOCIAL_NETWORKS';
export const GET_G20_FOOTER_LINKS = 'GET_G20_FOOTER_LINKS';
