import { MaybeWrap, UniversalLink } from '@plone/volto/components';
import Video from '@plone/volto/components/manage/Blocks/Video/Body';
import { flattenToAppURL } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import { Message } from 'semantic-ui-react';
import { getTeaserImageURL } from './helpers';
import { Icon } from '@package/icons';

const messages = defineMessages({
  YouCanAddItems: {
    id: 'You can add items using the sidebar',
    defaultMessage: 'You can add items using the sidebar',
    // defaultMessage: 'O carrossel está vazio. Por favor, adicione algum item a ele usando a barra lateral'
  },
  PrivateContentOrPublishedAfterAdd: {
    id: 'This content is private or has been published after it was added here',
    defaultMessage:
      'This content is private or has been published after it was added here',
  },
});

const CarouselBody = ({ data, isEditMode }) => {
  const intl = useIntl();
  const href = data.href?.[0];
  const imageType = href && href?.image_field && href.image_field;
  const image = data.preview_image?.[0];
  const videoUrl = data?.url_video;

  if (!href && !videoUrl) {
    return isEditMode ? (
      <Message>
        <div className="slide-content empty">
          <div className="imgs">
            <span>
              <Icon icon="Image" size={42} />
            </span>
            <span>
              <Icon icon="Youtube" size={42} />
            </span>
            <span>
              <Icon icon="FileText" size={42} />
            </span>
          </div>
          <p>{intl.formatMessage(messages.YouCanAddItems)}</p>
        </div>
      </Message>
    ) : null;
  }

  if (videoUrl) {
    return (
      <div className={`slide-content video play-${data?.playAlign}`}>
        <Video
          className="video"
          data={{
            '@type': 'video',
            url: videoUrl,
            showPreviewImage: true,
            preview_image: data?.preview_image,
          }}
          // defaultActive={false}
        />
      </div>
    );
  }

  const isPublished = !data?.review_state || data?.review_state === 'published';

  if (!isPublished && !isEditMode) {
    return null;
  }

  return (
    <div className="slide-content image">
      {!isPublished && (
        <div className="error-private">
          {intl.formatMessage(messages.PrivateContentOrPublishedAfterAdd)}
        </div>
      )}

      <MaybeWrap
        condition={!isEditMode}
        as={UniversalLink}
        href={href['@id']}
        target={data.openLinkInNewTab ? '_blank' : null}
        tabIndex={-1}
      >
        <MaybeWrap condition={isEditMode} as="span">
          {(href.hasPreviewImage || //Although we do not get this attribute any longer from the schema, we still keep it to ensure old content with that attribute still works
            image ||
            imageType === 'image' ||
            imageType === 'preview_image') && (
            <div className="image-wrapper">
              <img
                aria-hidden="true"
                src={flattenToAppURL(getTeaserImageURL(href, image, imageType))}
                alt=""
                loading="lazy"
              />
            </div>
          )}
          <div className="info">
            {data?.title ? <h3>{data?.title}</h3> : null}
            {data?.description ? <p>{data?.description}</p> : null}
          </div>
        </MaybeWrap>
      </MaybeWrap>
    </div>
  );
};

export default CarouselBody;
