import PropTypes from 'prop-types';
import { SidebarPortal } from '@plone/volto/components';
import CalendarData from './CalendarData';
import CalendarBody from './CalendarBody';

const Edit = (props) => {
  const { data, block, selected } = props;

  return (
    <>
      <CalendarBody data={data} id={block} isEditMode />
      <SidebarPortal selected={selected}>
        <CalendarData key={block} {...props} data={data} block={block} />
      </SidebarPortal>
    </>
  );
};

Edit.propTypes = {
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.bool,
};

export default Edit;
