import { memo } from 'react';
const SvgClipboard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="clipboard_svg__feather clipboard_svg__feather-clipboard"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
    <rect width={8} height={4} x={8} y={2} rx={1} ry={1} />
  </svg>
);
const Memo = memo(SvgClipboard);
export default Memo;
