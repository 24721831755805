import { memo } from 'react';
const SvgSliders = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="sliders_svg__feather sliders_svg__feather-sliders"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M4 21v-7M4 10V3M12 21v-9M12 8V3M20 21v-5M20 12V3M1 14h6M9 8h6M17 16h6" />
  </svg>
);
const Memo = memo(SvgSliders);
export default Memo;
