import { defineMessages } from 'react-intl';

const messages = defineMessages({
  divider: {
    id: 'Divider',
    defaultMessage: 'Divider',
  },
  spacing: {
    id: 'Spacing',
    defaultMessage: 'Spacing',
  },
  small: {
    id: 'Small [male]',
    defaultMessage: 'Small',
  },
  medium: {
    id: 'Medium [male]',
    defaultMessage: 'Medium',
  },
  large: {
    id: 'Large',
    defaultMessage: 'Large',
  },
  showLine: { id: 'Show line', defaultMessage: 'Show line' },
});

export const schemaDivider = ({ intl }) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.divider),
        fields: ['spacing', 'showLine'],
      },
    ],
    properties: {
      spacing: {
        title: intl.formatMessage(messages.spacing),
        noValueOption: false,
        default: 'md',
        type: 'string',
        factory: 'Choice',
        choices: [
          ['sm', intl.formatMessage(messages.small)],
          ['md', intl.formatMessage(messages.medium)],
          ['lg', intl.formatMessage(messages.large)],
        ],
      },
      showLine: {
        title: intl.formatMessage(messages.showLine),
        type: 'boolean',
      },
    },
  };
};

export default schemaDivider;
