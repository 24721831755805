import AnimateHeight from 'react-animate-height';
import cx from 'classnames';
import { Icon } from '@package/icons';
import { defineMessages, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getG20Cities, getG20Tracks, getG20Workgroups } from '@package/actions';
import { accessibleOnClick } from '@package/helpers';
import GroupedCheckbox from './GroupedCheckbox';
import TrackCheckbox from './TrackCheckbox';

// TODO: Escrever testes completos para os componentes do Filterbar

const messages = defineMessages({
  loadingFilters: {
    id: 'Loading filters…',
    defaultMessage: 'Loading filters…',
  },
  Filters: { id: 'Filters', defaultMessage: 'Filters' },
  Cities: { id: 'Cities', defaultMessage: 'Cities' },
});

const FilterbarInner = ({ intl, tracks, cities, current, onFilter }) => (
  <div className="rbc-filterbar-inner">
    {tracks.map((track) => {
      return (
        <TrackCheckbox
          track={track}
          key={track.id}
          values={current}
          onFilter={(selection) => {
            onFilter(selection);
          }}
        />
      );
    })}

    <GroupedCheckbox
      name={intl.formatMessage(messages.Cities)}
      options={cities}
      values={current.cities}
      onFilter={(selection) => {
        onFilter({ ...current, cities: selection });
      }}
    />
  </div>
);

const Filterbar = ({
  className,
  isEditMode,
  current = {
    tracks: [],
    cities: [],
  },
  onFilter,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getG20Tracks());
    dispatch(getG20Cities());
    dispatch(getG20Workgroups());
  }, [dispatch]);

  const tracksRes = useSelector((state) => state.g20Tracks);
  const workgroupsRes = useSelector((state) => state.g20Workgroups);
  const citiesRes = useSelector((state) => state.g20Cities);

  if (tracksRes.loading || citiesRes.loading || workgroupsRes.loading) {
    return <div>{intl.formatMessage(messages.loadingFilters)}</div>;
  }

  if (
    tracksRes.error ||
    !tracksRes.loaded ||
    workgroupsRes.error ||
    !workgroupsRes.loaded ||
    citiesRes.error ||
    !citiesRes.loaded
  ) {
    // console.log(
    //   tracksRes.error ,
    //   citiesRes.error );
    return isEditMode ? <div>Erro...</div> : null;
  }

  const tracks = tracksRes.data.map((track) => {
    return {
      id: track.Id,
      name: track.Label,
      workgroups: workgroupsRes.data.reduce((prev, group) => {
        if (group.TrackId === track.Id) {
          return [
            ...prev,
            {
              id: group.Id,
              code: group.Code,
              name: group.Label,
              trackId: group.TrackId,
              trackName: track.Label,
            },
          ];
        }

        return prev;
      }, []),
    };
  }, []);

  const cities = citiesRes.data.map((city) => {
    return { id: city.Id, name: city.Name };
  });

  const innerProps = { tracks, cities, current, onFilter, intl };

  const title = (
    <div>
      <Icon icon="Sliders" size={16} />
      <span>{intl.formatMessage(messages.Filters)}</span>
    </div>
  );

  return (
    <div className={cx('rbc-filterbar', className)}>
      <div
        className="rbc-filterbar-title"
        {...accessibleOnClick(() => setOpen(!open))}
      >
        {title}
        <Icon
          icon={open ? 'ChevronUp' : 'ChevronDown'}
          size={12}
          className="arrow"
        />
      </div>
      <div className="rbc-filterbar-title-desktop">{title}</div>

      <div className="rbc-filterbar-desktop">
        <FilterbarInner {...innerProps} />
      </div>
      <AnimateHeight
        animateOpacity
        duration={300}
        height={open ? 'auto' : 0}
        className="rbc-filterbar-mobile"
      >
        <FilterbarInner {...innerProps} />
      </AnimateHeight>
    </div>
  );
};
export default Filterbar;
