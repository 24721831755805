import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import { Icon } from '@package/icons';

import styles from './NumbersBody.module.less';

const messages = defineMessages({
  missingRequired: {
    id: 'Fill at least one index',
    defaultMessage: 'Fill at least one index',
  },
});

const buildObject = (id, data) => {
  const {
    [`category${id}`]: category = '',
    [`description${id}`]: description = '',
    [`value${id}`]: value = '',
    [`icon${id}`]: icon = '',
    [`style${id}`]: style = '',
  } = data;

  return {
    category: category.trim(),
    description: description.trim(),
    value: value.trim(),
    icon,
    style,
  };
};

const getIndexes = (data) => {
  return [
    buildObject(1, data),
    buildObject(2, data),
    buildObject(3, data),
  ].filter(
    (index) =>
      !(!index.value && !index.category && !index.description && !index.icon),
  );
};

const NumbersBody = (props) => {
  const { data } = props;
  const { title, subtitle, buttonLabel, buttonUrl } = data;
  const intl = useIntl();

  const indexes = getIndexes(data);

  return (
    <div className={`full-width ${styles.block}`}>
      <div className="ui container">
        {title ? (
          <h2 className="text-center text-pure-0 mt-0 mb-2">{title}</h2>
        ) : null}
        {subtitle ? (
          <p className="text-center text-pure-0 mt-0 mb-2">{subtitle}</p>
        ) : null}

        {!indexes.length ? (
          <div>{intl.formatMessage(messages.missingRequired)}</div>
        ) : (
          <ul className={styles.grid}>
            {indexes.map((index, idx) => (
              <li key={idx}>
                {index.icon ? (
                  <div className={styles.icon}>
                    <span className={index.style}>
                      <Icon icon={index.icon} size={42} />
                    </span>
                  </div>
                ) : null}

                {index.category ? (
                  <div className={styles.cat}>{index.category}</div>
                ) : null}
                {index.value ? (
                  <div className={styles.val}>{index.value}</div>
                ) : null}
                {index.description ? (
                  <div className={styles.label}>{index.description}</div>
                ) : null}
              </li>
            ))}
          </ul>
        )}

        <div className="text-center mt-7">
          {buttonLabel && buttonUrl ? (
            <UniversalLink
              item={{ '@id': buttonUrl }}
              className="br-button secondary dark-mode bg-blue-warm-vivid-80"
            >
              {buttonLabel}
            </UniversalLink>
          ) : null}
        </div>
      </div>
    </div>
  );
};

NumbersBody.propTypes = {
  isEditMode: PropTypes.bool,
};

export default NumbersBody;
