import { defineMessages } from 'react-intl';
import EventsView from './EventsView';
import EventsEdit from './EventsEdit';
// import iconSVG from '@plone/volto/icons/showcase.svg';
import iconSVG from '../../../../theme/assets/icons/event.svg';

defineMessages({
  events: {
    id: 'Events',
    defaultMessage: 'Events',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.eventsBlock = {
    id: 'eventsBlock',
    title: 'Events',
    group: 'portalg20',
    icon: iconSVG,
    view: EventsView,
    edit: EventsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
