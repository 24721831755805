import { memo } from 'react';
const SvgNuvemChuva = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="m8.5 18.003-1.768 1.729C6.26 20.204 6 20.832 6 21.5s.26 1.296.732 1.768S7.832 24 8.5 24s1.296-.26 1.768-.732.732-1.1.732-1.768-.26-1.296-.736-1.771L8.5 18.004Zm1.061 4.557c-.565.567-1.554.566-2.121 0-.283-.283-.439-.66-.439-1.061s.156-.777.436-1.057L8.501 19.4l1.061 1.038c.283.283.439.66.439 1.061s-.156.777-.439 1.061Zm6.939-4.557-1.768 1.729c-.473.472-.732 1.1-.732 1.768s.26 1.296.732 1.768c.473.472 1.1.732 1.768.732s1.295-.26 1.768-.732.732-1.1.732-1.768-.26-1.296-.736-1.771L16.5 18.004Zm1.061 4.557c-.566.566-1.555.566-2.121 0-.283-.283-.439-.66-.439-1.061s.156-.777.436-1.057l1.064-1.042 1.061 1.038c.283.283.439.66.439 1.061s-.156.777-.439 1.061ZM24 12.499c0 2.743-1.55 5.254-3.731 6.553a4.608 4.608 0 0 0-.607-.755l-.03-.03C21.59 17.173 23 14.945 23 12.498c0-3.509-2.856-6.422-6.367-6.493l-.355-.007-.11-.338A6.732 6.732 0 0 0 9.751.998a6.758 6.758 0 0 0-6.75 6.75c0 .776.139 1.551.412 2.304l.141.388-.354.212c-1.377.826-2.2 2.264-2.2 3.847 0 2.247 1.66 4.097 3.814 4.431a4.401 4.401 0 0 0-.501.937C1.85 19.322 0 17.124 0 14.5c0-1.803.869-3.451 2.345-4.484A7.677 7.677 0 0 1 2 7.75C2 3.477 5.477 0 9.75 0a7.729 7.729 0 0 1 7.253 5.023C20.893 5.284 24 8.567 24 12.5Zm-9 2c0-.668-.26-1.296-.736-1.771L12.5 11.003l-1.768 1.729c-.472.472-.732 1.1-.732 1.768s.26 1.296.732 1.768 1.1.732 1.768.732 1.295-.26 1.768-.732.732-1.1.732-1.768Zm-1.439 1.061c-.566.566-1.555.566-2.121 0-.283-.283-.439-.66-.439-1.061s.156-.777.436-1.057l1.064-1.042 1.061 1.038c.283.283.439.66.439 1.061s-.156.777-.439 1.061Z" />
  </svg>
);
const Memo = memo(SvgNuvemChuva);
export default Memo;
