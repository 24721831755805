/**
 * Componente personalizado para o cabeçalho de data do react-big-calendar.
 * Este componente compõe a visualização do cabeçalho de data, incluindo rótulos e ações de detalhamento.
 * Utiliza os subcomponentes Label para renderizar rótulos com ou sem ação de drilldown.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {node} props.label - Rótulo a ser exibido.
 * @param {string} props.drilldownView - Visão associada ao evento de "drilldown".
 * @param {func} props.onDrillDown - Função a ser chamada no evento de "drilldown".
 * @param {bool} props.isOffRange - Indica se a data está fora do intervalo visível.
 * @returns {JSX.Element} - Retorna a representação do componente DateHeader.
 */

import PropTypes from 'prop-types';

const Label = ({ label, drilldownView, onDrillDown }) => {
  if (!drilldownView) {
    return <span>{label}</span>;
  }

  return <div>{label}</div>;
};

Label.propTypes = {
  label: PropTypes.node,
  date: PropTypes.instanceOf(Date),
  drilldownView: PropTypes.string,
  onDrillDown: PropTypes.func,
  isOffRange: PropTypes.bool,
};

const DateHeader = ({ label, drilldownView, onDrillDown }) => {
  return (
    <div className="custom-date-header">
      <div className="dh-item header-right">
        <Label {...{ label, drilldownView, onDrillDown }} />
      </div>
    </div>
  );
};

DateHeader.propTypes = {
  label: PropTypes.node,
  date: PropTypes.instanceOf(Date),
  drilldownView: PropTypes.string,
  onDrillDown: PropTypes.func,
  isOffRange: PropTypes.bool,
};

export default DateHeader;
