import { defineMessages } from 'react-intl';

export default defineMessages({
  Caption: {
    id: 'Caption',
    defaultMessage: 'Caption',
  },
  CaptionHelp: {
    id: 'CaptionHelp',
    defaultMessage: 'Text to be displayed below the image',
  },
  AltTextHint: {
    id: 'Alt text hint',
    defaultMessage: 'Leave empty if the image is purely decorative.',
  },
  AltTextHintLinkText: {
    id: 'Alt text hint link text',
    defaultMessage: 'Describe the purpose of the image.',
  },
});
