/**
 * Componente de Barra de Ferramentas Personalizada para react-big-calendar.
 * Permite navegação entre diferentes modos de visualização, avanço e retrocesso no calendário,
 * e seleção da data atual. Também inclui botões para selecionar modos específicos de visualização.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {string} props.view - A visualização atual do calendário.
 * @param {string[]} props.views - Lista de modos de visualização disponíveis.
 * @param {node} props.label - Rótulo da data exibido na barra de ferramentas.
 * @param {Object} props.localizer - Objeto contendo mensagens localizadas.
 * @param {Date} props.date - Data atual exibida no calendário.
 * @param {Function} props.onNavigate - Função de callback para navegar entre datas.
 * @param {Function} props.onView - Função de callback para alterar a visualização do calendário.
 * @returns {JSX.Element} - Representação do componente CustomToolbar.
 *
 * @example
 * // Exemplo de uso do componente CustomToolbar
 * const MyCalendar = () => {
 *   const events = [...]; // Array de eventos
 *   return (
 *     <Calendar
 *       view="month"
 *       views={['month', 'week', 'day']}
 *       label="November 2023"
 *       localizer={myLocalizer}
 *       date={new Date(2023, 10, 1)}
 *       onNavigate={handleNavigate}
 *       onView={handleViewChange}
 *       components={{
 *         toolbar: CustomToolbar,
 *       }}
 *     />
 *   );
 * };
 */
import { IconButton } from '@package/components';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './CustomToolbar.module.less';

const CustomToolbar = ({
  view,
  views,
  label,
  localizer,
  date,
  onNavigate,
  onView,
}) => {
  let { messages } = localizer;

  const goToBack = () => {
    let mDate = date;
    let newDate;
    if (view === 'month') {
      newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
    } else if (view === 'week') {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() - 7,
        1,
      );
    } else if (view === 'list') {
      newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
    } else {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() - 1,
        1,
      );
    }

    navigate('prev', newDate);
  };

  const goToNext = () => {
    let mDate = date;
    let newDate;
    if (view === 'month') {
      newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
    } else if (view === 'week') {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() + 7,
      );
    } else if (view === 'list') {
      newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
    } else {
      newDate = new Date(mDate.getFullYear() + 1, mDate.getMonth(), 1);
    }

    navigate('next', newDate);
  };

  const goToToday = () => {
    const now = new Date();
    date.setMonth(now.getMonth());
    date.setYear(now.getFullYear());
    date.setDate(now.getDate());
    onNavigate('current');
  };

  const navigate = (action, date) => {
    onNavigate(action, date);
  };

  const showViews = (view) => {
    onView(view);
  };

  const generateDateLabel = () => {
    const dates = moment(date);
    return (
      <span>
        {view === 'year' ? (
          <span className={styles.textMonth}>{label}</span>
        ) : (
          <>
            <b>{dates.format('MMMM')}</b>
            <span className={styles.textMonth}> {dates.format('YYYY')}</span>
          </>
        )}
      </span>
    );
  };

  const viewNamesGroup = (messages) => {
    let viewNames = views;

    const sortedArrayviewNames = viewNames.sort((a, b) => {
      const orderReference = [
        'month',
        'list',
        // 'year'
      ];
      return orderReference.indexOf(a) - orderReference.indexOf(b);
    });

    if (sortedArrayviewNames.length > 1) {
      return sortedArrayviewNames.map((name) => (
        <button
          type="button"
          className={cx(styles.ButtonCalendar, {
            [styles.selected]: view === name,
          })}
          key={name}
          onClick={() => showViews(name)}
        >
          {messages[name]}
        </button>
      ));
    }
  };

  return (
    <div className={styles.container}>
      {/* <div className={styles.containerButtonIcons}>
        <button
          className={styles.ButtonCalendarIcons}
          onClick={() => onView('month')}
        >
          <Icon icon="Calendar" />
        </button>
        <button
          className={styles.ButtonCalendarIcons}
          onClick={() => onView('list')}
        >
          <Icon icon="Calendar" />
        </button>
      </div> */}
      <div className={styles.containerButtonLeft}>
        {viewNamesGroup(messages)}
      </div>

      {view !== 'list' && (
        <div className={styles.containerMonthTile}>
          <IconButton
            dark
            icon="ChevronLeft"
            className={styles.ButtonPrevious}
            id="prev-btn-icon"
            onClick={goToBack}
          />
          {generateDateLabel()}
          <IconButton
            dark
            icon="ChevronRight"
            className={styles.ButtonNext}
            id="next-btn-icon"
            onClick={goToNext}
          />
        </div>
      )}
      <div className={styles.containerButtonRight}>
        {/* <Icon icon="Printer" onClick={() => onView('list')} />
        <span>Print</span> */}

        {view !== 'list' && (
          <button className={styles.ButtonCalendar} onClick={goToToday}>
            {messages.today}
          </button>
        )}
      </div>
    </div>
  );
};

CustomToolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default CustomToolbar;
