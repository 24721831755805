import PropTypes from 'prop-types';
import { SidebarPortal } from '@plone/volto/components';
import CardsData from './CardsData';
import CardsBody from './CardsBody';

const Edit = (props) => {
  const { data, block, selected } = props;

  return (
    <>
      <CardsBody data={data} id={block} isEditMode />
      <SidebarPortal selected={selected}>
        <CardsData key={block} {...props} data={data} block={block} />
      </SidebarPortal>
    </>
  );
};

Edit.propTypes = {
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.bool,
};

export default Edit;
