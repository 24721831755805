import { memo } from 'react';
const SvgSunrise = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="sunrise_svg__feather sunrise_svg__feather-sunrise"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M17 18a5 5 0 0 0-10 0M12 2v7M4.22 10.22l1.42 1.42M1 18h2M21 18h2M18.36 11.64l1.42-1.42M23 22H1M8 6l4-4 4 4" />
  </svg>
);
const Memo = memo(SvgSunrise);
export default Memo;
