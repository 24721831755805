import moment from 'moment';
import { defineMessages, injectIntl } from 'react-intl';

// TODO: Criar componente reusável para listagem e bloco de datas
// TODO: Criar testes unitários

const messages = defineMessages({
  not_saved_yet: {
    id: 'Not saved yet',
    defaultMessage: 'Not saved yet',
  },
  published: {
    id: 'Published',
    defaultMessage: 'Published',
  },
  created: {
    id: 'Created',
    defaultMessage: 'Created',
  },
  modified: {
    id: 'Modified',
    defaultMessage: 'Modified',
  },
  not_published_yet: {
    id: 'Not published yet',
    defaultMessage: 'Not published yet',
  },
});

const DateInfo = ({ item, intl, className = '' }) => {
  // Se a data de modificação for passada, mas a data de criação não, será
  // será exibida somente a data de modificação.
  const { effective, modified, created } = item;
  let modifiedDate;
  let publishDate;

  if (created) {
    const publishDate = effective && effective > created ? effective : null;

    if (modified) {
      if (publishDate) {
        modifiedDate = modified > publishDate ? modified : null;
      } else if (created) {
        modifiedDate = modified > created ? modified : null;
      }
    }
  } else {
    modifiedDate = modified;
  }

  return (
    <div className={`d-sm-flex text-gray-70 ${className}`}>
      {created ? (
        <>
          <div>
            {publishDate
              ? `${intl.formatMessage(messages['published'])} ${moment(
                  publishDate,
                ).fromNow()}`
              : `${intl.formatMessage(messages['created'])} ${moment(
                  created,
                ).fromNow()}`}
          </div>
        </>
      ) : null}
      {modifiedDate ? (
        <>
          {created ? (
            <span className="br-divider vertical d-none d-sm-block mx-baseh " />
          ) : null}
          <div>
            {`${intl.formatMessage(messages['modified'])} ${moment(
              modified,
            ).fromNow()}`}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default injectIntl(DateInfo);
