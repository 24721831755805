import { defineMessages } from 'react-intl';
import iconSvg from './icons/section-subtitle.svg';
import View from './View';
import Edit from './Edit';

defineMessages({
  Header: {
    id: 'Section Subtitle',
    defaultMessage: 'Section Subtitle',
  },
});

const applyConfig = (config) => {
  config.blocks.blocksConfig.SectionSubtitleBlock = {
    id: 'SectionSubtitleBlock',
    title: 'Section Subtitle',
    group: 'text',
    icon: iconSvg,
    view: View,
    edit: Edit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};

export default applyConfig;
