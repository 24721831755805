import { useState, useEffect } from 'react';
import { IconButton } from '@package/components';
import { BodyClass } from '@plone/volto/helpers';
import { injectIntl } from 'react-intl';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { ModalFooter, ModalHeader, buttons, groups } from '../../messages';
import withLgpdCookie from '../withLgpdCookie';
import { Group } from './components';

import { closeManageCookies } from '../../actions';
import '../../theme/modal.less';

const ManageCookies = ({
  intl,
  lgpdCookieReady,
  performanceCookies,
  thirdPartyCookies,
  rejectCookies,
  saveCookies,
}) => {
  const dispatch = useDispatch();
  const { modalOpen } = useSelector((state) => state.manageCookies);

  const [performance, setPerformance] = useState(performanceCookies);
  const [thirdParty, setThirdParty] = useState(thirdPartyCookies);

  useEffect(() => {
    setPerformance(performanceCookies);
    setThirdParty(thirdPartyCookies);
  }, [performanceCookies, thirdPartyCookies]);

  if (!modalOpen || !lgpdCookieReady) {
    return null;
  }

  const handleClose = () => {
    dispatch(closeManageCookies());
  };

  const handleSave = () => {
    saveCookies({ performance, thirdParty });
    handleClose();
  };

  const handleReject = () => {
    rejectCookies();
    handleClose();
  };

  return (
    <>
      <BodyClass className="lgpd-no-scroll" />
      <Portal>
        <div className="lgpd-overlay" />
        <div className="lgpd-modal">
          {/* <button className={`br-button circle small ${}`} >
              <i className="fas fa-times" aria-hidden="true" />
            </button> */}

          <IconButton
            icon="Close"
            className="lgpd-button close"
            title={intl.formatMessage(buttons.closeWithoutSave)}
            onClick={handleClose}
          />

          <div className="header">
            <ModalHeader />
          </div>

          <div className="modal-content">
            <Group
              readonly
              checked={true}
              title={intl.formatMessage(groups.strictlyNecessary.title)}
            >
              <groups.strictlyNecessary.description />
            </Group>

            <Group
              checked={performance}
              handleChange={(value) => setPerformance(value)}
              title={intl.formatMessage(groups.performance.title)}
            >
              <groups.performance.description />
            </Group>

            <Group
              checked={thirdParty}
              handleChange={setThirdParty}
              title={intl.formatMessage(groups.thirdParty.title)}
            >
              <groups.thirdParty.description />
            </Group>

            <ModalFooter />
          </div>

          <div className="divider" />

          <div className="footer">
            <button
              className="lgpd-button"
              data-lgpd-action="denied"
              onClick={handleReject}
            >
              {intl.formatMessage(buttons.rejectCookies)}
            </button>
            <button
              className="lgpd-button"
              data-lgpd-action={performance ? 'granted' : 'denied'}
              onClick={handleSave}
            >
              {intl.formatMessage(buttons.save)}
            </button>
          </div>
        </div>
      </Portal>
    </>
  );
};

export default withLgpdCookie(injectIntl(ManageCookies));
