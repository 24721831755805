import { memo } from 'react';
const SvgDoar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M24 20.5c0 1.93-1.57 3.5-3.5 3.5h-17C1.57 24 0 22.43 0 20.5a3.507 3.507 0 0 1 2.386-3.318c.221.295.455.58.702.853A2.502 2.502 0 0 0 1.001 20.5c0 1.379 1.122 2.5 2.5 2.5H20.5c1.378 0 2.5-1.121 2.5-2.5a2.503 2.503 0 0 0-2.087-2.465c.246-.273.481-.558.702-.853a3.508 3.508 0 0 1 2.386 3.318ZM10.5 15h1v2h1v-2h1c1.378 0 2.5-1.121 2.5-2.5a2.763 2.763 0 0 0-2.334-2.739l-3.173-.509A1.769 1.769 0 0 1 9 7.5c0-.827.673-1.5 1.5-1.5h3c.827 0 1.5.673 1.5 1.5V8h1v-.5C16 6.121 14.878 5 13.5 5h-1V3h-1v2h-1A2.503 2.503 0 0 0 8 7.5c0 1.369.981 2.521 2.334 2.739l3.173.509c.865.14 1.493.877 1.493 1.752 0 .827-.673 1.5-1.5 1.5h-3c-.827 0-1.5-.673-1.5-1.5V12H8v.5c0 1.379 1.122 2.5 2.5 2.5ZM2 10C2 4.486 6.486 0 12 0s10 4.486 10 10-4.486 10-10 10S2 15.514 2 10Zm1 0c0 4.963 4.038 9 9 9s9-4.037 9-9-4.038-9-9-9-9 4.037-9 9Z" />
  </svg>
);
const Memo = memo(SvgDoar);
export default Memo;
