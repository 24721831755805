import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Message, Container as SemanticContainer } from 'semantic-ui-react';

import { linkView as messages } from '@package/messages';
import styles from './LinkView.module.less';

const LinkView = ({ token, content }) => {
  const history = useHistory();
  const intl = useIntl();
  useEffect(() => {
    if (!token) {
      const { remoteUrl } = content;
      if (isInternalURL(remoteUrl)) {
        history.replace(flattenToAppURL(remoteUrl));
      } else if (!__SERVER__) {
        window.location.href = flattenToAppURL(remoteUrl);
      }
    }
  }, [content, history, token]);
  const { title, description, remoteUrl } = content;
  const { openExternalLinkInNewTab } = config.settings;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  return (
    <Container id="page-document">
      <h1 className="documentFirstHeading">{title}</h1>
      {content.description && (
        <p className="documentDescription">{description}</p>
      )}
      {remoteUrl && (
        <div className={styles.link}>
          <Message color="blue">
            <div>{intl.formatMessage(messages.linkViewLinksAddress)}</div>

            <div>
              <strong>
                <UniversalLink
                  href={remoteUrl}
                  openLinkInNewTab={
                    openExternalLinkInNewTab && !isInternalURL(remoteUrl)
                  }
                >
                  {flattenToAppURL(remoteUrl)}
                </UniversalLink>
              </strong>
            </div>
          </Message>
          <p>{intl.formatMessage(messages.linkViewEditMode)}</p>
        </div>
      )}
    </Container>
  );
};

LinkView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    remoteUrl: PropTypes.string,
  }),
  token: PropTypes.string,
};

LinkView.defaultProps = {
  content: null,
  token: null,
};

export default LinkView;
