const View = ({ data }) => {
  const { mainHeader, align, title } = data;

  if (!title) return null;

  return mainHeader ? (
    <h1 className="sectionTitle" style={{ textAlign: align }}>
      {title}
    </h1>
  ) : (
    <h2 className="sectionTitle" style={{ textAlign: align }}>
      {title}
    </h2>
  );
};

export default View;
