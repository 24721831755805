const LogoSimple = ({ titleId, title, size, className }) => (
  <svg
    aria-labelledby={titleId}
    className={className}
    width="170"
    height="122"
    viewBox="0 0 170 122"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={size}
  >
    <title id={titleId}>{title}</title>

    <path
      d="M40.3629 96.9747C37.6282 98.4004 34.7769 99.4338 31.8038 100.064C28.8306 100.695 25.7992 101.013 22.7094 101.013C19.4183 101.013 16.3921 100.499 13.615 99.4709C10.8433 98.4428 8.4425 96.9641 6.42329 95.0456C4.40409 93.1218 2.83006 90.7952 1.69592 88.0606C0.567073 85.3259 0 82.2785 0 78.9079C0 75.5373 0.561773 72.4899 1.69592 69.7552C2.82476 67.0206 4.39879 64.694 6.42329 62.7702C8.4425 60.8464 10.838 59.373 13.615 58.3449C16.3868 57.3167 19.4183 56.7974 22.7094 56.7974C26.0006 56.7974 29.1751 57.2054 32.0105 58.0163C34.8405 58.8272 37.3314 60.2263 39.4672 62.2031L32.9273 69.3366C31.6607 68.0275 30.2351 67.0418 28.6504 66.3634C27.0658 65.6903 25.0837 65.3512 22.7094 65.3512C20.7273 65.3512 18.936 65.6903 17.3302 66.3634C15.7243 67.0365 14.3464 67.9798 13.2017 69.1882C12.0516 70.3965 11.1719 71.8327 10.5571 73.4969C9.94232 75.161 9.63494 76.9629 9.63494 78.9026C9.63494 80.8423 9.94232 82.6972 10.5571 84.3401C11.1719 85.9831 12.0516 87.4087 13.2017 88.617C14.3517 89.8254 15.7296 90.7687 17.3302 91.4418C18.936 92.1149 20.7273 92.4541 22.7094 92.4541C24.6915 92.4541 26.4722 92.2262 27.9403 91.7704C29.403 91.3146 30.5742 90.8482 31.4487 90.3713V83.5346H20.1867L27.7707 74.9755H40.3629V96.9641V96.9747Z"
      fill="#006DA7"
    />
    <path
      d="M43.5741 90.4931L59.9185 75.8128C60.7506 75.0603 61.5826 74.207 62.4147 73.2583C63.2467 72.3097 63.6654 71.1967 63.6654 69.9301C63.6654 68.5045 63.1513 67.3862 62.1179 66.5701C61.0844 65.7592 59.8814 65.3511 58.4929 65.3511C56.8287 65.3511 55.5303 65.8652 54.5975 66.8987C53.6648 67.9321 53.1401 69.1987 53.0235 70.7039L44.1093 70.052C44.2312 67.8738 44.6817 65.9818 45.4767 64.376C46.2663 62.7701 47.321 61.4346 48.6247 60.3641C49.9338 59.2935 51.4654 58.4933 53.2302 57.958C54.995 57.4227 56.9029 57.1577 58.9645 57.1577C60.8671 57.1577 62.6479 57.4227 64.312 57.958C65.9761 58.4933 67.4229 59.2882 68.6525 60.3376C69.882 61.3869 70.8413 62.7065 71.5355 64.2912C72.2298 65.8758 72.5743 67.7201 72.5743 69.8188C72.5743 71.1649 72.4365 72.3839 72.1556 73.4756C71.88 74.5674 71.4931 75.5584 70.995 76.4488C70.4968 77.3391 69.9138 78.1659 69.2407 78.9132C68.5677 79.6657 67.8363 80.3971 67.0414 81.1126L54.2637 92.1042H72.9241V99.9479H43.5635V90.4984L43.5741 90.4931Z"
      fill="#006DA7"
    />
    <path
      d="M83.9265 78.908C83.9265 80.8901 84.2339 82.7026 84.8487 84.3455C85.4634 85.9884 86.3432 87.414 87.4932 88.6224C88.6433 89.8307 90.0212 90.7741 91.6217 91.4472C93.2276 92.1202 95.0189 92.4594 97.001 92.4594C98.9831 92.4594 100.774 92.1202 102.38 91.4472C103.986 90.7741 105.359 89.836 106.509 88.6224C107.659 87.414 108.539 85.9884 109.153 84.3455C109.768 82.7026 110.075 80.8901 110.075 78.908C110.075 76.9258 109.768 75.1663 109.153 73.5022C108.539 71.8381 107.659 70.4019 106.509 69.1935C105.359 67.9852 103.981 67.0471 102.38 66.3688C100.774 65.6957 98.9831 65.3565 97.001 65.3565C95.0189 65.3565 93.2276 65.6957 91.6217 66.3688C90.0159 67.0418 88.638 67.9852 87.4932 69.1935C86.3432 70.4019 85.4634 71.8381 84.8487 73.5022C84.2339 75.1663 83.9265 76.9682 83.9265 78.908ZM74.2969 78.908C74.2969 75.5426 74.8586 72.49 75.9928 69.7553C77.1269 67.0206 78.6957 64.694 80.7202 62.7702C82.7394 60.8517 85.1349 59.3731 87.9119 58.3396C90.6837 57.3115 93.7151 56.7921 97.0063 56.7921C100.297 56.7921 103.324 57.3062 106.101 58.3396C108.872 59.3731 111.273 60.8464 113.292 62.7702C115.312 64.694 116.886 67.0206 118.02 69.7553C119.149 72.49 119.716 75.5426 119.716 78.908C119.716 82.2733 119.149 85.3259 118.02 88.0606C116.891 90.7953 115.317 93.1219 113.292 95.0457C111.273 96.9695 108.872 98.4428 106.101 99.471C103.329 100.499 100.297 101.018 97.0063 101.018C93.7151 101.018 90.6837 100.504 87.9119 99.471C85.1402 98.4428 82.7394 96.9642 80.7202 95.0457C78.701 93.1272 77.1269 90.7953 75.9928 88.0606C74.8639 85.3259 74.2969 82.2786 74.2969 78.908Z"
      fill="#006DA7"
    />
    <path
      d="M4.92376 118.137H7.69553C8.07711 118.137 8.48519 118.11 8.92507 118.062C9.36495 118.015 9.76773 117.903 10.1334 117.728C10.4991 117.554 10.8012 117.305 11.0397 116.976C11.2782 116.647 11.4 116.207 11.4 115.651C11.4 114.761 11.1086 114.135 10.5256 113.785C9.94262 113.436 9.04166 113.261 7.81212 113.261H4.91846V118.137H4.92376ZM4.92376 110.68H7.66903C8.67068 110.68 9.42324 110.484 9.92672 110.092C10.4302 109.699 10.6793 109.185 10.6793 108.549C10.6793 107.818 10.4196 107.272 9.90022 106.912C9.38084 106.551 8.54878 106.376 7.40404 106.376H4.91846V110.68H4.92376ZM1.9135 103.795H8.50639C9.14236 103.795 9.77303 103.87 10.3931 104.023C11.0132 104.177 11.5696 104.421 12.0519 104.765C12.5395 105.11 12.9317 105.55 13.2338 106.09C13.5358 106.631 13.6895 107.288 13.6895 108.051C13.6895 109.005 13.4193 109.795 12.8787 110.415C12.3381 111.035 11.6279 111.48 10.7535 111.75V111.798C11.2782 111.862 11.771 112.01 12.2215 112.238C12.6773 112.471 13.0589 112.763 13.3822 113.123C13.7001 113.483 13.9492 113.897 14.1347 114.363C14.3202 114.835 14.4103 115.338 14.4103 115.879C14.4103 116.801 14.2248 117.575 13.8591 118.184C13.4934 118.799 13.0112 119.292 12.4123 119.668C11.8134 120.044 11.1298 120.309 10.356 120.468C9.58224 120.627 8.79787 120.707 8.00291 120.707H1.9082V103.79L1.9135 103.795Z"
      fill="#006DA7"
    />
    <path
      d="M23.2287 110.966C23.658 110.966 24.0873 110.945 24.5166 110.908C24.9458 110.871 25.3433 110.775 25.6984 110.622C26.0588 110.468 26.345 110.24 26.5729 109.927C26.7954 109.615 26.9067 109.185 26.9067 108.624C26.9067 108.131 26.806 107.733 26.5941 107.431C26.3874 107.129 26.1171 106.901 25.7832 106.747C25.4493 106.594 25.0783 106.498 24.6703 106.451C24.2622 106.403 23.87 106.382 23.4884 106.382H20.8862V110.971H23.2287V110.966ZM17.876 103.796H23.7534C24.5643 103.796 25.3486 103.87 26.1065 104.023C26.8643 104.177 27.5374 104.431 28.1257 104.802C28.714 105.168 29.1856 105.661 29.5354 106.286C29.8852 106.906 30.0601 107.696 30.0601 108.65C30.0601 109.88 29.7209 110.902 29.0425 111.735C28.3642 112.561 27.3996 113.065 26.1383 113.24L30.6325 120.718H27.0021L23.0856 113.547H20.8862V120.718H17.876V103.801V103.796Z"
      fill="#006DA7"
    />
    <path
      d="M42.7005 114.268L40.093 107.383L37.4378 114.268H42.6952H42.7005ZM38.8793 103.801H41.4815L48.7687 120.718H45.3291L43.7498 116.849H36.415L34.8833 120.718H31.5127L38.874 103.801H38.8793Z"
      fill="#006DA7"
    />
    <path
      d="M59.431 107.431C59.1131 106.97 58.6838 106.631 58.1538 106.414C57.6185 106.196 57.0567 106.09 56.4685 106.09C56.1187 106.09 55.7795 106.133 55.4509 106.212C55.1223 106.292 54.8309 106.419 54.5659 106.594C54.3009 106.769 54.0942 106.997 53.9352 107.272C53.7762 107.553 53.6967 107.882 53.6967 108.263C53.6967 108.836 53.8981 109.275 54.2956 109.578C54.6931 109.88 55.1859 110.145 55.7742 110.367C56.3625 110.59 57.0091 110.807 57.7086 111.014C58.4082 111.22 59.0548 111.507 59.643 111.872C60.2313 112.238 60.7242 112.726 61.127 113.33C61.5244 113.934 61.7258 114.739 61.7258 115.741C61.7258 116.647 61.5562 117.442 61.2224 118.121C60.8885 118.799 60.438 119.361 59.8709 119.806C59.3038 120.251 58.6467 120.585 57.8994 120.808C57.1521 121.03 56.3625 121.142 55.5357 121.142C54.4864 121.142 53.4741 120.967 52.499 120.617C51.5291 120.267 50.6918 119.679 49.9922 118.847L52.2605 116.647C52.6262 117.204 53.1084 117.638 53.7073 117.951C54.3062 118.264 54.9369 118.417 55.6046 118.417C55.9544 118.417 56.3042 118.37 56.654 118.274C57.0038 118.179 57.3217 118.036 57.6079 117.845C57.8941 117.654 58.1273 117.411 58.3022 117.119C58.4771 116.822 58.5672 116.483 58.5672 116.102C58.5672 115.481 58.3658 114.999 57.9683 114.665C57.5708 114.331 57.0779 114.05 56.4844 113.817C55.8961 113.584 55.2495 113.367 54.55 113.16C53.8504 112.953 53.2038 112.673 52.6156 112.312C52.0273 111.952 51.5344 111.475 51.1316 110.881C50.7342 110.282 50.5328 109.482 50.5328 108.48C50.5328 107.606 50.713 106.848 51.0733 106.212C51.4337 105.576 51.9001 105.046 52.4831 104.622C53.066 104.198 53.7285 103.886 54.4811 103.679C55.2283 103.472 55.9968 103.366 56.7759 103.366C57.6662 103.366 58.5301 103.504 59.3674 103.774C60.2048 104.045 60.9574 104.49 61.6251 105.11L59.4257 107.426L59.431 107.431Z"
      fill="#006DA7"
    />
    <path
      d="M92.4062 117.681L98.9779 111.326C99.1687 111.136 99.3701 110.934 99.5768 110.717C99.7835 110.5 99.969 110.277 100.139 110.033C100.308 109.795 100.446 109.546 100.557 109.281C100.669 109.016 100.727 108.74 100.727 108.454C100.727 108.104 100.658 107.792 100.525 107.521C100.388 107.251 100.213 107.018 99.9849 106.827C99.7623 106.636 99.5026 106.488 99.2058 106.382C98.909 106.281 98.5964 106.228 98.2625 106.228C97.547 106.228 96.9534 106.435 96.4924 106.848C96.0313 107.262 95.7504 107.818 95.655 108.523L92.6447 108.284C92.7083 107.505 92.8938 106.816 93.2065 106.217C93.5139 105.619 93.9167 105.12 94.4149 104.723C94.9077 104.325 95.4801 104.023 96.1373 103.817C96.7891 103.61 97.4993 103.504 98.2625 103.504C99.0256 103.504 99.7411 103.605 100.398 103.801C101.061 104.002 101.638 104.294 102.131 104.686C102.624 105.078 103.016 105.576 103.303 106.18C103.589 106.785 103.732 107.495 103.732 108.306C103.732 109.376 103.488 110.293 103.006 111.067C102.518 111.841 101.893 112.577 101.13 113.277L95.8935 118.126H103.732V120.707H92.4062V117.67V117.681Z"
      fill="#006DA7"
    />
    <path
      d="M108.968 112.259C108.968 112.784 109.005 113.393 109.074 114.088C109.148 114.782 109.291 115.439 109.514 116.059C109.736 116.679 110.065 117.209 110.494 117.638C110.923 118.068 111.496 118.285 112.216 118.285C112.937 118.285 113.504 118.068 113.939 117.638C114.368 117.209 114.697 116.685 114.919 116.059C115.142 115.439 115.29 114.782 115.359 114.088C115.433 113.393 115.465 112.784 115.465 112.259C115.465 111.735 115.428 111.125 115.359 110.431C115.285 109.737 115.142 109.079 114.919 108.459C114.697 107.839 114.368 107.309 113.939 106.88C113.51 106.451 112.937 106.233 112.216 106.233C111.496 106.233 110.929 106.451 110.494 106.88C110.065 107.309 109.736 107.834 109.514 108.459C109.291 109.079 109.143 109.737 109.074 110.431C109 111.125 108.968 111.735 108.968 112.259ZM106.101 112.259C106.101 110.924 106.207 109.779 106.424 108.83C106.641 107.882 106.922 107.087 107.272 106.44C107.622 105.794 108.019 105.279 108.454 104.887C108.894 104.495 109.339 104.204 109.795 104.002C110.25 103.801 110.685 103.674 111.109 103.61C111.533 103.546 111.899 103.515 112.222 103.515C112.545 103.515 112.911 103.546 113.335 103.61C113.759 103.674 114.193 103.806 114.649 104.002C115.105 104.204 115.55 104.495 115.985 104.887C116.424 105.279 116.817 105.794 117.166 106.44C117.516 107.087 117.802 107.882 118.014 108.83C118.232 109.779 118.338 110.924 118.338 112.259C118.338 113.595 118.232 114.74 118.014 115.688C117.802 116.637 117.516 117.432 117.166 118.078C116.817 118.725 116.419 119.244 115.985 119.631C115.545 120.023 115.1 120.315 114.649 120.516C114.193 120.718 113.759 120.845 113.335 120.908C112.911 120.972 112.545 121.004 112.222 121.004C111.899 121.004 111.533 120.972 111.109 120.908C110.685 120.845 110.25 120.712 109.795 120.516C109.339 120.315 108.894 120.023 108.454 119.631C108.014 119.239 107.622 118.725 107.272 118.078C106.922 117.432 106.636 116.637 106.424 115.688C106.207 114.74 106.101 113.6 106.101 112.259Z"
      fill="#006DA7"
    />
    <path
      d="M120.702 117.681L127.274 111.326C127.465 111.136 127.666 110.934 127.873 110.717C128.079 110.5 128.265 110.277 128.434 110.033C128.604 109.795 128.742 109.546 128.853 109.281C128.964 109.016 129.023 108.74 129.023 108.454C129.023 108.104 128.954 107.792 128.821 107.521C128.684 107.251 128.509 107.018 128.286 106.827C128.063 106.636 127.804 106.488 127.507 106.382C127.21 106.281 126.898 106.228 126.564 106.228C125.848 106.228 125.255 106.435 124.794 106.848C124.332 107.262 124.052 107.818 123.956 108.523L120.946 108.284C121.01 107.505 121.195 106.816 121.508 106.217C121.82 105.619 122.223 105.12 122.716 104.723C123.209 104.325 123.781 104.023 124.438 103.817C125.09 103.61 125.801 103.504 126.564 103.504C127.327 103.504 128.042 103.605 128.705 103.801C129.367 104.002 129.945 104.294 130.438 104.686C130.931 105.078 131.323 105.576 131.609 106.18C131.895 106.785 132.038 107.495 132.038 108.306C132.038 109.376 131.795 110.293 131.307 111.067C130.819 111.841 130.194 112.577 129.431 113.277L124.195 118.126H132.033V120.707H120.707V117.67L120.702 117.681Z"
      fill="#006DA7"
    />
    <path
      d="M141.922 107.86H141.875L137.645 114.692H141.922V107.86ZM141.922 117.273H134.73V114.427L141.541 103.79H144.789V114.686H147.248V117.267H144.789V120.707H141.922V117.267V117.273Z"
      fill="#006DA7"
    />
    <path d="M68.271 103.801H65.2607V120.718H68.271V103.801Z" fill="#006DA7" />
    <path
      d="M72.4531 103.801H75.4634V117.993H82.6604V120.718H72.4531V103.801Z"
      fill="#006DA7"
    />

    <path
      d="M128.842 0C119.812 12.1576 122.228 23.25 122.228 23.25C124.857 13.0109 136.448 0 136.448 0H128.842Z"
      fill="url(#paint0_linear_25_20970)"
    />
    <path
      d="M119.536 0C113.897 7.84362 114.75 19.4077 121.528 28.0357C128.307 36.6636 137.618 40.5536 137.618 40.5536C124.618 33.7329 120.569 26.1966 120.818 16.09C121.062 6.2272 127.978 0 127.978 0H119.541H119.536Z"
      fill="url(#paint1_linear_25_20970)"
    />
    <path
      d="M108.544 0C101.967 14.5743 112.1 25.4865 121.936 33.876C132.303 42.716 139.15 44.0091 150.635 48.5563C162.119 53.1035 165.723 60.4807 165.723 60.4807C165.744 60.3694 168.362 57.5923 168.362 57.5923C160.556 45.7103 145.425 45.8057 132.435 38.8418C123.781 34.2046 116.902 26.7637 114.236 16.7048C111.57 6.64588 116.509 0 116.509 0H108.549L108.544 0Z"
      fill="url(#paint2_linear_25_20970)"
    />
    <path
      d="M104.723 23.4143C110.929 33.7965 120.786 39.8117 126.049 43.1293C131.312 46.447 138.228 49.86 143.92 54.5503C149.606 59.2405 157.519 68.849 157.948 78.3779L161.266 72.3945C161.266 72.3945 159.119 62.8603 149.702 54.598C142.918 48.6463 133.209 45.9276 123.208 37.6759C126.764 40.5165 136.129 45.1432 146.861 50.6602C158.664 56.7285 161.833 64.5244 161.833 64.5244L163.894 62.3303C163.894 62.3303 161.16 56.8292 154.827 52.706C147.614 48.0157 146.193 47.7772 135.811 43.3201C124.194 38.333 115.863 30.0866 108.417 21.6971C100.547 12.836 103.154 2.97314 103.154 2.97314C103.154 2.97314 98.5115 13.0215 104.723 23.4037"
      fill="url(#paint3_linear_25_20970)"
    />
    <path
      d="M101.707 13.0585C101.707 18.6021 102.698 26.3397 114.316 36.0912C125.265 45.2863 143.369 51.6831 146.591 69.5061C148.573 80.4554 141.191 92.507 133.649 100.218H129.383C129.383 100.218 154.212 79.3318 140.141 59.3624C129.187 43.8182 98.623 36.7537 101.702 13.0532"
      fill="#EE2D2F"
    />
    <path
      d="M141.837 83.7837C143.798 77.5088 143.469 70.2163 140.098 63.8672C135.265 54.7676 127.443 50.7398 114.363 41.5447C101.283 32.3496 99.5288 22.0151 99.5288 22.0151C97.9654 31.6342 107.017 39.5043 109.673 41.6401C112.328 43.7706 122.238 50.443 127.729 54.6722C133.267 58.9385 141.301 66.533 138.964 79.5068C137.825 85.8082 134.194 91.4524 125.079 100.218H129.393C129.393 100.218 137.523 93.3762 141.842 83.7784"
      fill="#FFCD03"
    />
    <path
      d="M106.896 41.3328C106.896 41.3328 106.911 41.3487 106.922 41.354C114.411 47.5176 124.263 53.4427 128.439 57.709C132.615 61.9753 138.35 70.2217 134.603 81.4995C130.856 92.7774 118.963 100.224 118.963 100.224H125.079C134.2 91.4578 137.83 85.8135 138.964 79.5121C139.086 78.8391 139.176 78.1819 139.245 77.5353C139.431 68.0541 135.938 64.021 133.267 60.5496C130.273 56.6649 120.569 49.9819 116.732 47.7508C114.262 46.3198 109.784 43.3043 106.896 41.3328Z"
      fill="url(#paint4_linear_25_20970)"
    />
    <path
      d="M101.49 60.751L105.025 58.6523C105.025 58.6523 110.585 61.1644 121.195 61.8746C121.195 61.8746 112.551 64.9643 101.49 60.751Z"
      fill="#EE2D2F"
    />
    <path
      d="M121.989 40.5324C123.961 42.1223 128.142 45.546 129.87 46.9716C137.608 52.4568 144.693 58.9914 146.596 69.5114C148.578 80.4607 141.196 92.5123 133.659 100.223C133.659 100.223 133.871 100.155 134.253 100.022C147.232 87.3557 151.164 81.4941 150.391 71.1331C149.585 60.327 135.52 50.4482 133.802 49.1339C132.345 48.0156 123.855 41.8096 122.032 40.5536C122.021 40.543 122.005 40.5377 121.995 40.5271L121.989 40.5324Z"
      fill="url(#paint5_linear_25_20970)"
    />
    <path
      d="M122.027 40.5537C122.557 41.0731 141.811 54.3119 146.92 61.1167C151.239 66.8722 153.555 75.0498 149.448 83.5505L153.868 82.2097C153.868 82.2097 157.858 72.3257 149.564 61.3817C142.606 52.192 125.567 43.0658 122.021 40.559L122.027 40.5537Z"
      fill="url(#paint6_linear_25_20970)"
    />
    <path
      d="M109.434 48.0369C116.732 53.3949 124.788 56.2727 129.149 63.2525C132.228 68.1812 135.377 78.5846 125.312 89.6134C124.523 90.4773 123.489 91.4842 122.366 92.5177H127.883C130.564 89.5339 133.172 85.7976 134.597 81.4995C138.344 70.2163 132.605 61.9699 128.434 57.7036C124.258 53.4373 114.411 47.5122 106.917 41.3539C102.115 37.4003 99.7146 34.0562 98.432 31.8727C98.22 38.508 105.942 45.4771 109.429 48.0369"
      fill="#00A24F"
    />
    <path
      d="M108.719 55.0061C115.402 59.0339 121.799 60.2211 126.542 66.4748C131.174 72.5854 128.376 79.9096 123.744 85.4956H128.503C131.81 80.3601 132.509 75.5055 132.022 71.4512C130.156 60.7246 118.82 55.7375 112.514 52.5417C104.834 48.657 101.49 44.2 101.49 44.2L98.1035 46.0973C100.488 49.1287 103.61 51.927 108.719 55.0061Z"
      fill="#00A651"
    />
    <path
      d="M136.319 39.8488C143.75 44.0939 152.309 44.0462 159.516 47.4592C162.548 48.8954 166.841 50.9252 169.999 53.6705V53.5221C169.999 53.5221 169.994 53.4797 169.989 53.4585C169.777 52.0117 169.247 50.639 168.552 49.383C166.682 47.9203 163.777 45.9223 160.03 44.2051C154.344 41.5977 146.331 40.5324 146.331 40.5324C146.331 40.5324 152.526 39.8329 160.295 43.4844C163.698 45.0796 166.157 46.8073 167.853 48.2435C167.18 47.226 166.427 46.3091 165.685 45.5036C162.278 42.0216 156.798 38.8259 148.127 38.6722C131.396 38.3754 124.989 27.5957 122.217 23.2552C122.217 23.2552 123.059 32.286 136.309 39.8541"
      fill="url(#paint7_linear_25_20970)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_25_20970"
        x1="132.208"
        y1="-0.0370981"
        x2="122.17"
        y2="21.8509"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#00A350" />
        <stop offset="0.44" stopColor="#008A63" />
        <stop offset="0.9" stopColor="#0062AF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_25_20970"
        x1="119.032"
        y1="1.30374"
        x2="134.635"
        y2="40.0025"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.22" stopColor="#00A350" />
        <stop offset="0.5" stopColor="#446D34" />
        <stop offset="0.77" stopColor="#ED1C24" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_25_20970"
        x1="107.786"
        y1="5.82972"
        x2="163.29"
        y2="60.7298"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15" stopColor="#00A94F" />
        <stop offset="0.52" stopColor="#008B95" />
        <stop offset="0.89" stopColor="#0065B1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_25_20970"
        x1="103.334"
        y1="2.72406"
        x2="160.047"
        y2="76.2474"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15" stopColor="#00A651" />
        <stop offset="0.52" stopColor="#008C91" />
        <stop offset="0.84" stopColor="#1E5DAB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_25_20970"
        x1="120.065"
        y1="43.0075"
        x2="131.455"
        y2="98.4163"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3" stopColor="#FFCD03" />
        <stop offset="0.62" stopColor="#F47A28" />
        <stop offset="0.92" stopColor="#ED1C24" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_25_20970"
        x1="136.092"
        y1="54.7304"
        x2="139.6"
        y2="95.724"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15" stopColor="#ED1C24" />
        <stop offset="0.84" stopColor="#44B649" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_25_20970"
        x1="136.712"
        y1="51.8104"
        x2="149.167"
        y2="84.2183"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15" stopColor="#ED1C24" />
        <stop offset="0.84" stopColor="#44B649" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_25_20970"
        x1="121.878"
        y1="24.2145"
        x2="168.918"
        y2="53.4797"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#145DAB" />
        <stop offset="0.51" stopColor="#00778B" />
        <stop offset="0.92" stopColor="#00994D" />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoSimple;
