import PropTypes from 'prop-types';

import styles from './Header.module.less';

const Header = (props) => {
  const { title, handleToggleMenu, children } = props;

  // I18N
  return (
    <div className={styles.header}>
      {children
        ? children
        : title && <div className={styles.title}>{title}</div>}

      {handleToggleMenu && (
        <button
          className={`br-button small circle ${styles.close}`}
          onClick={handleToggleMenu}
        ></button>
      )}
    </div>
  );
};

Header.displayName = 'Header';

Header.propTypes = {
  title: PropTypes.string,
  handleToggleMenu: PropTypes.func,
  children: PropTypes.node,
};

export default Header;
