import { GET_G20_SOCIAL_NETWORKS } from '../constants/ActionTypes';

export function getG20SocialNetworks() {
  return {
    type: GET_G20_SOCIAL_NETWORKS,
    request: {
      op: 'get',
      path: '/@g20site',
    },
  };
}
