import React from 'react';
import PropTypes from 'prop-types';

import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';

/**
 * Renders a preview image for a catalog brain result item.
 *
 */
function ContentImage(props) {
  const {
    item,
    size = 'preview',
    alt,
    customField,
    'show-placeholder': placeholder = false,
    ...rest
  } = props;

  let src;

  if (customField) {
    src = item.image_scales?.hasOwnProperty(customField)
      ? flattenToAppURL(`${item['@id']}/@@images/${customField}/${size}`)
      : null;
  } else {
    src = item.image_field
      ? flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/${size}`)
      : placeholder
      ? config.getComponent({
          name: 'DefaultImage',
          dependencies: ['listing', 'summary'],
        }).component || DefaultImageSVG
      : null;
  }

  return src ? <img src={src} alt={alt ?? item.title} {...rest} /> : null;
}

ContentImage.propTypes = {
  size: PropTypes.string,
  'show-placeholder': PropTypes.bool,
  customField: PropTypes.string,
  item: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    image_field: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
};

export default ContentImage;
