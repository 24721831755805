import { memo } from 'react';
const SvgSopa = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <path d="M9.146 4.354A3.94 3.94 0 0 1 8 1.586V0h1v1.586c0 .767.311 1.518.854 2.061A3.94 3.94 0 0 1 11 6.415v1.586h-1V6.415c0-.767-.311-1.518-.854-2.061ZM14 5.415v1.586h1V5.415a3.889 3.889 0 0 0-1.146-2.768A2.935 2.935 0 0 1 13 .586V0h-1v.586c0 1.03.418 2.039 1.146 2.768.551.55.854 1.282.854 2.061Zm9.969 7.484c-.761 4.712-4.052 9.305-7.856 11.102l-8.326-.048C3.981 22.155.792 17.61.032 12.899A2.503 2.503 0 0 1 2.508 10h18.983c.736 0 1.433.322 1.91.883.477.559.683 1.293.567 2.016Zm-1.328-1.367a1.51 1.51 0 0 0-1.149-.531H2.508a1.505 1.505 0 0 0-1.488 1.74c.704 4.363 3.613 8.563 7.094 10.26h7.773c3.48-1.696 6.39-5.897 7.095-10.26a1.495 1.495 0 0 0-.341-1.208Z" />
  </svg>
);
const Memo = memo(SvgSopa);
export default Memo;
