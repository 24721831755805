import moment from 'moment';
import { GET_G20_EVENTS } from '../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: {},
};

export default function g20Events(state = initialState, action = {}) {
  const parseDate = (date) => {
    var temp = new Date(date);
    var userTimezoneOffset = temp.getTimezoneOffset() * 60000;
    return new Date(temp.getTime() + userTimezoneOffset);
  };

  const createData = (events = []) => {
    const result = {
      all: events.map((event) => {
        // console.log(event);

        return {
          id: event.EventId,
          title: event.Title,
          city: event.City,
          track: event.Track || 'Both Tracks',
          bothTracks: !event.Track,
          // TODO: Fix prop name
          workgroupLabel: event.WorkgroupLabel,
          workgroupCode: event.WorkgroupCode,
          end: parseDate(event.EndsOn),
          start: parseDate(event.StartsOn),
        };
      }),

      ongoing: [],
      past: [],
      future: [],
    };

    result.all.forEach((event) => {
      if (moment().isAfter(event.end, 'day')) {
        result.past.push(event);
      } else if (moment().isBetween(event.start, event.end, 'day', '[]')) {
        result.ongoing.push(event);
      } else {
        result.future.push(event);
      }
    });

    return result;
  };

  switch (action.type) {
    case `${GET_G20_EVENTS}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        data: {},
      };
    case `${GET_G20_EVENTS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        data: createData(action.result),
      };
    case `${GET_G20_EVENTS}_FAIL`:
      return {
        ...state,
        error: action.result,
        loaded: false,
        loading: false,
        data: {},
      };
    default:
      return state;
  }
}
