/**
 * Logo component.
 * @module components/theme/Logo/Logo
 */
import PropTypes from 'prop-types';
import { LogoG20 } from '@package/components';
import { UniversalLink } from '@plone/volto/components';
import { toBackendLang } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { siteMessages } from '@package/messages';

/**
 * Logo component class.
 * @function Logo
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */
const Logo = (props) => {
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  const intl = useIntl();

  return (
    <UniversalLink
      href={settings.isMultilingual ? `/${toBackendLang(lang)}` : '/'}
      title={intl.formatMessage(siteMessages.siteName)}
    >
      <LogoG20 {...props} />
    </UniversalLink>
  );
};

Logo.propTypes = {
  titleId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  invert: PropTypes.bool,
  full: PropTypes.bool,
  'invert-text': PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

export default Logo;
