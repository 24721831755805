import { injectIntl } from 'react-intl';

const GroupThirdParty = ({ intl }) => {
  if (intl.locale === 'en') {
    return (
      <>
        <div className="mb-base">
          The G20 portal depends on services offered by third parties that
          allow:
        </div>
        <ul>
          <li>Improve government information campaigns; </li>
          <li>Offer interactive content; </li>
          <li>
            Improve usability and facilitate content sharing on social networks;{' '}
          </li>
          <li>
            Watch videos and animated presentations directly on the G20 portal{' '}
          </li>
        </ul>
        <div>
          The third-party cookies on the G20 portal are all Google advertising
          and multimedia cookies. These third parties will also collect and use
          browsing data for their own purposes. The user can deactivate them
          directly on the Google website.
        </div>
        <ul>
          <li>
            Google's official third-party cookie advisory
            (https://business.safety.google/adscookies).{' '}
          </li>
          <li>
            Google Policy
            (https://policies.google.com/technologies/partner-sites?hl-pt-BR).{' '}
          </li>
        </ul>
        <div>
          Google Analytics on the G20 portal has advertising reporting features
          enabled, which collects additional information per DoubleClick cookie,
          such as web activity and device advertising IDs (app activity)
          (https://support.google.com/ analytics/answer/2799357){' '}
        </div>
        <div>
          The G20 portal has no control over which third-party cookies are
          activated. Some third-party cookies that may be found when accessing
          the portal:{' '}
        </div>
        <div>Domains: Google, Youtube, DoubleClick.net </div>
      </>
    );
  }

  if (intl.locale === 'es') {
    return (
      <>
        <div>
          El portal del G20 depende de servicios ofrecidos por terceros que
          permiten:{' '}
        </div>
        <ul>
          <li>Mejorar las campañas de información gubernamental; </li>
          <li>Ofrecer contenido interactivo; </li>
          <li>
            Mejorar la usabilidad y facilitar el intercambio de contenidos en
            las redes sociales;{' '}
          </li>
          <li>
            Vea vídeos y presentaciones animadas directamente en el portal del
            G20{' '}
          </li>
        </ul>
        <div>
          Las cookies de terceros en el portal del G20 son todas cookies
          publicitarias y multimedia de Google. Estos terceros también
          recopilarán y utilizarán datos de navegación para sus propios fines.
          El usuario podrá desactivarlas directamente en la página web de
          Google.{' '}
        </div>
        <ul>
          <li>
            Aviso oficial de cookies de terceros de Google
            (https://business.safety.google/adscookies).{' '}
          </li>
          <li>
            Política de Google
            (https://policies.google.com/technologies/partner-sites?hl-pt-BR).{' '}
          </li>
        </ul>
        <div>
          Google Analytics en el portal G20 tiene habilitadas funciones de
          informes publicitarios, que recopilan información adicional por cookie
          de DoubleClick, como actividad web e ID de publicidad del dispositivo
          (actividad de aplicaciones)
          (https://support.google.com/analytics/answer/2799357){' '}
        </div>
        <div>
          El portal G20 no tiene control sobre qué cookies de terceros se
          activan. Algunas cookies de terceros que se pueden encontrar al
          acceder al portal:{' '}
        </div>
        <div>Domínios: Google, Youtube, DoubleClick.net </div>
      </>
    );
  }

  return (
    <>
      <div>
        O portal G20 depende dos serviços oferecidos por terceiros que permitem:{' '}
      </div>
      <ul>
        <li>Melhorar as campanhas de informação do governo; </li>
        <li>Oferecer conteúdo interativo; </li>
        <li>
          Melhorar a usabilidade e facilitar o compartilhamento de conteúdo nas
          redes sociais;{' '}
        </li>
        <li>
          Assistir a vídeos e apresentações animadas diretamente no portal G20{' '}
        </li>
      </ul>
      <div>
        Os cookies de terceiros no portal G20 são todos cookies de publicidade e
        multimídia do Google. Esses terceiros coletarão e usarão dados de
        navegação também para seus próprios fins. O usuário pode desativá-los
        direto no site da Google.{' '}
      </div>
      <ul>
        <li>
          Informativo oficial dos cookies de terceiros do Google
          (https://business.safety.google/adscookies).{' '}
        </li>
        <li>
          Política do Google
          (https://policies.google.com/technologies/partner-sites?hl-pt-BR).{' '}
        </li>
      </ul>
      <div>
        O Google Analytics no portal G20 tem recursos de relatórios de
        publicidade ativados, que coleta informações adicionais por cookie da
        DoubleClick, como atividade da Web e de IDs de publicidade do
        dispositivo (atividade do aplicativo)
        (https://support.google.com/analytics/answer/2799357){' '}
      </div>
      <div>
        O portal G20 não tem controle sobre quais cookies de terceiros serão
        ativados. Alguns cookies de terceiros que podem ser encontrados ao
        acessar o portal:{' '}
      </div>
      <div>Domínios: Google, Youtube, DoubleClick.net </div>
    </>
  );
};

export default injectIntl(GroupThirdParty);
