import { defineMessages } from 'react-intl';
import Large from './Large';

defineMessages({
  largeVariation: {
    id: 'Large',
    defaultMessage: 'Large',
  },
});

const teaserVariations = [
  {
    id: 'large',
    isDefault: false,
    title: 'Large',
    template: Large,
  },
];

export default teaserVariations;
